const requestInitDefaults: RequestInit = {
  credentials: 'include',
}

export class FetchError extends Error {
  constructor(msg: string, res: Response, url: string, errors: unknown) {
    super(msg)
    this.name = 'FetchError'
    this.url = url
    this.res = res
    this.errors = errors
  }
  res: Response
  errors: unknown
  url: string
}

export default async function fetch(
  url: string,
  requestInit = {} as RequestInit
) {
  const res = await window.fetch(url, {
    ...requestInitDefaults,
    ...requestInit,
  })
  if (!res.ok) {
    throw new FetchError(
      'Request failed',
      res,
      url,
      res.headers.get('content-type')?.includes('application/json')
        ? await res.json()
        : null
    )
  }
  if (res.status !== 204) {
    return res.json()
  } else {
    return
  }
}
