<script lang="ts" setup>
import AppHeader from '@/components/layout/AppHeader.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import FullWidthBanner from '@/components/FullWidthBanner.vue'

import { t } from '@/lib/locale'

import { useNavigationStore } from '../stores/navigation'

const navigationStore = useNavigationStore()
</script>

<template>
  <div class="PageTransition">
    <AppHeader :nav="navigationStore.main" />
    <ErrorMessage>
      <template #title>{{ t('not_found_title') }}</template>

      <p>{{ t('not_found_copy') }}</p>
    </ErrorMessage>
    <FullWidthBanner>
      <template #title>{{ t('not_found_extra_title') }}</template>
      <div v-html="t('not_found_extra_copy')"></div>
    </FullWidthBanner>
    <AppFooter :nav="navigationStore.footer" />
  </div>
</template>
