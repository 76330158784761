<script setup lang="ts">
import Layout from '@/components/layout/Layout.vue'

import {
  useSubscriptionCustomerStore,
  type Customer,
} from '@/stores/subscriptionCustomer'
import type { AuthenticatedEntity } from '@/stores/auth'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import { computed, ref } from 'vue'

import { useBackLink } from './lib/backLink'
import LoadingCta from '../components/LoadingCta.vue'
import PersonalInfoForm from '../components/PersonalInfoForm.vue'
import ErrorMessage from '../components/ErrorMessage.vue'

import { t } from '@/lib/locale'
import mixpanel from 'mixpanel-browser'

const props = defineProps<{ authedUser: AuthenticatedEntity }>()

const { backTo } = useBackLink()

const subscriptionCustomerStore = useSubscriptionCustomerStore()
subscriptionCustomerStore.init(props.authedUser.rechargeId)

const isLoading = computed(() =>
  [AsyncOperationState.IN_PROGRESS, AsyncOperationState.IDLE].includes(
    subscriptionCustomerStore.loadState
  )
)

const isBusy = computed(
  () => subscriptionCustomerStore.workState === AsyncOperationState.IN_PROGRESS
)

type AddressField = keyof Customer['include']['addresses'][number]
const addressFields = [
  'first_name',
  'last_name',
  'company',
  'address1',
  'address2',
  'province',
  'city',
  'zip',
  'phone',
] as AddressField[]

const getInitialFormValues = (customer: Customer) => {
  const formValues = {
    first_name: '',
    last_name: '',
    address: {
      ...addressFields.reduce((map, key) => {
        map[key] = ''
        return map
      }, {} as Record<AddressField, string>),
    },
  }
  formValues.first_name = customer.first_name
  formValues.last_name = customer.last_name

  addressFields.forEach((key) => {
    const val = subscriptionCustomerStore.customer?.include.addresses[0][key]
    if (typeof val === 'string') {
      formValues.address[key] = val
    }
  })
  return formValues
}

const formErrors = ref<Record<string, unknown> | null>(null)

const onFormSubmit = async (
  values: ReturnType<typeof getInitialFormValues>
) => {
  formErrors.value = null
  const { success, errors } = await subscriptionCustomerStore.updateDetails(
    props.authedUser.rechargeId,
    values
  )
  if (success) {
    backTo('/details')
  } else {
    formErrors.value = errors as Record<string, unknown>
  }
  mixpanel.track('update_personal_info', { success })
}
</script>

<template>
  <Layout
    :title="t('personal_info_title')"
    :back-link="{ text: t('your_details_back_cta') }"
    @back="backTo('/details')"
  >
    <template #main>
      <LoadingCta
        v-if="!subscriptionCustomerStore.customer && isLoading"
      ></LoadingCta>
      <ErrorMessage v-else-if="!subscriptionCustomerStore.customer">
        <template #title>{{ t('personal_info_error') }}</template>
      </ErrorMessage>
      <div class="Card" v-else>
        <PersonalInfoForm
          :form-errors="formErrors"
          :initial-values="
            getInitialFormValues(subscriptionCustomerStore.customer)
          "
          :is-busy="isBusy"
          @submit="onFormSubmit"
          @back="backTo('/details')"
        />
      </div>
    </template>
  </Layout>
</template>
