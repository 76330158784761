import { formatProductTitle } from '@/lib/formatters/product-title'
import {
  cancelOnetime,
  getOnetimes,
  addToNextOrder,
  updateOnetime,
} from '@/services/onetime'
import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { useProductImageStore } from './productImage'
import { useTaskStore } from './task'
import { useProductLinkStore } from './productLink'

import { t } from '@/lib/locale'
import { useNextOrderStore } from './nextOrder'
import { useFutureOrderStore } from './futureOrder'
import Bugsnag from '@bugsnag/js'

export interface Onetime {
  id: string
  address_id: string
  customer_id: string
  cancellation_reason?: string
  cancellation_reason_comments?: string
  cancelled_at?: string
  created_at: string
  external_product_id: {
    ecommerce: string
  }
  external_variant_id: {
    ecommerce: string
  }
  next_charge_scheduled_at: string
  price: string
  product_title: string
  properties: []
  quantity: number
  sku?: string
  sku_override: boolean
  updated_at: string
  variant_title?: string
}

export const useOnetimeStore = defineStore('onetime', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    workState: AsyncOperationState.IDLE,
    onetimes: [] as Onetime[],
    stale: true as boolean,
  }),
  getters: {},
  actions: {
    async init(rechargeId: string) {
      if (this.stale || this.loadState === AsyncOperationState.IDLE) {
        await this.load(rechargeId)
      }
    },
    async load(rechargeId: string) {
      const productImageStore = useProductImageStore()
      const productLinkStore = useProductLinkStore()
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const onetimes = await getOnetimes(rechargeId)
        // remove all the 10.00% off auto renew stuff
        onetimes.forEach(formatTitle)
        this.onetimes = onetimes
        productImageStore.loadImagesForProducts(
          this.onetimes.map(
            (sub) =>
              `${sub.external_product_id.ecommerce}:${sub.external_variant_id.ecommerce}`
          )
        )
        productLinkStore.loadLinksForProducts(
          this.onetimes.map((sub) => `${sub.external_product_id.ecommerce}`)
        )
        this.stale = false
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        this.loadState = AsyncOperationState.ERRORED
        const taskStore = useTaskStore()
        taskStore.setTask(t('purchase_item_cancel_toast_error'), this.loadState)
        Bugsnag.notify(e as Error)
      }
    },
    async cancelOnetime(rechargeId: string, onetimeId: string) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_cancel_toast_progress'),
        this.workState
      )
      try {
        await cancelOnetime(rechargeId, onetimeId)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_cancel_toast_success'),
          this.workState
        )
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        nextOrderStore.stale = true
        futureOrderStore.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('purchase_item_cancel_toast_error'),
          this.workState
        )
      }
    },
    async updateOnetime(
      rechargeId: string,
      subscriptionId: string,
      update: {
        quantity: number
      }
    ) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const nextOrderStore = useNextOrderStore()
      const futureOrderStore = useFutureOrderStore()
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_update_onetime_toast_progress'),
        this.workState
      )
      try {
        const { onetime } = await updateOnetime(
          rechargeId,
          subscriptionId,
          update
        )
        this.patchOnetime(onetime)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_update_toast_success'),
          this.workState
        )
        nextOrderStore.stale = true
        futureOrderStore.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('purchase_item_update_toast_error'),
          this.workState
        )
      }
    },
    async addToNextOrder(
      rechargeId: string,
      addressId: string,
      productId: string,
      variantId: string,
      nextOrderDate: string,
      quantity = 1
    ) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_add_to_next_order_toast_progress'),
        this.workState
      )
      try {
        await addToNextOrder(
          rechargeId,
          addressId,
          productId,
          variantId,
          nextOrderDate,
          quantity
        )
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_add_to_next_order_toast_success'),
          this.workState
        )
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        nextOrderStore.stale = true
        futureOrderStore.stale = true
        this.stale = true
      } catch (e) {
        this.workState = AsyncOperationState.ERRORED
        Bugsnag.notify(e as Error)
        taskStore.updateTask(
          taskId,
          t('purchase_item_add_to_next_order_toast_error'),
          this.workState
        )
      }
    },
    async patchOnetime(onetime: Onetime) {
      this.$patch(() => {
        const onetimeIndex = this.onetimes.findIndex((s) => s.id === onetime.id)
        formatTitle(onetime)
        this.onetimes[onetimeIndex] = onetime
      })
    },
  },
})

const formatTitle = (onetime: Onetime) => {
  onetime.product_title = formatProductTitle(onetime.product_title)
}
