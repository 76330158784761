<script lang="ts" setup>
import BaseIcon from './BaseIcon.vue'
import svgContent from '../../assets/icons/arrow.svg?component'
const props = withDefaults(
  defineProps<{
    flipY?: boolean
  }>(),
  {
    flipY: false,
  }
)
</script>

<template>
  <BaseIcon :svg-content="svgContent" :flip-y="props.flipY" />
</template>

<script></script>
