import fetch from './lib/fetch'
import { getSellingPlan } from './rechargeProductSummary'

export async function getSubscriptions(rechargeId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscriptions`
  )
}

export async function updateSubscription(
  rechargeId: string,
  subscriptionId: string,
  update: {
    frequency: number
    quantity: number
  }
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscription/${subscriptionId}`,
    {
      method: 'PUT',
      body: JSON.stringify(update),
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export async function cancelSubscription(
  rechargeId: string,
  subscriptionId: string,
  reason: string,
  comments?: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscription/${subscriptionId}/cancel`,
    {
      method: 'POST',
      body: JSON.stringify({
        reason,
        comments,
      }),
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export async function skipSubscription(
  rechargeId: string,
  subscriptionId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscription/${subscriptionId}/skip`,
    {
      method: 'POST',
    }
  )
}

export async function addSubscriptionToNextOrder(
  rechargeId: string,
  subscriptionId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscription/${subscriptionId}/add-to-next-order`,
    {
      method: 'POST',
    }
  )
}

export async function reactivateSubscription(
  rechargeId: string,
  subscriptionId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscription/${subscriptionId}/reactivate`,
    {
      method: 'POST',
    }
  )
}

export async function swapSubscription(
  rechargeId: string,
  subscriptionId: string,
  variantId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscription/${subscriptionId}/swap`,
    {
      method: 'PUT',
      body: JSON.stringify({
        external_variant_id: variantId,
      }),
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export async function delaySubscription(
  rechargeId: string,
  subscriptionId: string,
  until: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/subscription/${subscriptionId}/delay`,
    {
      method: 'PUT',
      body: JSON.stringify({
        until,
      }),
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export async function addNewSubscriptionToNextOrder(
  rechargeId: string,
  productId: string,
  variantId: string,
  quantity: number,
  planId: string
) {
  const rechargeSellingPlan = await getSellingPlan(productId, planId)
  if (!rechargeSellingPlan) throw new Error('Selling plan not found')
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/add-to-next-order`,
    {
      method: 'POST',
      body: JSON.stringify({
        external_product_id: productId,
        external_variant_id: variantId,
        quantity,
        plan_id: rechargeSellingPlan.id,
      }),
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}
