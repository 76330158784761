import fetch from './lib/fetch'

export async function applyDiscount(
  rechargeId: string,
  addressId: string,
  discountCode: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/discount`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ addressId, discountCode }),
    }
  )
}
