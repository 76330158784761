<script lang="ts" setup>
import { reactive } from 'vue'
import Button from './Button.vue'
import Message from './Message.vue'
import type { Customer } from '../stores/subscriptionCustomer'

import { t } from '@/lib/locale'

type AddressField = keyof Customer['include']['addresses'][number]

const props = defineProps<{
  formErrors: Record<string, unknown> | null
  initialValues: {
    first_name: string
    last_name: string
    address: Record<AddressField, string>
  }
  isBusy: boolean
}>()

const reactiveFormValues = reactive({
  ...props.initialValues,
  address: { ...props.initialValues.address },
})

defineEmits<{
  (e: 'submit', values: typeof reactiveFormValues): void
  (e: 'back'): void
}>()
</script>

<template>
  <form
    @submit.prevent="$emit('submit', reactiveFormValues)"
    class="PersonalInfoForm"
  >
    <Message mode="error" v-if="formErrors !== null">
      {{ t('personal_info_save_error') }}
    </Message>

    <h5 class="PersonalInfoForm__Title">
      {{ t('personal_info_name_fieldset_title') }}
    </h5>

    <div class="FormRow">
      <div class="PersonalInfoForm__Field FormCol">
        <label for="first_name">
          {{ t('personal_info_first_name_label') }}
          <abbr title="required">*</abbr></label
        >
        <input
          name="first_name"
          type="text"
          v-model="reactiveFormValues.first_name"
          required
          :disabled="isBusy"
          :class="{
            'Input--inline-error': formErrors && formErrors.first_name,
          }"
        />
      </div>
      <div class="PersonalInfoForm__Field FormCol">
        <label for="last_name">
          {{ t('personal_info_last_name_label') }}
          <abbr title="required">*</abbr></label
        >
        <input
          name="last_name"
          type="text"
          v-model="reactiveFormValues.last_name"
          required
          :disabled="isBusy"
          :class="{
            'Input--inline-error': formErrors && formErrors.last_name,
          }"
        />
      </div>
    </div>

    <h5 class="PersonalInfoForm__Title">
      {{ t('personal_info_address_fieldset_title') }}
    </h5>

    <div class="FormRow">
      <div class="PersonalInfoForm__Field FormCol">
        <label for="address_first_name">
          {{ t('personal_info_first_name_label') }}
          <abbr title="required">*</abbr></label
        >
        <input
          name="address_first_name"
          type="text"
          v-model="reactiveFormValues.address.first_name"
          required
          :disabled="isBusy"
          :class="{
            'Input--inline-error':
              formErrors && formErrors['address::first_name'],
          }"
        />
      </div>
      <div class="PersonalInfoForm__Field FormCol">
        <label for="delivery_last_name">
          {{ t('personal_info_last_name_label') }}
          <abbr title="required">*</abbr></label
        >
        <input
          name="delivery_last_name"
          type="text"
          v-model="reactiveFormValues.address.last_name"
          required
          :disabled="isBusy"
          :class="{
            'Input--inline-error':
              formErrors && formErrors['address::last_name'],
          }"
        />
      </div>
    </div>

    <div class="FormRow">
      <div class="PersonalInfoForm__Field FormCol">
        <label for="delivery_company">{{
          t('personal_info_company_label')
        }}</label>
        <input
          name="delivery_company"
          type="text"
          v-model="reactiveFormValues.address.company"
          :disabled="isBusy"
          :class="{
            'Input--inline-error': formErrors && formErrors['address::company'],
          }"
        />
      </div>
    </div>

    <div class="FormRow">
      <div class="PersonalInfoForm__Field FormCol">
        <label for="address_line_1">
          {{ t('personal_info_address_line_1_label') }}
          <abbr title="required">*</abbr></label
        >
        <input
          name="address_line_1"
          type="text"
          v-model="reactiveFormValues.address.address1"
          required
          :disabled="isBusy"
          :class="{
            'Input--inline-error':
              formErrors && formErrors['address::address1'],
          }"
        />
      </div>

      <div class="PersonalInfoForm__Field FormCol">
        <label for="address_line_2">
          {{ t('personal_info_address_line_2_label') }}
        </label>
        <input
          name="address_line_2"
          type="text"
          v-model="reactiveFormValues.address.address2"
          :disabled="isBusy"
          :class="{
            'Input--inline-error':
              formErrors && formErrors['address::address2'],
          }"
        />
      </div>
    </div>

    <div class="FormRow">
      <div class="PersonalInfoForm__Field FormCol">
        <label for="address_city">
          {{ t('personal_info_address_city_label') }}
          <abbr title="required">*</abbr></label
        >
        <input
          name="address_city"
          type="text"
          v-model="reactiveFormValues.address.city"
          required
          :disabled="isBusy"
          :class="{
            'Input--inline-error': formErrors && formErrors['address::city'],
          }"
        />
      </div>
      <div class="PersonalInfoForm__Field FormCol">
        <label for="address_province">{{
          t('personal_info_address_county_label')
        }}</label>
        <input
          name="address_province"
          type="text"
          v-model="reactiveFormValues.address.province"
          :disabled="isBusy"
          :class="{
            'Input--inline-error': formErrors && formErrors['address::county'],
          }"
        />
      </div>
    </div>

    <div class="FormRow">
      <div class="PersonalInfoForm__Field FormCol">
        <label for="address_zip">
          {{ t('personal_info_address_postcode_label') }}
          <abbr title="required">*</abbr></label
        >
        <input
          name="address_zip"
          type="text"
          v-model="reactiveFormValues.address.zip"
          required
          :disabled="isBusy"
          :class="{
            'Input--inline-error': formErrors && formErrors['address::zip'],
          }"
        />
      </div>
    </div>

    <h5 class="PersonalInfoForm__Title">
      {{ t('personal_info_contact_details_fieldset_title') }}
    </h5>

    <div class="FormRow">
      <div class="PersonalInfoForm__Field FormCol">
        <label for="address_phone">
          {{ t('personal_info_phone_label') }}
          <abbr title="required">*</abbr>
        </label>
        <input
          name="address_phone"
          type="text"
          v-model="reactiveFormValues.address.phone"
          :disabled="isBusy"
          :class="{
            'Input--inline-error': formErrors && formErrors['address::phone'],
          }"
        />
      </div>
    </div>

    <div class="FormActions ButtonGroup">
      <Button :modifiers="['block']" :disabled="isBusy">
        {{ t('personal_info_update_cta') }}
      </Button>
      <Button
        :modifiers="['block', 'link', 'error']"
        :disabled="isBusy"
        @click="$emit('back')"
        type="button"
      >
        {{ t('personal_info_cancel_cta') }}
      </Button>
    </div>
  </form>
</template>

<style>
.PersonalInfoForm {
  padding: 24px;
}
</style>
