<script lang="ts" setup>
import Message from '@/components/Message.vue'
import IconStatusInfo from '@/assets/icons/status-info.svg?component'

import { formatDate } from '@/lib/formatters/date'
import { ref, watch } from 'vue'
import type { Order } from '@/stores/nextOrder'

import { t } from '@/lib/locale'

const props = defineProps<{
  nextOrder: Order | null
}>()

const emit = defineEmits<{
  (e: 'change', date: number): void
}>()

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
const selectedDate = ref<number>(
  props.nextOrder
    ? new Date(props.nextOrder.scheduled_at).getDate()
    : tomorrow.getDate()
)

watch(selectedDate, () => {
  emit('change', selectedDate.value)
})
</script>

<template>
  <div class="Card OnboardingPanel">
    <h5 class="OnboardingPanel__Title">{{ t('onboarding_date_title') }}</h5>
    <p>
      {{ t('onboarding_date_intro') }}
    </p>
    <div
      v-if="nextOrder"
      v-html="
        t('onboarding_date_auto_select_copy', {
          next: formatDate('DD Mon', nextOrder.scheduled_at),
          day: formatDate('DDth', nextOrder.scheduled_at),
        })
      "
    />
    <template v-else>
      <p>
        {{ t('onboarding_date_manual_select_copy') }}
      </p>
      <p>
        <select v-model="selectedDate">
          <option v-for="i in 28" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </p>
    </template>
    <Message mode="mid">
      <template #icon><IconStatusInfo /></template>
      <p>
        <strong>{{ t('onboarding_date_info_box_title') }}</strong>
      </p>
      <p>
        {{ t('onboarding_date_info_box_copy') }}
      </p>
    </Message>
  </div>
</template>
