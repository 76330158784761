<script setup lang="ts">
import Layout from '@/components/layout/Layout.vue'
import FutureOrderList from '@/components/FutureOrderList.vue'

import { useFutureOrderStore } from '@/stores/futureOrder'
import type { AuthenticatedEntity } from '@/stores/auth'

import { t } from '@/lib/locale'

import { useBackLink } from './lib/backLink'
import { useProductImageStore } from '@/stores/productImage'
import { useRouter } from 'vue-router'
import { useSubscriptionStore } from '@/stores/subscription'
import { watch } from 'vue'

const router = useRouter()
const { backTo } = useBackLink()

const props = defineProps<{ authedUser: AuthenticatedEntity }>()

const futureOrderStore = useFutureOrderStore()
futureOrderStore.init(props.authedUser.rechargeId)

watch(
  () => futureOrderStore.stale,
  () => {
    if (futureOrderStore.stale) futureOrderStore.init()
  }
)

const edit = (id: string) => {
  router.push(`/subscriptions/${id}`)
}

const productImageStore = useProductImageStore()
const subscriptionStore = useSubscriptionStore()
</script>

<template>
  <Layout
    :title="t('future_orders_title')"
    :description="t('future_orders_description')"
    :back-link="{ text: t('your_account_back_cta') }"
    @back="backTo('/')"
  >
    <template #main>
      <FutureOrderList
        :orders="futureOrderStore.orders"
        :load-state="futureOrderStore.loadState"
        :work-state="subscriptionStore.workState"
        :images-by-product-id="productImageStore.productImages"
        @edit-item="edit($event)"
      ></FutureOrderList>
    </template>
  </Layout>
</template>
