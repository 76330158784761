<script lang="ts" setup>
import { ref, computed } from 'vue'
import type { Component } from 'vue'

const props = defineProps<{
  svgContent: Component
  size?: string
  animate?: boolean
  flipY?: boolean
}>()

const iconClasses = computed(() => {
  const classes = []
  if (props.size) {
    classes.push(`Icon--${props.size}`)
  }
  if (props.animate) {
    classes.push('Icon--animated')
  }
  if (props.flipY) {
    classes.push('Icon--flip-y')
  }
  return classes
})

const iconEl = ref<HTMLElement | null>(null)

let path: SVGPathElement | null

const beforeEnter = function (el: Element) {
  if (props.animate) {
    path = el.querySelector('path')
    if (path) {
      const length = path.getTotalLength()
      path.style.strokeDasharray = length.toString()
      path.style.strokeDashoffset = length.toString()
      path.style.transition = 'stroke-dashoffset .2s ease-in-out'
    }
  }
}
const enter = function () {
  if (props.animate) {
    setTimeout(() => {
      if (path) path.style.strokeDashoffset = '0'
    }, 100)
  }
}
const leave = function () {
  if (props.animate) {
    if (iconEl.value) iconEl.value.style.display = 'none'
  }
}
</script>

<template>
  <transition appear @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <component
      :is="svgContent"
      class="Icon"
      :class="iconClasses"
      ref="iconEl"
    />
  </transition>
</template>

<style>
.Icon {
  height: 16px;
  width: 16px;
  display: block;
}

.Icon > svg {
  display: block;
  width: 100%;
  height: auto;
}

.Icon--flip-y {
  transform: rotateY(180deg);
}
</style>
