import Bugsnag from '@bugsnag/js'
import mixpanel from 'mixpanel-browser'
import { defineStore } from 'pinia'
import { getToken } from '../services/authentication'

const TOKEN_MAYBE_STALE_THRESHOLD_MS = 1000 * 60 * 60 // one hour

export type AuthenticatedEntity = {
  shopifyId: string
  rechargeId: string
  impersonating?: boolean
}

export enum AuthenticationState {
  IDLE,
  PENDING,
  AUTHENTICATED,
  UNAUTHENTICATED,
  LOGGED_OUT,
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as AuthenticatedEntity | null,
    authenticationState: AuthenticationState.IDLE,
    authenticationError: null as unknown,
    lastSuccessfulAuth: null as Date | null,
    renewTimeoutHandle: null as number | null,
  }),
  getters: {
    isAuthenticated: (state) =>
      state.authenticationState === AuthenticationState.AUTHENTICATED,
    needsUpdating: (state) =>
      state.authenticationState !== AuthenticationState.AUTHENTICATED ||
      !state.lastSuccessfulAuth ||
      new Date().valueOf() - state.lastSuccessfulAuth.valueOf() >
        TOKEN_MAYBE_STALE_THRESHOLD_MS,
  },
  actions: {
    async authenticate(tempToken?: string) {
      // prevent unnecessary requests by only renewing the token when stale
      if (!this.needsUpdating) return
      try {
        const user = await getToken(tempToken)
        this.user = user
        this.lastSuccessfulAuth = new Date()
        this.authenticationState = AuthenticationState.AUTHENTICATED
        mixpanel.identify(user.shopifyId)
        Bugsnag.setUser(user.shopifyId)
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.authenticationState = AuthenticationState.UNAUTHENTICATED
        this.authenticationError = e
      }
    },

    async logout() {
      await fetch(`${import.meta.env.VITE_API_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
      })
      this.authenticationState = AuthenticationState.LOGGED_OUT
    },
  },
})
