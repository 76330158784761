import { defineStore } from 'pinia'
import { getHandleForProduct } from '@/services/storefront'

export const useProductLinkStore = defineStore('productLink', {
  state: () => ({
    productLinks: {} as Record<string, string | undefined>,
  }),
  actions: {
    async getLinkForProduct(id: string) {
      if (!Object.hasOwnProperty.call(this.productLinks, id)) {
        const product = await getHandleForProduct(id)
        if (product) {
          this.productLinks[id] = product.handle
        }
      }
    },
    async loadLinksForProducts(ids: string[]) {
      const uniqueIdsWithoutLinks = ids.reduce((ids, id) => {
        if (!Object.hasOwnProperty.call(this.productLinks, id)) {
          ids.add(id)
        }
        return ids
      }, new Set<string>())
      uniqueIdsWithoutLinks.forEach(async (id) => {
        this.getLinkForProduct(id)
      })
    },
  },
  getters: {
    generateLinkForProduct: (state) => {
      return (productId: string, variantId?: string) => {
        const variant = variantId ? `?variant=${variantId}` : ''
        return `https://bowercollective.com/products/${state.productLinks[productId]}${variant}`
      }
    },
  },
})
