<script setup lang="ts">
import Button from '@/components/Button.vue'
import Select from '@/components/Select.vue'
import QuantityControl from '@/components/QuantityControl.vue'
import { Product } from '@/services/storefront'
import { computed, ref } from 'vue'
import { formatMoneyWithCurrency } from '@/lib/formatters/money'

const props = defineProps<{
  product: Product
  isBusy: boolean
}>()

const emit = defineEmits<{
  (
    e: 'addToOrder',
    productId: string,
    variantId: string,
    quantity: number,
    planId: string | null
  ): void
}>()

const selectedVariantId = ref<string | null>(
  props.product.variants.edges[0].node.id.toString()
)

const selectedVariant = computed(
  () =>
    props.product.variants.edges.find((node) => {
      return node.node.id.toString() === selectedVariantId.value
    }) as Product['variants']['edges'][0]
)

const hasSellingPlans = computed(() => {
  return props.product.sellingPlanGroups.edges.length > 0
})

const selectedSellingPlanId = ref<string | null>(
  hasSellingPlans.value
    ? props.product.sellingPlanGroups.edges[0].node.sellingPlans.edges[0].node.id.toString()
    : null
)

const selectedSellingPlan = computed(() => {
  if (!selectedSellingPlanId.value) return null
  const sellingPlan =
    props.product.sellingPlanGroups.edges[0].node.sellingPlans.edges.find(
      (node) => node.node.id.toString() === selectedSellingPlanId.value
    )
  return sellingPlan ? sellingPlan.node : null
})

const pricePer = computed(() => {
  const volume = props.product.tags.find((str) => /__volume_ml:/.test(str))
  const weight = props.product.tags.find((str) => /__weight_g:/.test(str))
  if (volume) {
    const v = parseInt(volume.replace('__volume_ml:', '').trim())
    return {
      unit: '100ml',
      value:
        (100 / v) * (parseFloat(selectedVariant.value.node.price.amount) * 100),
      subscribeValue: subscribePrice.value
        ? (100 / v) * subscribePrice.value
        : null,
    }
  } else if (weight) {
    const w = parseInt(weight.replace('__weight_g:', '').trim())
    return {
      unit: '100g',
      value:
        (100 / w) * (parseFloat(selectedVariant.value.node.price.amount) * 100),
      subscribeValue: subscribePrice.value
        ? (100 / w) * subscribePrice.value
        : null,
    }
  } else {
    return null
  }
})

const subscribePrice = computed(() => {
  if (!selectedSellingPlan.value) return null
  return Math.round(
    ((100 -
      selectedSellingPlan.value.priceAdjustments[0].adjustmentValue
        .adjustmentPercentage) /
      100) *
      (parseFloat(selectedVariant.value.node.price.amount) * 100)
  )
})

const quantity = ref(1)

const onAddToOrder = () => {
  emit(
    'addToOrder',
    props.product.id,
    selectedVariant.value.node.id,
    quantity.value,
    selectedSellingPlanId.value
  )
}
</script>

<template>
  <div class="UpsellModalProduct">
    <div class="UpsellModalProduct__Media">
      <img
        :src="product.images.edges[0].node.medium"
        alt="Product image"
        width="200"
      />
      <h4>{{ product.title }}</h4>
    </div>
    <div class="Stack Stack--small">
      <div>
        <div class="UpsellModalProduct__PriceLine">
          <div class="UpsellModalProduct__PriceLineLeft">
            <span v-if="subscribePrice">
              {{ formatMoneyWithCurrency((subscribePrice / 100).toString()) }}
              <s>
                {{ formatMoneyWithCurrency(selectedVariant.node.price.amount) }}
              </s>
            </span>
            <span v-else>
              {{ formatMoneyWithCurrency(selectedVariant.node.price.amount) }}
            </span>
          </div>
          <div class="UpsellModalProduct__PriceLineRight">
            <span v-if="pricePer">
              <span v-if="pricePer.subscribeValue">
                <s>
                  {{
                    formatMoneyWithCurrency(
                      (Math.round(pricePer.value) / 100).toString()
                    )
                  }}
                </s>
                {{
                  formatMoneyWithCurrency(
                    (Math.round(pricePer.subscribeValue) / 100).toString()
                  )
                }}
              </span>
              <span v-else>
                {{
                  formatMoneyWithCurrency(
                    (Math.round(pricePer.value) / 100).toString()
                  )
                }}
              </span>
              /
              {{ pricePer.unit }}
            </span>
          </div>
        </div>
        <div class="UpsellModalProduct__PurchaseOptions">
          <span>Send me</span>
          <QuantityControl
            :value="1"
            :is-busy="isBusy"
            :debounce-delay="1"
            @change-quantity="quantity = $event"
          ></QuantityControl>
          <Select
            v-if="hasSellingPlans"
            :value="selectedSellingPlanId ? selectedSellingPlanId : 'NONE'"
            :disabled="false"
            @change="selectedSellingPlanId = $event === 'NONE' ? null : $event"
          >
            <option
              v-for="sellingPlanAllocation in selectedVariant?.node
                .sellingPlanAllocations.edges"
              :key="sellingPlanAllocation.node.sellingPlan.id"
              :value="sellingPlanAllocation.node.sellingPlan.id"
            >
              {{
                sellingPlanAllocation.node.sellingPlan.name
                  .replace(/^delivery /i, '')
                  .replace('1 ', '')
                  .toLowerCase()
              }}
            </option>
            <option value="NONE">once only</option>
          </Select>
        </div>
      </div>
      <Button
        :modifiers="['block']"
        @click="onAddToOrder"
        @disabled="isBusy"
        :loading="isBusy"
        :class="{
          'Button--is-disabled': isBusy,
        }"
      >
        Add to next order
      </Button>
    </div>
  </div>
</template>

<style>
.UpsellModalProduct__PriceLine {
  display: flex;
  justify-content: space-between;
  margin: calc(var(--grid-gap) * 0.5) 0;
  font-weight: bold;
  align-items: baseline;

  & s {
    color: var(--color-forest-50);
  }
}

.UpsellModalProduct__PriceLineRight {
  display: flex;
  gap: 5px;
}

.UpsellModalProduct__PriceLineLeft {
  font-size: 26px;
  font-family: var(--font-family-domaine);
  display: flex;
  gap: 5px;

  & s {
    font-size: var(--font-size--base);
    font-family: var(--font-family-rund);
  }
}

.UpsellModalProduct__PurchaseOptions {
  display: flex;
  align-items: center;
  gap: 5px;

  & > span:first-child {
    flex-grow: 1;
    max-width: 35%;
  }

  & > .FormField--dropdown {
    flex-grow: 1;
  }
}

.UpsellModalProduct__Media {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: var(--grid-gap);

  & > h4 {
    margin: 0 32px 0 0;
  }

  & > img {
    width: 100%;
    height: auto;
    border-radius: var(--radius-small);
  }

  @media (--mq-phablet-and-up) {
    flex-direction: row;

    & > img {
      width: 100px;
    }
  }
}
</style>
