import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, {
  type BugsnagPluginVueResult,
} from '@bugsnag/plugin-vue'
import mixpanel from 'mixpanel-browser'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: import.meta.env.MODE,
  autoTrackSessions: false,
})

Bugsnag.addOnError((event) => {
  if (event.errors[0].errorClass === 'FetchError') {
    event.addMetadata('fetch', 'url', event.originalError.url)
    event.addMetadata('fetchResponse', event.originalError.res)
  }
  if (event.errors[0].errorClass === 'TypeError') {
    // Ignore request errors that were the browser cancelling fetch() calls
    if (
      [
        'cancelled',
        'Failed to fetch',
        'NetworkError when attempting to fetch resource.',
      ].includes(event.errors[0].errorMessage)
    )
      return false
  }
})

mixpanel.init(import.meta.env.VITE_MIXPANEL_API_KEY, {
  debug: import.meta.env.MODE !== 'production',
})

import App from './App.vue'
import router from './router'

const bugsnagVue = Bugsnag.getPlugin('vue')
const app = createApp(App)

app.use(bugsnagVue as BugsnagPluginVueResult)
app.use(createPinia())
app.use(router)

app.mount('#app')
