import {
  createPaymentMethodSetupIntent,
  removePaymentMethod,
  savePaymentMethod,
  setActivePaymentMethod,
  requestPaymentMethodEmail,
} from '@/services/paymentMethod'
import { defineStore } from 'pinia'
import {
  getCustomer,
  saveCustomerDetails,
} from '../services/subscriptionCustomer'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { useNextOrderStore, type Discount } from './nextOrder'
import { useTaskStore } from './task'

import { t } from '@/lib/locale'
import Bugsnag from '@bugsnag/js'

export type AddressField = keyof Customer['include']['addresses'][number]

interface Address {
  id: string
  customer_id: string
  address1: string
  address2?: string
  city: string
  company?: string
  country_code?: string
  created_at: string
  discounts: Discount[]
  first_name: string
  last_name: string
  order_attributes?: Record<string, unknown>[]
  order_note: string
  phone: string
  province?: string
  shipping_lines_override: []
  updated_at: string
  zip: string
}

export interface PaymentMethod {
  id: string
  customer_id: string
  billing_address: {
    address1: string
    address2?: string
    city: string
    company?: string
    country: string
    country_code?: string
    first_name: string
    last_name: string
    phone: string
    province?: string
    zip: string
  }
  created_at: string
  default: boolean
  payment_details: {
    brand: string
    exp_month: number
    exp_year: number
    last4: string
  }
  payment_type: string
  processor_name: 'shopify_payments' | 'stripe' | string
  status: unknown
  status_reason: unknown
  updated_at: string
}

export interface Customer {
  id: string
  created_at: string
  email: string
  external_customer_id: {
    ecommerce: string
  }
  first_charge_processed_at: string
  first_name: string
  has_payment_method_in_dunning: boolean
  has_valid_payment_method: boolean
  hash: string
  last_name: string
  subscriptions_active_count: number
  subscriptions_total_count: number
  updated_at: string
  include: {
    addresses: Address[]
    payment_methods: PaymentMethod[]
  }
}

export const useSubscriptionCustomerStore = defineStore(
  'subscriptionCustomer',
  {
    state: () => ({
      loadState: AsyncOperationState.IDLE,
      customer: null as Customer | null,
      setupIntentLoadState: AsyncOperationState.IDLE,
      workState: AsyncOperationState.IDLE,
    }),
    actions: {
      async init(rechargeId: string) {
        if (this.loadState === AsyncOperationState.IDLE) {
          this.load(rechargeId)
        }
      },
      async load(rechargeId: string) {
        this.loadState = AsyncOperationState.IN_PROGRESS
        try {
          this.customer = await getCustomer(rechargeId)
          this.loadState = AsyncOperationState.DONE
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.loadState = AsyncOperationState.ERRORED
        }
      },
      async createPaymentMethodSetupIntent(rechargeId: string) {
        this.setupIntentLoadState = AsyncOperationState.IN_PROGRESS
        try {
          const intent = await createPaymentMethodSetupIntent(rechargeId)
          this.setupIntentLoadState = AsyncOperationState.DONE
          return intent
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.setupIntentLoadState = AsyncOperationState.ERRORED
        }
      },
      async savePaymentMethod(
        rechargeId: string,
        stripePaymentMethodId: string
      ) {
        this.workState = AsyncOperationState.IN_PROGRESS
        const taskStore = useTaskStore()
        const taskId = taskStore.setTask(
          t('payment_method_add_toast_progress'),
          this.workState
        )
        try {
          const intent = await savePaymentMethod(
            rechargeId,
            stripePaymentMethodId
          )
          this.workState = AsyncOperationState.DONE
          taskStore.updateTask(
            taskId,
            t('payment_method_add_toast_success'),
            this.workState
          )
          this.load(rechargeId)
          return intent
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
          taskStore.updateTask(
            taskId,
            t('payment_method_add_toast_error'),
            this.workState
          )
        }
      },
      async setActivePaymentMethod(
        rechargeId: string,
        paymentMethodId: string
      ) {
        this.workState = AsyncOperationState.IN_PROGRESS
        const taskStore = useTaskStore()
        const taskId = taskStore.setTask(
          t('payment_method_set_active_toast_progress'),
          this.workState
        )
        try {
          await setActivePaymentMethod(rechargeId, paymentMethodId)
          this.workState = AsyncOperationState.DONE
          taskStore.updateTask(
            taskId,
            t('payment_method_set_active_toast_success'),
            this.workState
          )
          this.load(rechargeId)
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
          taskStore.updateTask(
            taskId,
            t('payment_method_set_active_toast_error'),
            this.workState
          )
        }
      },
      async removePaymentMethod(rechargeId: string, paymentMethodId: string) {
        this.workState = AsyncOperationState.IN_PROGRESS
        const taskStore = useTaskStore()
        const taskId = taskStore.setTask(
          t('payment_method_remove_toast_progress'),
          this.workState
        )
        try {
          await removePaymentMethod(rechargeId, paymentMethodId)
          this.workState = AsyncOperationState.DONE
          taskStore.updateTask(
            taskId,
            t('payment_method_remove_toast_success'),
            this.workState
          )
          this.load(rechargeId)
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
          taskStore.updateTask(
            taskId,
            t('payment_method_remove_toast_error'),
            this.workState
          )
        }
      },
      async updateDetails(
        rechargeId: string,
        details: {
          first_name: string
          last_name: string
          address: Record<AddressField, string>
        }
      ) {
        this.workState = AsyncOperationState.IN_PROGRESS
        const taskStore = useTaskStore()
        const taskId = taskStore.setTask(
          t('personal_info_toast_progress'),
          this.workState
        )
        try {
          await saveCustomerDetails(rechargeId, details)
          this.workState = AsyncOperationState.DONE
          taskStore.updateTask(
            taskId,
            t('personal_info_toast_success'),
            this.workState
          )
          this.load(rechargeId)
          const nextOrderStore = useNextOrderStore()
          nextOrderStore.stale = true
          return { success: true }
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
          taskStore.updateTask(
            taskId,
            t('personal_info_toast_error'),
            this.workState
          )
          return { success: false, errors: (e as { errors: unknown }).errors }
        }
      },
      async requestPaymentMethodEmail(
        rechargeId: string,
        paymentMethodId: string
      ) {
        this.workState = AsyncOperationState.IN_PROGRESS
        const taskStore = useTaskStore()
        const taskId = taskStore.setTask(
          t('payment_method_email_toast_progress'),
          this.workState
        )
        try {
          await requestPaymentMethodEmail(rechargeId, paymentMethodId)
          this.workState = AsyncOperationState.DONE
          taskStore.updateTask(
            taskId,
            t('payment_method_email_toast_success'),
            this.workState
          )
          this.load(rechargeId)
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
          taskStore.updateTask(
            taskId,
            t('payment_method_email_toast_error'),
            this.workState
          )
        }
      },
    },
    getters: {
      cardLastFourDigits(): string | null {
        if (!this.customer) return null
        const defaultMethod = this.customer.include.payment_methods.find(
          (method) => method.default
        )
        if (!defaultMethod) return null
        return defaultMethod.payment_details.last4
      },
      phoneNumber(): string | null {
        if (!this.customer) return null
        return this.customer.include.addresses[0]?.phone
      },
      paymentMethods(): PaymentMethod[] {
        if (!this.customer) return []
        return this.customer.include.payment_methods.filter(
          (method) => !!method.id
        )
      },
    },
  }
)
