import { useRouter } from 'vue-router'
export function useBackLink() {
  const router = useRouter()
  return {
    backTo: (location: string) => {
      if (
        window.history.state &&
        window.history.state.back &&
        window.history.state.back === location
      ) {
        router.back()
      } else {
        router.push(location)
      }
    },
  }
}
