<script setup lang="ts">
import Button from '@/components/Button.vue'
import { t } from '@/lib/locale'

import { ref } from 'vue'
import type { Order } from '@/stores/nextOrder'
import type { Customer } from '@/stores/subscriptionCustomer'
import OnboardingDateSelector from './OnboardingDateSelector.vue'
import OnboardingAddressSelector from './OnboardingAddressSelector.vue'
import { formatDate } from '@/lib/formatters/date'

const props = defineProps<{
  nextOrder: Order | null
  customer: Customer
  isBusy: boolean
}>()
const emit = defineEmits<{
  (
    e: 'submit',
    fromDate: string,
    toDate: string,
    selectedAddressId: string
  ): void
}>()

const selectedDate = ref<number>()

const selectedAddressId = ref<string>(
  props.customer.include.addresses[0].id.toString()
)
const onSubmit = () => {
  const fromDate = props.nextOrder ? props.nextOrder.scheduled_at : new Date()
  const toDate = props.nextOrder
    ? props.nextOrder.scheduled_at
    : (() => {
        const d = new Date()
        d.setDate(selectedDate.value || 1)
        if (d < new Date()) d.setMonth(d.getMonth() + 1)
        return d
      })()
  emit(
    'submit',
    formatDate('YYYY-MM-DD', fromDate),
    formatDate('YYYY-MM-DD', toDate),
    selectedAddressId.value
  )
}
</script>

<template>
  <div class="Onboarding Stack">
    <h1 class="h2">{{ t('onboarding_title') }}</h1>
    <p v-html="t('onboarding_subtitle')"></p>
    <OnboardingDateSelector
      :next-order="nextOrder"
      @change="selectedDate = $event"
    ></OnboardingDateSelector>
    <OnboardingAddressSelector
      :customer="customer"
      @change="selectedAddressId = $event"
      v-if="customer.include.addresses.length > 1"
    ></OnboardingAddressSelector>
    <Button @click="onSubmit" :disabled="isBusy">
      {{ t('onboarding_confirm_cta') }}
    </Button>
  </div>
</template>

<style>
.Onboarding {
  padding: 0 24px;
}

@media (--mq-tablet-and-up) {
  .Onboarding {
    padding: 0;
  }
}

.OnboardingPanel {
  padding: 24px;
}

.OnboardingPanel__Title {
  margin-bottom: 10px;
}

.Onboarding__ButtonGroup {
  margin-bottom: 0;
}
</style>
