import { createRouter, createWebHistory } from 'vue-router'
import NotFoundView from '@/views/NotFoundView.vue'
import LogoutView from '@/views/LogoutView.vue'
import LoginView from '@/views/LoginView.vue'
import YourAccountView from '@/views/YourAccountView.vue'
import YourDetailsView from '@/views/YourDetailsView.vue'
import YourPreviousOrdersView from '@/views/YourPreviousOrdersView.vue'
import YourFutureOrdersView from '@/views/YourFutureOrdersView.vue'
import YourSubscriptionsView from '@/views/YourSubscriptionsView.vue'
import NewPaymentMethodView from '@/views/NewPaymentMethodView.vue'
import SubscriptionView from '@/views/SubscriptionView.vue'
import NextOrderItemView from '@/views/NextOrderItemView.vue'
import OnboardingView from '@/views/OnboardingView.vue'
import OrderView from '@/views/OrderView.vue'
import DebugView from '@/views/DebugView.vue'
import { useAuthStore } from '@/stores/auth'
import PersonalInfoView from '@/views/PersonalInfoView.vue'
import mixpanel from 'mixpanel-browser'
import { useOnboardingStore } from '@/stores/onboarding'
import Bugsnag from '@bugsnag/js'

declare module 'vue-router' {
  interface RouteMeta {
    windowTitle?: string
  }
}

const authenticatedRouteGuard = async () => {
  const authStore = useAuthStore()
  try {
    await authStore.authenticate()
    if (!authStore.isAuthenticated) {
      throw authStore.authenticationError || new Error('Not authenticated')
    }
  } catch (e) {
    if (e && (e as Error).name === 'FetchError') {
      if ((e as { res: Response }).res.status === 401) {
        return '/logout'
      } else {
        // TODO create an error page specific for this
        return '/login'
      }
    } else {
      // TODO create an error page specificily for this
      Bugsnag.notify(e as Error)
      return '/login'
    }
  }
}

const onboardingRouteGuard = async () => {
  if (window.location.hostname === 'localhost') return true
  const onboardingStore = useOnboardingStore()
  const authStore = useAuthStore()
  if (!authStore.user?.rechargeId) return
  try {
    const status = await onboardingStore.init(authStore.user.rechargeId)
    if (!status.hasSetDate) {
      return '/welcome'
    }
    return true
  } catch (e) {
    Bugsnag.notify(e as Error)
    return '/error'
  }
}

const getAuthedUser = () => useAuthStore().user

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'account',
      component: YourAccountView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/next-order/item/:id',
      name: 'account--nextorder--item',
      component: NextOrderItemView,
      props: (route) => ({
        authedUser: getAuthedUser(),
        id: route.params.id,
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/details',
      name: 'details',
      meta: {
        windowTitle: 'Your details',
      },
      component: YourDetailsView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/details/personal-info',
      name: 'details--personal-info',
      meta: {
        windowTitle: 'Personal info',
      },
      component: PersonalInfoView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/details/new-payment-method',
      name: 'details--new-payment-method',
      meta: {
        windowTitle: 'New payment method',
      },
      component: NewPaymentMethodView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/previous-orders',
      name: 'previous-orders',
      meta: {
        windowTitle: 'Previous orders',
      },
      component: YourPreviousOrdersView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/previous-orders/:id',
      name: 'previous-orders--item',
      meta: {
        windowTitle: 'Previous orders',
      },
      component: OrderView,
      props: (route) => ({
        authedUser: getAuthedUser(),
        id: route.params.id,
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/future-orders',
      name: 'future-orders',
      meta: {
        windowTitle: 'Future orders',
      },
      component: YourFutureOrdersView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      meta: {
        windowTitle: 'Your subscriptions',
      },
      component: YourSubscriptionsView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/subscriptions/:id',
      name: 'subscriptions--item',
      meta: {
        windowTitle: 'Your subscriptions',
      },
      component: SubscriptionView,
      props: (route) => ({
        authedUser: getAuthedUser(),
        id: route.params.id,
      }),
      beforeEnter: [authenticatedRouteGuard, onboardingRouteGuard],
    },
    {
      path: '/welcome',
      name: 'welcome',
      meta: {
        windowTitle: 'Welcome',
      },
      component: OnboardingView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard],
    },
    {
      path: '/logout',
      name: 'Logout',
      component: LogoutView,
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView,
    },
    {
      path: '/debug',
      name: 'Debug',
      component: DebugView,
      props: () => ({
        authedUser: getAuthedUser(),
      }),
      beforeEnter: [authenticatedRouteGuard],
    },
    {
      path: '/style-guide',
      name: 'style-guide',
      component: () => import('../views/StyleGuideView.vue'),
    },
    {
      path: '/quick-actions/:action_name',
      name: 'quick-actions',
      meta: {
        windowTitle: 'Quick actions',
      },
      props: (route) => ({
        actionName: route.params.action_name,
      }),
      component: () => import('../views/QuickActionsView.vue'),
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView },
  ],
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  },
})

router.afterEach((to) => {
  mixpanel.track('page_view', { url: to.fullPath })
  if (to.meta.windowTitle) {
    window.document.title = `${to.meta.windowTitle} – Bower Collective`
  } else {
    window.document.title = `Your account – Bower Collective`
  }
})

export default router
