<script setup lang="ts">
import Layout from '@/components/layout/Layout.vue'
import PreviousOrderList from '@/components/PreviousOrderList.vue'

import { useOrderStore } from '@/stores/order'
import type { AuthenticatedEntity } from '@/stores/auth'

import { t } from '@/lib/locale'

import { useBackLink } from './lib/backLink'

const { backTo } = useBackLink()

const props = defineProps<{ authedUser: AuthenticatedEntity }>()

const orderStore = useOrderStore()
orderStore.init(props.authedUser.shopifyId)
</script>

<template>
  <Layout
    :title="t('previous_orders_title')"
    :description="t('previous_orders_description')"
    :back-link="{ text: t('your_account_back_cta') }"
    @back="backTo('/')"
  >
    <template #main>
      <PreviousOrderList
        :orders="orderStore.orders"
        :load-state="orderStore.loadState"
        :page-info="orderStore.pageInfo"
        @previous="orderStore.loadPreviousPage(props.authedUser.shopifyId)"
        @next="orderStore.loadNextPage(props.authedUser.shopifyId)"
      ></PreviousOrderList>
    </template>
  </Layout>
</template>
