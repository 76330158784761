import fetch from './lib/fetch'

export async function getRecommendations(
  shopifyId: string,
  category: string,
  query: Record<string, string> = {}
) {
  const qs = new URLSearchParams(query)
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/ecommerce/${shopifyId}/recommendations/${category}?${qs.toString()}`
  )
}
