<script lang="ts" setup></script>

<template>
  <div class="FullWidthBanner FullWidthBanner--mid">
    <div class="Container">
      <h2 class="h4">
        <slot name="title" />
      </h2>
      <p>
        <slot />
      </p>
    </div>
  </div>
</template>

<style>
.FullWidthBanner {
  text-align: center;
  padding: 32px 0 40px;
}

.FullWidthBanner h2 {
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
