import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { getCustomerWasteMetrics } from '../services/order'
import { getProductVariantSkus } from '@/services/storefront'
import Bugsnag from '@bugsnag/js'

const apiUrl = 'https://api.bowercollective.com/plastic-waste/pwe-by-sku'

type SkuPweMapEntries = {
  sku: string
  product_title: string
  height: number
  weight: number
  volume: number
}[]

export interface WasteMetrics {
  height: number
  weight: number
  volume: number
}

export const useWasteMetricsStore = defineStore('wasteMetrics', {
  state: () => ({
    customerWasteMetricsloadState: AsyncOperationState.IDLE,
    customerWasteMetrics: null as WasteMetrics | null,
    nextOrderWasteMetricsLoadState: AsyncOperationState.IDLE,
    nextOrderWasteMetrics: null as WasteMetrics | null,
    skuPweMap: null as null | SkuPweMapEntries,
  }),
  actions: {
    async getSkuPweMap() {
      if (!this.skuPweMap) {
        this.skuPweMap = await (await fetch(apiUrl)).json()
        return this.skuPweMap
      } else {
        return this.skuPweMap
      }
    },
    async initCustomerWasteMetrics(shopifyId: string) {
      if (!this.customerWasteMetrics) this.getCustomerWasteMetrics(shopifyId)
    },
    async getCustomerWasteMetrics(shopifyId: string) {
      this.customerWasteMetricsloadState = AsyncOperationState.IN_PROGRESS
      try {
        const res = await getCustomerWasteMetrics(shopifyId)
        this.customerWasteMetrics = res
        this.customerWasteMetricsloadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.customerWasteMetricsloadState = AsyncOperationState.ERRORED
      }
    },
    async initNextOrderWasteMetrics(
      products: { variantId: string; quantity: number }[]
    ) {
      this.nextOrderWasteMetricsLoadState = AsyncOperationState.IN_PROGRESS
      const skuPweMap = await this.getSkuPweMap()
      const skus = await getProductVariantSkus(
        products.map((product) => product.variantId)
      )
      if (skus && skuPweMap) {
        const orderData = products.reduce((map, orderLineItem) => {
          const type =
            skus.find(
              (type) =>
                type &&
                type.id.replace('gid://shopify/ProductVariant/', '') ===
                  orderLineItem.variantId
            )?.sku || '<Uncategorised>'
          const currentCount = map[type] || 0
          return {
            ...map,
            [type]: currentCount + orderLineItem.quantity,
          }
        }, {} as Record<string, number>)
        const wasteCount = { weight: 0, height: 0, volume: 0 }
        for (const sku in orderData) {
          const pwe = skuPweMap.find((item) => item.sku === sku)
          if (!pwe) continue
          wasteCount.weight += pwe.weight * orderData[sku]
          wasteCount.height += pwe.height * orderData[sku]
          wasteCount.volume += pwe.volume * orderData[sku]
        }
        this.nextOrderWasteMetrics = wasteCount
      } else {
        this.nextOrderWasteMetricsLoadState = AsyncOperationState.ERRORED
      }
    },
  },
})
