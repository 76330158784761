<script lang="ts" setup></script>

<template>
  <b class="GhostUI SimpleCta">
    <slot></slot>
  </b>
</template>

<style>
.SimpleCta.GhostUI {
  min-height: 76px;
  background-color: var(--color-white) !important;
  animation-name: simple-cta-ghost-ui-fade !important;
}
@media (--mq-tablet-and-up) {
  .SimpleCta.GhostUI {
    min-height: 80px;
  }
}

@keyframes simple-cta-ghost-ui-fade {
  0% {
    background-color: var(--color-warm-white-100);
  }

  50% {
    background-color: var(--color-white);
  }

  100% {
    background-color: var(--color-warm-white-100);
  }
}
</style>
