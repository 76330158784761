<script lang="ts" setup>
import Message from '@/components/Message.vue'
import Loading from '@/components/Loading.vue'
import Button from '@/components/Button.vue'
import { ref } from 'vue'

import { t } from '../lib/locale'

const props = defineProps<{
  error?: string
  isLoading: boolean
  onPaymentSubmit: () => void | Promise<void>
}>()

const handlingPaymentSubmit = ref<boolean>(false)
const onPaymentSubmit = async (event: Event) => {
  event.preventDefault()
  handlingPaymentSubmit.value = true
  await props.onPaymentSubmit()
  handlingPaymentSubmit.value = false
}
</script>

<template>
  <div class="NewPaymentMethod Card">
    <h3>{{ t('payment_method_add_title') }}</h3>
    <Message v-if="error" mode="error" class="NewPaymentMethod__ErrorMessage">
      {{ error }}
    </Message>
    <Loading v-if="isLoading"></Loading>
    <form @submit="onPaymentSubmit" v-else>
      <div
        class="StripeContainer"
        :class="{ 'StripeContainer--in-progress': handlingPaymentSubmit }"
      >
        <!--Stripe.js injects the Payment Element here -->
        <slot name="stripePaymentElement"></slot>
      </div>
      <Button
        :disabled="handlingPaymentSubmit"
        :loading="handlingPaymentSubmit"
      >
        {{ t('payment_method_add_cta') }}
      </Button>
    </form>
  </div>
</template>

<style>
.NewPaymentMethod {
  padding: 24px;
}

.NewPaymentMethod__ErrorMessage {
  margin-bottom: 24px;
}

.StripeContainer {
  transition: opacity 0.125s;
  min-height: 100px;
  margin-bottom: 24px;
}

.StripeContainer--in-progress {
  opacity: 0.5;
}
</style>
