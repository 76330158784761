<script lang="ts" setup>
import { computed } from 'vue'
import IconClose from '../assets/icons/close.svg?component'
import IconLoading from '../assets/icons/loading-spinner.svg?component'

const props = defineProps<{
  mode?: string[]
  hasClose?: boolean
  hasShake?: boolean
  loading?: boolean
}>()

defineEmits<{
  (e: 'close'): void
}>()

const modeClasses = computed(() => (props.mode || []).map((m) => `Toast--${m}`))
</script>

<template>
  <Transition name="ToastAnimate" appear>
    <div class="Toast" :class="[modeClasses, hasShake ? 'Toast--shake' : '']">
      <template v-if="!loading">
        <div v-if="$slots.icon" class="Toast__Icon">
          <slot name="icon" />
        </div>
      </template>
      <template v-else>
        <div class="Toast__Icon Toast__Icon--loading">
          <IconLoading />
        </div>
      </template>
      <div class="Toast__Content">
        <slot />
      </div>
      <button
        class="Toast__Close CloseButton"
        v-if="hasClose"
        @click="$emit('close')"
      >
        <IconClose />
      </button>
    </div>
  </Transition>
</template>

<style>
.Toast {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999em;
  font-family: var(--font-family-rund);
  padding: 8px 13px;
  border: solid 1px transparent;
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 48px);
  z-index: 100;
  box-shadow: 0 10px 15px -9px rgba(var(--rgb-forest-100), 0.25);

  & a:hover {
    text-decoration: none;
  }

  @media (--mq-tablet-and-up) {
    padding: 11px 16px;
    width: auto;
    max-width: calc(100% - 48px);
  }
}

.Toast__Content {
  line-height: 1.5;
}

.Toast__Content > p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--font-size--small);
}

.Toast__Content > p + p {
  margin-top: 0.25rem;
}

.Toast__Icon {
  margin-right: 10px;
  @media (--mq-tablet-and-up) {
    margin-right: 15px;
  }
}

.Toast__Icon--loading {
  width: 25px;
  height: 24px;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.Toast__Close {
  border: 0;
  background-color: transparent;
  margin-left: 10px;
  width: 22.5px;
  height: 22.5px;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 12px;
    height: 12px;
    display: block;

    & path {
      stroke-width: 2px;
    }
  }
}

.Toast--shake {
  animation-name: shake-x;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
}

@keyframes shake-x {
  from,
  to {
    left: 50%;
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    left: calc(50% - 7px);
  }

  20%,
  40%,
  60%,
  80% {
    left: calc(50% + 7px);
  }
}

.ToastAnimate-leave-active,
.ToastAnimate-enter-active {
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ToastAnimate-enter-to,
.ToastAnimate-leave-from {
  opacity: 1;
  transform: translate(-50%, 0);
}

.ToastAnimate-enter-from,
.ToastAnimate-leave-to {
  opacity: 0;
  transform: translate(-50%, calc(100% + 25px));
}
</style>
