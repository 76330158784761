<script lang="ts" setup>
import IconChevron from '../assets/icons/chevron.svg?component'
import IconPencil from '../assets/icons/pencil.svg?component'
import IconSkip from '../assets/icons/skip.svg?component'
import ProductImage from './ProductImage.vue'
import Button from './Button.vue'
import { formatMoneyWithCurrency } from '@/lib/formatters/money'
import { formatDate } from '@/lib/formatters/date'

import { t } from '../lib/locale'

withDefaults(
  defineProps<{
    id: string
    title: string
    variantTitle?: string
    images?: {
      small: string
      medium: string
      large: string
      original: string
    }
    quantity?: number
    price?: string
    allowEdit?: boolean
    allowSkip?: boolean
    isDisabled?: boolean
    nextOrder?: string
  }>(),
  {
    allowEdit: true,
  }
)

const emit = defineEmits<{
  (e: 'edit', purchaseItemId: string): void
  (e: 'skip', purchaseItemId: string): void
}>()
</script>

<template>
  <div
    class="ProductListItem"
    :class="isDisabled ? 'ProductListItem--is-disabled' : ''"
  >
    <ProductImage
      :url="images?.medium"
      :alt="title"
      class="ProductListItem__Image"
    />

    <div class="ProductListItem__Copy">
      <p class="ProductListItem__Title">
        {{ title
        }}<span v-if="variantTitle" class="ProductListItem__VariantTitle"
          >&nbsp;– {{ variantTitle }}</span
        >
      </p>
      <p class="ProductListItem__Meta">
        <span v-if="quantity">
          {{ t('product_list_item_quantity_label') }}: {{ quantity }}
        </span>
        <span v-if="price === '0.00'">FREE</span>
        <span v-else-if="price">
          {{ formatMoneyWithCurrency(price) }}
          {{ t('product_list_item_each') }}
        </span>
      </p>
      <p class="ProductListItem__Meta" v-if="nextOrder">
        {{ t('product_list_item_next_order_label') }}
        {{ formatDate('DD Mon', nextOrder) }}
      </p>
    </div>

    <div class="ProductListItem__Icon" v-if="allowSkip">
      <div class="hide-on-mobile">
        <Button
          :modifiers="['link']"
          aria-label="Skip"
          @click="emit('skip', id)"
        >
          <template #iconBefore><IconSkip /></template>
          {{ t('product_list_item_skip_cta') }}
        </Button>
      </div>
    </div>

    <div class="ProductListItem__Icon" v-if="allowEdit">
      <div class="hide-on-desktop">
        <IconChevron class="ProductListItem__Icon__Chevron" />
      </div>
      <div class="hide-on-mobile">
        <Button
          :modifiers="['link']"
          aria-label="Edit product"
          @click="emit('edit', id)"
        >
          <template #iconBefore><IconPencil /></template>
          {{ t('product_list_item_edit_cta') }}
        </Button>
      </div>
    </div>
    <div
      v-if="allowEdit"
      @click="emit('edit', id)"
      class="ProductListItem__MobileClick"
      aria-hidden
    ></div>
  </div>
</template>

<style>
.ProductListItem {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: var(--color-white);
  color: var(--color-forest-100);
  position: relative;
}

.ProductListItem__Image {
  flex: 0 0 88px;
  width: 88px;
  height: 88px;
  object-fit: contain;
  object-position: center;
  margin-right: 16px;
  overflow: hidden;
  border-radius: var(--radius-small);
}

.ProductListItem__Copy {
  flex-grow: 1;

  & p {
    margin: 0;
  }
}

.ProductListItem__VariantTitle {
  color: var(--color-forest-60);
}

.ProductListItem__Meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--color-forest-60);
  @media (--mq-tablet-and-up) {
    justify-content: flex-start;

    & span {
      display: inline-block;
      margin-right: 16px;
    }
  }
}

.ProductListItem__Icon {
  color: var(--color-ocean-100);
  margin-left: 24px;
}

.ProductListItem__Icon__Chevron {
  transform: rotate(-90deg);
}

.ProductListItem__Icon--mobile {
  min-width: 16px;
}

.ProductListItem__Icon--desktop {
  min-width: 60px;
}

.ProductListItem__Title.GhostUI {
  margin-bottom: 5px;
}

.ProductListItem--is-disabled {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(100%);
}

.ProductListItem__MobileClick {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  @media (--mq-tablet-and-up) {
    display: none;
  }
}
</style>
