export function usePageOverlay(
  onClick: (e: Event) => void,
  onKeyup: (e: KeyboardEvent) => void
) {
  return {
    show() {
      document.body.classList.add('PageOverlay--is-visible')
      document.body.addEventListener('click', onClick)
      document.addEventListener('keyup', onKeyup)
    },
    hide() {
      document.body.classList.remove('PageOverlay--is-visible')
      document.body.removeEventListener('click', onClick)
      document.removeEventListener('keyup', onKeyup)
    },
  }
}
