<script lang="ts" setup>
import { ref, watch } from 'vue'
import IconChevron from '../assets/icons/chevron.svg?component'

const props = withDefaults(
  defineProps<{
    title?: string
    subtitle?: string
    isExpanded?: boolean
  }>(),
  {
    isExpanded: false,
  }
)

const emit = defineEmits<{
  (e: 'expand'): void
  (e: 'collapse'): void
}>()

const sectionIsExpanded = ref(props.isExpanded)
watch(sectionIsExpanded, (newValue) => {
  if (newValue) {
    emit('expand')
  } else {
    emit('collapse')
  }
})

const toggleExpandableSection = function () {
  if (sectionIsExpanded.value === false) {
    sectionIsExpanded.value = true
  } else {
    sectionIsExpanded.value = false
  }
}
</script>

<template>
  <div
    class="Card ExpandableSection"
    :class="{ 'ExpandableSection--is-expanded': sectionIsExpanded }"
  >
    <div class="ExpandableSection__Main" @click="toggleExpandableSection">
      <div class="ExpandableSection__Text">
        <h2 class="ExpandableSection__title h4" v-if="title">{{ title }}</h2>
        <p class="ExpandableSection__subtitle" v-if="subtitle">
          {{ subtitle }}
        </p>
      </div>
      <div class="ExpandableSection__Icon">
        <IconChevron />
      </div>
    </div>
    <transition name="ExpandableSection__Content">
      <div class="ExpandableSection__Content" v-show="sectionIsExpanded">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<style>
.ExpandableSection__Main {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ExpandableSection__Icon {
  margin-left: 24px;

  & svg {
    transition: transform 0.25s ease-in;
  }
}

.ExpandableSection--is-expanded .ExpandableSection__Icon svg {
  transform: rotate(-180deg);
}

.ExpandableSection__Text h2,
.ExpandableSection__Text p {
  margin: 0;
}

.ExpandableSection__Content {
  overflow: hidden;
}

.ExpandableSection__Content-enter-from,
.ExpandableSection__Content-leave-to {
  opacity: 0;
}

.ExpandableSection__Content-enter-to,
.ExpandableSection__Content-leave-from {
  opacity: 1;
}

.ExpandableSection__Content-enter-active,
.ExpandableSection__Content-leave-active {
  transition: opacity 0.25s linear;
  overflow: hidden;
}
</style>
