import {
  cancelSubscription,
  getSubscriptions,
  addSubscriptionToNextOrder,
  skipSubscription,
  updateSubscription,
  reactivateSubscription,
  addNewSubscriptionToNextOrder,
} from '@/services/subscription'
import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { useProductImageStore } from './productImage'
import { useTaskStore } from './task'
import { useNextOrderStore } from './nextOrder'
import { useFutureOrderStore } from './futureOrder'
import { useProductLinkStore } from './productLink'
import { formatProductTitle } from '@/lib/formatters/product-title'

import { t } from '@/lib/locale'
import Bugsnag from '@bugsnag/browser'

export interface Subscription {
  id: string
  address_id: string
  customer_id: string
  cancellation_reason?: string
  cancellation_reason_comments?: string
  cancelled_at?: string
  charge_interval_frequency: number
  created_at: string
  expire_after_specific_number_of_charges?: number
  external_product_id: {
    ecommerce: string
  }
  external_variant_id: {
    ecommerce: string
  }
  has_queued_charges: boolean
  is_prepaid: boolean
  is_skippable: boolean
  is_swappable: boolean
  max_retries_reached: boolean
  next_charge_scheduled_at?: string
  order_day_of_month?: number
  order_day_of_week?: number
  order_interval_frequency: number
  order_interval_unit: 'month' | 'week' | 'day'
  price: string
  product_title: string
  properties: []
  quantity: number
  sku?: string
  sku_override: boolean
  status: 'active' | 'cancelled' | 'expired'
  updated_at: string
  variant_title?: string
}

export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    workState: AsyncOperationState.IDLE,
    subscriptions: [] as Subscription[],
    stale: true as boolean,
  }),
  getters: {
    activeSubscriptions(state) {
      return state.subscriptions
        .filter((sub) => sub.status === 'active')
        .sort((sA, sB) => {
          if (!sA.next_charge_scheduled_at) return -1
          if (!sB.next_charge_scheduled_at) return 1
          return sA.next_charge_scheduled_at > sB.next_charge_scheduled_at
            ? 1
            : -1
        })
    },
    cancelledSubscriptions(state) {
      return state.subscriptions.reduce((subscriptions, sub) => {
        if (sub.status === 'cancelled') {
          const searchFn = (subscription: Subscription) =>
            subscription.external_product_id.ecommerce ===
            sub.external_product_id.ecommerce
          // dedupe cancelled subs based on product_id
          // TODO: maybe we should do it via variant id?
          if (
            !subscriptions.find(searchFn) &&
            !this.activeSubscriptions.find(searchFn)
          ) {
            subscriptions.push(sub)
          }
        }
        return subscriptions
      }, [] as Subscription[])
    },
  },
  actions: {
    async init(rechargeId: string) {
      if (this.stale || this.loadState === AsyncOperationState.IDLE) {
        await this.load(rechargeId)
      }
    },
    async load(rechargeId: string) {
      const productImageStore = useProductImageStore()
      const productLinkStore = useProductLinkStore()
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const subscriptions = await getSubscriptions(rechargeId)
        // remove all the 10.00% off auto renew stuff
        subscriptions.forEach(formatTitle)
        this.subscriptions = subscriptions
        productImageStore.loadImagesForProducts(
          this.subscriptions.map(
            (sub) =>
              `${sub.external_product_id.ecommerce}:${sub.external_variant_id.ecommerce}`
          )
        )
        productLinkStore.loadLinksForProducts(
          this.subscriptions.map(
            (sub) => `${sub.external_product_id.ecommerce}`
          )
        )
        this.stale = false
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
    async patchSubscription(subscription: Subscription) {
      const productImageStore = useProductImageStore()
      const productLinkStore = useProductLinkStore()
      this.$patch(() => {
        const subscriptionIndex = this.subscriptions.findIndex(
          (s) => s.id === subscription.id
        )
        formatTitle(subscription)
        this.subscriptions[subscriptionIndex] = subscription
      })
      productImageStore.loadImagesForProducts([
        `${subscription.external_product_id.ecommerce}:${subscription.external_variant_id.ecommerce}`,
      ])
      productLinkStore.loadLinksForProducts([
        `${subscription.external_product_id.ecommerce}`,
      ])
    },
    async updateSubscription(
      rechargeId: string,
      subscriptionId: string,
      update: {
        quantity: number
        frequency: number
      }
    ) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const nextOrderStore = useNextOrderStore()
      const futureOrderStore = useFutureOrderStore()
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_update_toast_progress'),
        this.workState
      )
      try {
        const { subscription } = await updateSubscription(
          rechargeId,
          subscriptionId,
          update
        )
        this.patchSubscription(subscription)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_update_toast_success'),
          this.workState
        )
        nextOrderStore.stale = true
        futureOrderStore.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('purchase_item_update_toast_error'),
          this.workState
        )
      }
    },
    async cancelSubscription(
      rechargeId: string,
      subscriptionId: string,
      reason: string,
      comments?: string
    ) {
      this.workState = AsyncOperationState.IN_PROGRESS
      try {
        const { subscription } = await cancelSubscription(
          rechargeId,
          subscriptionId,
          reason,
          comments
        )
        this.patchSubscription(subscription)
        this.workState = AsyncOperationState.DONE
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        nextOrderStore.stale = true
        futureOrderStore.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
      }
    },
    async addSubscriptionToNextOrder(
      rechargeId: string,
      subscriptionId: string
    ) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_add_to_next_order_toast_progress'),
        this.workState
      )
      try {
        const { subscription } = await addSubscriptionToNextOrder(
          rechargeId,
          subscriptionId
        )
        this.patchSubscription(subscription)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_add_to_next_order_toast_success'),
          this.workState
        )
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        nextOrderStore.stale = true
        futureOrderStore.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('purchase_item_add_to_next_order_toast_error'),
          this.workState
        )
      }
    },
    async addNewSubscriptionToNextOrder(
      rechargeId: string,
      productId: string,
      variantId: string,
      quantity: number,
      planId: string
    ) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_add_new_subscription_to_next_order_toast_progress'),
        this.workState
      )
      try {
        await addNewSubscriptionToNextOrder(
          rechargeId,
          productId,
          variantId,
          quantity,
          planId
        )
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_add_new_subscription_to_next_order_toast_success'),
          this.workState
        )
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        nextOrderStore.stale = true
        futureOrderStore.stale = true
        this.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('purchase_item_add_new_subscription_to_next_order_toast_error'),
          this.workState
        )
      }
    },
    async skipSubscription(rechargeId: string, subscriptionId: string) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_skip_toast_progress'),
        this.workState
      )
      try {
        const { subscription } = await skipSubscription(
          rechargeId,
          subscriptionId
        )
        this.patchSubscription(subscription)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_skip_toast_success'),
          this.workState
        )
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        nextOrderStore.stale = true
        futureOrderStore.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('purchase_item_skip_toast_error'),
          this.workState
        )
      }
    },
    async reactivateSubscription(rechargeId: string, subscriptionId: string) {
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskStore = useTaskStore()
      const taskId = taskStore.setTask(
        t('purchase_item_reactivate_toast_progress'),
        this.workState
      )
      try {
        const { subscription } = await reactivateSubscription(
          rechargeId,
          subscriptionId
        )
        this.patchSubscription(subscription)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('purchase_item_reactivate_toast_success'),
          this.workState
        )
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        nextOrderStore.stale = true
        futureOrderStore.stale = true
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('purchase_item_reactivate_toast_error'),
          this.workState
        )
      }
    },
  },
})

const formatTitle = (subscription: Subscription) => {
  subscription.product_title = formatProductTitle(subscription.product_title)
}
