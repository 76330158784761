<script lang="ts" setup>
import { usePageOverlay } from '@/lib/pageOverlay'
import { onBeforeMount, onBeforeUnmount } from 'vue'
import IconClose from '../assets/icons/close.svg?component'

withDefaults(
  defineProps<{
    title?: string
    headingLevel?: string
  }>(),
  {
    headingLevel: 'h5',
  }
)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { show, hide } = usePageOverlay(
  (e) => {
    if (document.querySelector('.PageOverlay') === e.target) {
      emit('close')
    }
  },
  (e) => {
    if (e.key === 'Escape') {
      emit('close')
    }
  }
)

onBeforeMount(show)
onBeforeUnmount(hide)
</script>

<template>
  <transition name="Modal" appear>
    <div class="Modal">
      <div class="Modal__Outer">
        <div class="Modal__Title" v-if="title">
          <h2 :class="headingLevel">{{ title }}</h2>
        </div>
        <div class="Modal__Content">
          <slot></slot>
        </div>
        <button class="Modal__Close ButtonClose" @click="$emit('close')">
          <IconClose />
        </button>
      </div>
    </div>
  </transition>
</template>

<style>
.Modal {
  position: absolute;
  z-index: 100;
  overscroll-behavior: none;
}

.Modal__Outer {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 21;
  transform: translate(-50%, 0);
  width: 90%;
  height: auto;
  max-height: 90%;
  overflow-y: auto;
  overscroll-behavior-y: none;
  background-color: var(--color-white);
  border-radius: var(--radius-large) var(--radius-large) 0 0;
  box-shadow: 0 0 20px 0 var(--color-shadow-30);
  padding: 24px;
  transition: opacity 0.2s ease-in;
  overscroll-behavior: none;
}

.Modal__Close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 24px;
  right: 24px;
  background-color: var(--color-ocean-100);
  color: var(--color-white);
  border: 0;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  transition: background-color var(--global-transition);
  z-index: 1;

  & svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-color: var(--color-forest-100);
    cursor: pointer;
  }
}

.Modal__Title {
  padding: 0 72px 0 0;

  & h2 {
    margin-bottom: 8px;
  }
}

.Modal__Panel {
  background-color: var(--color-lichen-100);
  margin: var(--grid-gap) calc(0px - var(--grid-gap))
    calc(0px - var(--grid-gap));
  padding: var(--grid-gap);
}

.Modal-enter-active,
.Modal-leave-active {
  transition: opacity 0.2s ease-in;

  & .Modal__Outer {
    transition: transform 0.2s ease-in;
  }
}

.Modal-enter-from,
.Modal-leave-to {
  opacity: 0;
  visibility: hidden;

  & .Modal__Outer {
    transform: translate(-50%, 110%);
  }
}

.Modal-enter-to,
.Modal-leave-from {
  opacity: 1;
  visibility: visible;

  & .Modal__Outer {
    transform: translate(-50%, 0);
  }
}

@media (--mq-landscape-tablet-and-up) {
  .Modal__Outer {
    max-width: 512px;
    border-radius: var(--radius-large);
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Modal.Modal--wide .Modal__Outer {
    max-width: 768px;
  }

  .Modal-enter-active,
  .Modal-leave-active {
    & .Modal__Outer {
      transition: none;
    }
  }

  .Modal-enter-from,
  .Modal-leave-to {
    & .Modal__Outer {
      transform: translate(-50%, -50%);
    }
  }

  .Modal-enter-to,
  .Modal-leave-from {
    & .Modal__Outer {
      transform: translate(-50%, -50%);
    }
  }
}
</style>
