import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { getLastOrder } from '../services/order'
import Bugsnag from '@bugsnag/js'

const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 7

interface LastOrder {
  id: string
  createdAt: string
  status: string
  displayStatus: string
  fulfillments: {
    trackingInfo: {
      company: string
      number: string
      url: string
    }[]
  }[]
}

export const useLastOrderStore = defineStore('lastOrder', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    order: null as LastOrder | null,
  }),
  getters: {
    isRecent: (state) => {
      if (!state.order) return false
      const orderDate = new Date(state.order.createdAt)
      const today = new Date()
      return today.getTime() - orderDate.getTime() < ONE_WEEK_MS
      // return true
    },
    summary: (state) => {
      if (!state.order) {
        return null
      } else if (
        !state.order.fulfillments[0] ||
        !state.order.fulfillments[0].trackingInfo[0]
      ) {
        return {
          id: state.order.id,
          date: state.order.createdAt,
          trackingInfo: null,
        }
      } else {
        return {
          id: state.order.id,
          date: state.order.createdAt,
          trackingInfo: state.order.fulfillments[0].trackingInfo[0],
        }
      }
    },
  },
  actions: {
    async init(shopifyId?: string) {
      if (this.loadState === AsyncOperationState.IDLE && shopifyId) {
        await this.load(shopifyId)
      }
    },
    async load(shopifyId: string) {
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        this.order = await getLastOrder(shopifyId)
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
  },
})
