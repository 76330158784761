import {
  addSubscriptionToNextOrder,
  swapSubscription,
  delaySubscription,
} from '@/services/subscription'
import { changeDate } from '@/services/order'

import { useSubscriptionStore } from '@/stores/subscription'
import { useNextOrderStore } from '@/stores/nextOrder'
import { useFutureOrderStore } from '@/stores/futureOrder'
import { useOnetimeStore } from '@/stores/onetime'

import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'

import Bugsnag from '@bugsnag/js'
import {
  getFragranceGroupProducts,
  getProductFragranceGroup,
} from '@/services/storefront'

export interface AlternativeProduct {
  id: string
  title: string
  fragrance_name: { value: string } | null
  images: {
    altText: string | null
    small: string
    medium: string
    large: string
    original: string
  }
  variants: {
    id: string
    price: {
      amount: string
    }
  }[]
}

export const useCancellationInterventionStore = defineStore(
  'cancellationIntervention',
  {
    state: () => ({
      workState: AsyncOperationState.IDLE,
      loadState: AsyncOperationState.IDLE,
      alternativeProducts: [] as AlternativeProduct[],
    }),
    getters: {},
    actions: {
      async addToNextOrder(rechargeId: string, subscriptionId: string) {
        this.workState = AsyncOperationState.IN_PROGRESS
        try {
          const subscriptionStore = useSubscriptionStore()
          const { subscription } = await addSubscriptionToNextOrder(
            rechargeId,
            subscriptionId
          )
          subscriptionStore.patchSubscription(subscription)
          this.workState = AsyncOperationState.DONE
          const nextOrderStore = useNextOrderStore()
          const futureOrderStore = useFutureOrderStore()
          nextOrderStore.stale = true
          futureOrderStore.stale = true
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
        }
      },
      async moveNextOrder(rechargeId: string, from: string, to: string) {
        this.workState = AsyncOperationState.IN_PROGRESS
        try {
          await changeDate(rechargeId, from, to)
          this.workState = AsyncOperationState.DONE
          const subscriptionStore = useSubscriptionStore()
          const futureOrderStore = useFutureOrderStore()
          const nextOrderStore = useNextOrderStore()
          const onetimeStore = useOnetimeStore()

          subscriptionStore.stale = true
          onetimeStore.stale = true
          futureOrderStore.stale = true
          nextOrderStore.stale = true

          subscriptionStore.init(rechargeId)
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
        }
      },
      async delay(rechargeId: string, subscriptionId: string, until: string) {
        const subscriptionStore = useSubscriptionStore()
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        this.workState = AsyncOperationState.IN_PROGRESS
        try {
          const { subscription } = await delaySubscription(
            rechargeId,
            subscriptionId,
            until
          )
          subscriptionStore.patchSubscription(subscription)
          this.workState = AsyncOperationState.DONE
          nextOrderStore.stale = true
          futureOrderStore.stale = true
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
        }
      },
      async swap(
        rechargeId: string,
        subscriptionId: string,
        variantId: string
      ) {
        const subscriptionStore = useSubscriptionStore()
        const nextOrderStore = useNextOrderStore()
        const futureOrderStore = useFutureOrderStore()
        this.workState = AsyncOperationState.IN_PROGRESS
        try {
          const { subscription } = await swapSubscription(
            rechargeId,
            subscriptionId,
            variantId
          )
          subscriptionStore.patchSubscription(subscription)
          this.workState = AsyncOperationState.DONE
          nextOrderStore.stale = true
          futureOrderStore.stale = true
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.workState = AsyncOperationState.ERRORED
        }
      },
      async loadAlternativeProducts(subscriptionId: string) {
        this.loadState = AsyncOperationState.IN_PROGRESS
        try {
          const subscriptionStore = useSubscriptionStore()
          const sub = subscriptionStore.subscriptions.find(
            (sub) => sub.id === subscriptionId
          )
          if (sub) {
            const res = await getProductFragranceGroup(
              sub.external_product_id.ecommerce
            )
            if (res && res.fragrance_group_id && res.fragrance_group_id.value) {
              const alternativeProducts = await getFragranceGroupProducts(
                res.fragrance_group_id.value
              )
              this.alternativeProducts = alternativeProducts
            }
          }
          this.loadState = AsyncOperationState.DONE
        } catch (e) {
          Bugsnag.notify(e as Error)
          this.loadState = AsyncOperationState.ERRORED
        }
      },
      reset() {
        this.loadState = AsyncOperationState.IDLE
        this.alternativeProducts = []
      },
    },
  }
)
