<script lang="ts" setup>
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import type { Subscription } from '@/stores/subscription'
import type { ImageMap } from '@/stores/productImage'
import ProductListItem from '@/components/ProductListItem.vue'
import ProductListItemLoading from './ProductListItemLoading.vue'
import ExpandableSection from '@/components/ExpandableSection.vue'
import ErrorMessage from '../components/ErrorMessage.vue'

import { t } from '@/lib/locale'

const props = defineProps<{
  loadState: AsyncOperationState
  workState?: AsyncOperationState
  subscriptions: Subscription[]
  isExpanded: boolean
  subscriptionType: 'active' | 'cancelled'
  title: string
  imagesByProductId: Record<string, ImageMap[] | undefined>
  nextChargeDate?: string
}>()

const getImagesForProduct = (id: string) => {
  const imageList = props.imagesByProductId[id]
  if (imageList) {
    return imageList[0]
  } else {
    return undefined
  }
}

const emit = defineEmits<{
  (e: 'edit-item', id: string): void
}>()
</script>

<template>
  <ExpandableSection :title="title" :is-expanded="isExpanded">
    <div
      class="Subscriptions__Loading"
      v-if="
        [AsyncOperationState.IN_PROGRESS, AsyncOperationState.IDLE].includes(
          loadState
        )
      "
    >
      <ProductListItemLoading />
      <ProductListItemLoading />
      <ProductListItemLoading />
    </div>

    <ErrorMessage v-else-if="[AsyncOperationState.ERRORED].includes(loadState)">
      <template #title>{{ t('your_subscriptions_error_title') }}</template>
      <p>{{ t('your_subscriptions_error_copy') }}</p>
    </ErrorMessage>

    <ErrorMessage v-else-if="subscriptions.length === 0">
      <template #title>{{ t('your_subscriptions_empty_title') }}</template>
      {{ t(`your_subscriptions_empty_copy_${subscriptionType}`) }}
    </ErrorMessage>

    <ul v-else class="ProductList">
      <li
        v-for="subscription in subscriptions"
        :key="subscription.product_title"
      >
        <ProductListItem
          :id="subscription.id.toString()"
          :title="subscription.product_title"
          :images="
            getImagesForProduct(
              `${subscription.external_product_id.ecommerce}:${subscription.external_variant_id.ecommerce}`
            )
          "
          :price="subscription.price"
          :quantity="subscription.quantity"
          :next-order="subscription.next_charge_scheduled_at"
          :is-disabled="workState === AsyncOperationState.IN_PROGRESS"
          @edit="emit('edit-item', $event)"
        />
      </li>
    </ul>
  </ExpandableSection>
</template>
