<script lang="ts" setup>
import AppHeader from './AppHeader.vue'
import AppFooter from './AppFooter.vue'
import Button from '@/components/Button.vue'
import IconArrow from '@/components/icons/IconArrow.vue'

const props = defineProps<{
  title?: string
  description?: string
  backLink?: {
    text: string
  }
}>()

const emit = defineEmits<{
  (e: 'back'): void
}>()

const titleClass = props.backLink ? 'h4' : 'h2'
</script>

<template>
  <div class="PageTransition">
    <div class="PageOverlay" aria-hidden></div>
    <AppHeader />
    <div class="Layout PageTransition__Target">
      <div class="Layout__BackLink" v-if="props.backLink">
        <Button :modifiers="['link']" @click="emit('back')">
          <template #iconBefore>
            <IconArrow :flip-y="true"></IconArrow>
          </template>
          {{ props.backLink.text }}
        </Button>
      </div>
      <div class="Layout__Title" v-if="title">
        <h1 :class="titleClass">{{ title }}</h1>
        <p v-if="description">{{ description }}</p>
      </div>
      <slot name="banner"></slot>
      <div class="Stack">
        <div
          class="Layout__Container"
          :class="{ 'Layout__Container--without-title': !title }"
        >
          <main class="Layout__ContentMain">
            <slot name="main"></slot>
          </main>
          <aside class="Layout__ContentSecondary">
            <slot name="aside"></slot>
          </aside>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<style>
.Layout {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1144px;
  overflow-x: hidden;
}

.Layout__ContentMain,
.Layout__ContentSecondary {
  margin-bottom: var(--stack-default);
}

.Layout__Title,
.Layout__BackLink {
  padding: 0 24px;
}

.Layout__BackLink + .Layout__Title {
  margin-top: 40px;
}

.PageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  background: var(--color-shadow);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

body.PageOverlay--is-visible {
  overflow: hidden;

  & .PageOverlay {
    opacity: 0.5;
    visibility: visible;
  }
}

@media (--mq-tablet-and-up) {
  .Layout {
    padding: 0 24px;
    max-width: calc(1144px + 24px + 24px);
  }

  .Layout__Title,
  .Layout__BackLink {
    padding: 0;
  }
}

@media (--mq-landscape-tablet-and-up) {
  .Layout__Container {
    display: grid;
    grid-template-columns: 66.5% 1fr;
    gap: 64px;
    margin-bottom: 96px;
  }

  .Layout__Content__Main,
  .Layout__Content__Secondary {
    margin-bottom: 96px;
    grid-row: 1;
  }

  .Layout__Content__Main {
    grid-column: 1;
  }

  .Layout__Content__Secondary {
    grid-column: 2;
  }
}

.Layout__Container--without-title {
  margin-top: 24px;
}
</style>
