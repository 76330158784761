import { defineStore } from 'pinia'
import { getImagesForProduct } from '@/services/storefront'

export interface ImageMap {
  small: string
  medium: string
  large: string
  original: string
}

export const useProductImageStore = defineStore('productImages', {
  state: () => ({
    productImages: {} as Record<string, ImageMap[] | undefined>,
  }),
  actions: {
    async getImagesForProduct(id: string) {
      if (!Object.hasOwnProperty.call(this.productImages, id)) {
        const [productId, variantId] = id.split(':')
        this.productImages[id] = await getImagesForProduct(productId, variantId)
      }
    },
    async loadImagesForProducts(ids: string[]) {
      const uniqueIdsWithoutImages = ids.reduce((ids, id) => {
        if (!Object.hasOwnProperty.call(this.productImages, id)) {
          ids.add(id)
        }
        return ids
      }, new Set<string>())
      uniqueIdsWithoutImages.forEach(async (id) => {
        this.getImagesForProduct(id)
      })
    },
  },
})
