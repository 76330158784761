<script setup lang="ts">
import { useSubscriptionStore } from '@/stores/subscription'
import { useProductImageStore } from '@/stores/productImage'
import type { AuthenticatedEntity } from '@/stores/auth'

import Layout from '@/components/layout/Layout.vue'
import PurchaseItem from '@/components/PurchaseItem.vue'
import { usePurchaseItemState } from '@/views/lib/PurchaseItem'
import { useNextOrderStore } from '@/stores/nextOrder'
import { useCancellationInterventionStore } from '@/stores/cancellationIntervention'

import { t } from '@/lib/locale'
import mixpanel from 'mixpanel-browser'

import { useBackLink } from './lib/backLink'

const { backTo } = useBackLink()

const props = defineProps<{
  id: string
  authedUser: AuthenticatedEntity
}>()

const subscriptionStore = useSubscriptionStore()
const productImageStore = useProductImageStore()
const nextOrderStore = useNextOrderStore()
const cancellationInterventionStore = useCancellationInterventionStore()

const {
  currentItem,
  itemInNextOrder,
  update,
  cancel,
  addToNextOrder,
  reactivate,
  skip,
  link,
  cancelInterventionSwap,
  cancelInterventionAddToNextOrder,
  cancelInterventionDelay,
} = usePurchaseItemState(props.id, props.authedUser, 'subscription_item')
</script>

<template>
  <Layout
    :back-link="{ text: t('your_subscriptions_back_cta') }"
    @back="backTo('/subscriptions')"
  >
    <template #main>
      <PurchaseItem
        :item="currentItem"
        :link="link"
        :temp-item="
          itemInNextOrder
            ? {
                title: itemInNextOrder?.title,
                productId: itemInNextOrder?.external_product_id.ecommerce,
                variantId: itemInNextOrder?.external_variant_id.ecommerce,
              }
            : undefined
        "
        :product-images="productImageStore.productImages"
        :in-next-order="!!itemInNextOrder"
        :next-order-date="nextOrderStore.order?.scheduled_at"
        :work-state="subscriptionStore.workState"
        :cancellation-intervention-store="cancellationInterventionStore"
        :track="(event: string, data?: Record<string, unknown>) => mixpanel.track(event, data)"
        @save="update"
        @cancel="cancel"
        @add-to-next-order="addToNextOrder"
        @reactivate="reactivate"
        @skip="skip"
        @cancel-intervention-swap="cancelInterventionSwap"
        @cancel-intervention-add-to-next-order="
          cancelInterventionAddToNextOrder
        "
        @cancel-intervention-delay="cancelInterventionDelay"
      ></PurchaseItem>
    </template>
  </Layout>
</template>
