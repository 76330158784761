<script setup lang="ts">
import { useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import mixpanel from 'mixpanel-browser'

import Layout from '@/components/layout/Layout.vue'
import NextOrder from '@/components/NextOrder.vue'
import StatsCard from '@/components/StatsCard.vue'
import SimpleCta from '@/components/SimpleCta.vue'
import DispatchBanner from '@/components/DispatchBanner.vue'
import ChargeErrorBanner from '@/components/ChargeErrorBanner.vue'
import ExpandableSection from '@/components/ExpandableSection.vue'
import HorizontalProductList from '@/components/HorizontalProductList.vue'
import UpsellModal from '@/components/UpsellModal.vue'

import type { AuthenticatedEntity } from '@/stores/auth'
import { useNextOrderStore } from '@/stores/nextOrder'
import { useSubscriptionCustomerStore } from '@/stores/subscriptionCustomer'
import { useProductImageStore } from '@/stores/productImage'
import { useLastOrderStore } from '@/stores/lastOrder'
import { useWasteMetricsStore } from '@/stores/wasteMetrics'
import { useSubscriptionStore } from '@/stores/subscription'
import {
  RecommendationItem,
  useRecommendationStore,
} from '@/stores/recommendation'
import { useOnetimeStore } from '@/stores/onetime'

import { getProduct, Product } from '@/services/storefront'

import { formatDate } from '@/lib/formatters/date'
import { countup } from '@/lib/countup'

import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'

import { t } from '../lib/locale'
import { usePurchaseItemState } from './lib/PurchaseItem'
import Bugsnag from '@bugsnag/browser'
import NonSubscriberCta from '../components/NonSubscriberCta.vue'
import { returnEnvelopeId, returnEnvelopeVariantId } from '@/lib/envelope'

const N_UK_HOUSEHOLDS = 28100000

const nextOrderStore = useNextOrderStore()
const lastOrderStore = useLastOrderStore()
const subscriptionCustomerStore = useSubscriptionCustomerStore()
const productImageStore = useProductImageStore()
const subscriptionStore = useSubscriptionStore()
const wasteMetricsStore = useWasteMetricsStore()
const recommendationStore = useRecommendationStore()
const onetimeStore = useOnetimeStore()

const router = useRouter()

const props = defineProps<{ authedUser: AuthenticatedEntity }>()

if (props.authedUser.rechargeId) {
  const nextOrderInitP = nextOrderStore.init(props.authedUser.rechargeId)
  const subscriptionInitP = subscriptionStore.init(props.authedUser.rechargeId)

  onetimeStore.init(props.authedUser.rechargeId)
  subscriptionCustomerStore.init(props.authedUser.rechargeId)

  Promise.allSettled([nextOrderInitP, subscriptionInitP]).then(() => {
    const productIds = [] as string[]
    const variantIds = [] as string[]
    if (
      nextOrderStore.order &&
      subscriptionStore.loadState === AsyncOperationState.DONE
    ) {
      nextOrderStore.order.line_items.forEach((li) => {
        productIds.push(li.external_product_id.ecommerce.toString())
        variantIds.push(li.external_variant_id.ecommerce.toString())
      })
      subscriptionStore.subscriptions.forEach((s) => {
        productIds.push(s.external_product_id.ecommerce.toString())
        variantIds.push(s.external_variant_id.ecommerce.toString())
      })
    }
    recommendationStore.init(props.authedUser.shopifyId, 'new', {
      product_ids: productIds.join(','),
      variant_ids: variantIds.join(','),
    })
  })

  nextOrderInitP.then(() => {
    if (nextOrderStore.order) {
      recommendationStore.init(props.authedUser.shopifyId, 'buy-again', {
        product_ids: nextOrderStore.order.line_items
          .map((li) => li.external_product_id.ecommerce.toString())
          .join(','),
        variant_ids: nextOrderStore.order.line_items
          .map((li) => li.external_variant_id.ecommerce.toString())
          .join(','),
      })
    }
  })
} else {
  recommendationStore.init(props.authedUser.shopifyId, 'new')
}

lastOrderStore.init(props.authedUser.shopifyId)
wasteMetricsStore.initCustomerWasteMetrics(props.authedUser.shopifyId)

const edit = (id: string) => {
  router.push(`/next-order/item/${id}`)
}

const skip = (id: string) => {
  const { skip } = usePurchaseItemState(id, props.authedUser, 'next_order')
  skip()
}

const changeDate = async ({ from, to }: { from: string; to: string }) => {
  nextOrderStore.changeDate(props.authedUser.rechargeId, from, to)
  mixpanel.track('change_order_date', {
    from_date: from,
    to_date: to,
    n_days_changed:
      (new Date(to).getTime() - new Date(from).getTime()) / 1000 / 60 / 60 / 24,
  })
}

const applyDiscount = async (discountCode: string) => {
  nextOrderStore.applyDiscount(props.authedUser.rechargeId, discountCode)
  mixpanel.track('apply_discount', { discount_code: discountCode })
}

const { ref: nextOrderPlasticWasteSavedCount, start: startNextOrderCountup } =
  countup(0)
watch(
  () => wasteMetricsStore.nextOrderWasteMetrics,
  (newValue) => {
    if (newValue && newValue.weight) {
      startNextOrderCountup(newValue.weight, 1500)
    }
  },
  { immediate: true }
)

watch(
  () => nextOrderStore.order,
  (order) => {
    if (order) {
      mixpanel.track('next_order_view', {
        scheduled_at: order.scheduled_at,
        items_in_order: order.line_items.length,
      })

      wasteMetricsStore.initNextOrderWasteMetrics(
        order.line_items.map((li) => ({
          quantity: li.quantity,
          variantId: li.external_variant_id.ecommerce,
        }))
      )
    }
  },
  { immediate: true }
)

const { ref: customerPlasticWasteSavedCount, start: startWasteMetricsCountUp } =
  countup(0)

watch(
  () => wasteMetricsStore.customerWasteMetrics,
  (newValue) => {
    if (newValue && newValue.weight) {
      startWasteMetricsCountUp(newValue.weight / 1000, 1500)
    }
  },
  { immediate: true }
)

const addToNextOrder = async (
  productId: string,
  variantId: string,
  context?: string,
  quantity?: number
) => {
  if (props.authedUser.rechargeId && nextOrderStore.order) {
    await subscriptionStore.init(props.authedUser.rechargeId)
    const activeSubscriptionToProduct = subscriptionStore.subscriptions.find(
      (sub) =>
        `${sub.external_variant_id.ecommerce}` === `${variantId}` &&
        sub.status === 'active'
    )
    if (activeSubscriptionToProduct) {
      // move subscription to next order
      await subscriptionStore.addSubscriptionToNextOrder(
        props.authedUser.rechargeId,
        `${activeSubscriptionToProduct.id}`
      )
      mixpanel.track('add_to_order', {
        product_id: productId,
        variant_id: variantId,
        quantity: activeSubscriptionToProduct.quantity,
        context: context || 'next_order_upsell',
        is_new: false,
      })
      nextOrderStore.init(props.authedUser.rechargeId)
    } else {
      await onetimeStore.addToNextOrder(
        props.authedUser.rechargeId,
        nextOrderStore.order.address_id,
        productId,
        variantId,
        nextOrderStore.order.scheduled_at,
        quantity
      )
      mixpanel.track('add_to_order', {
        product_id: productId,
        variant_id: variantId,
        quantity: 1,
        context: context || 'next_order_upsell',
        is_new: true,
      })
      nextOrderStore.init(props.authedUser.rechargeId)
    }
  } else {
    // TODO what to do if the customer doesn't have a next order
    Bugsnag.notify(
      new Error('addToNextOrder was called but there is no next order')
    )
  }
}

const updateEnvelopeQuantity = async (quantity: number) => {
  const envelopeInOrder = nextOrderStore.order?.line_items.find(
    (item) =>
      item.external_product_id.ecommerce.toString() ===
      returnEnvelopeId.toString()
  )
  if (envelopeInOrder) {
    const { updateOnetime, cancel } = usePurchaseItemState(
      envelopeInOrder.purchase_item_id,
      props.authedUser,
      'next_order'
    )
    if (quantity > 0) {
      await updateOnetime({ quantity })
    } else {
      await cancel('onetime')
    }
  } else {
    await addToNextOrder(
      returnEnvelopeId.toString(),
      returnEnvelopeVariantId.toString(),
      'next_order',
      quantity
    )
  }
}

const navigateToProduct = (handle: string) => {
  window.location.href = `https://bowercollective.com/products/${handle}`
}

const onExpandUpsell = () => {
  localStorage.removeItem('bower_new_for_you_closed')
}

const onCollapseUpsell = () => {
  localStorage.setItem('bower_new_for_you_closed', 'YES')
}

const isUpsellExpanded = !localStorage.getItem('bower_new_for_you_closed')

const showingUpsellForProduct = ref<RecommendationItem | null>(null)
const upsellContext = ref<string | null>(null)
const upsellProduct = ref<Product | null>(null)
const productLoadState = ref<AsyncOperationState>(AsyncOperationState.IDLE)
watch(showingUpsellForProduct, async (product) => {
  upsellProduct.value = null
  if (product) {
    productLoadState.value = AsyncOperationState.IN_PROGRESS
    try {
      upsellProduct.value = await getProduct(product.id)
      productLoadState.value = AsyncOperationState.DONE
    } catch (e) {
      productLoadState.value = AsyncOperationState.ERRORED
    }
  } else {
    productLoadState.value = AsyncOperationState.IDLE
  }
})

const newRecommendationItems = computed(() =>
  recommendationStore.new.items
    .slice(0)
    .sort(() => Math.random() - 0.5)
    .slice(0, 5)
    .map((p) => ({
      title: p.title,
      imageUrl:
        p.images.find((i) => i.id === p.variants[0].image_id)?.src ||
        p.image.src,
      url: `https://bowercollective.com/products/${p.handle}`,
      action: nextOrderStore.order
        ? {
            text: t('upsell_add_cta'),
            callback: () => {
              showingUpsellForProduct.value = p
              upsellContext.value = 'your_bower_upsell'
            },
          }
        : {
            text: t('upsell_view_cta'),
            callback: () => navigateToProduct(p.handle),
          },
    }))
)

const addUpsellToNextOrder = async (
  productId: string,
  variantId: string,
  quantity: number,
  planId: string | null
) => {
  if (props.authedUser.rechargeId && nextOrderStore.order) {
    await subscriptionStore.init(props.authedUser.rechargeId)
    if (planId !== null) {
      mixpanel.track('add_to_order', {
        product_id: productId,
        variant_id: variantId,
        quantity: quantity,
        context: upsellContext.value,
        is_new: true,
        is_subscription: true,
      })
      await subscriptionStore.addNewSubscriptionToNextOrder(
        props.authedUser.rechargeId,
        productId,
        variantId,
        quantity,
        planId
      )
    } else {
      mixpanel.track('add_to_order', {
        product_id: productId,
        variant_id: variantId,
        quantity,
        context: upsellContext.value,
        is_new: true,
        is_subscription: false,
      })
      await onetimeStore.addToNextOrder(
        props.authedUser.rechargeId,
        nextOrderStore.order.address_id,
        productId,
        variantId,
        nextOrderStore.order.scheduled_at,
        quantity
      )
    }
    nextOrderStore.init(props.authedUser.rechargeId)
    showingUpsellForProduct.value = null
  }
}
</script>

<template>
  <div class="PageTransition">
    <Layout :title="t('your_account_title')">
      <template #banner>
        <ChargeErrorBanner
          v-if="nextOrderStore.order && nextOrderStore.order.status === 'error'"
          :next-order="nextOrderStore.order"
        >
        </ChargeErrorBanner>
        <DispatchBanner
          v-if="lastOrderStore.isRecent && lastOrderStore.summary"
          :order-date="formatDate('DD Month', lastOrderStore.summary.date)"
          :status="
            !lastOrderStore.summary.trackingInfo ? 'processed' : 'dispatched'
          "
          :track-url="lastOrderStore.summary?.trackingInfo?.url"
          :order-url="`/orders/${lastOrderStore.summary.id.replace(
            'gid://shopify/Order/',
            ''
          )}`"
          @view-order="
            lastOrderStore.summary &&
              $router.push(
                `/previous-orders/${lastOrderStore.summary.id.replace(
                  'gid://shopify/Order/',
                  ''
                )}`
              )
          "
        />
      </template>
      <template #main>
        <div class="Stack">
          <ExpandableSection
            title="New for you"
            subtitle="New products we think you’ll love"
            @expand="onExpandUpsell"
            @collapse="onCollapseUpsell"
            :is-expanded="isUpsellExpanded"
          >
            <HorizontalProductList
              :products="newRecommendationItems"
              :is-loading="
                recommendationStore.new.loadState ===
                  AsyncOperationState.IN_PROGRESS ||
                recommendationStore.new.loadState === AsyncOperationState.IDLE
              "
            ></HorizontalProductList>
          </ExpandableSection>
          <NextOrder
            v-if="authedUser.rechargeId"
            :order="nextOrderStore.order"
            :order-load-state="nextOrderStore.loadState"
            :card-last-four-digits="
              subscriptionCustomerStore.cardLastFourDigits
            "
            :images-by-product-id="productImageStore.productImages"
            :is-working="
              nextOrderStore.workState === AsyncOperationState.IN_PROGRESS ||
              subscriptionStore.workState === AsyncOperationState.IN_PROGRESS ||
              onetimeStore.workState === AsyncOperationState.IN_PROGRESS
            "
            :discount-state="nextOrderStore.discountState"
            :waste-g-saved="nextOrderPlasticWasteSavedCount"
            :waste-tonnes-saved="
              wasteMetricsStore.nextOrderWasteMetrics
                ? (wasteMetricsStore.nextOrderWasteMetrics.weight / 1000000) *
                  N_UK_HOUSEHOLDS
                : 0
            "
            :recommendation-items="recommendationStore['buy-again'].items"
            @edit-item="edit"
            @skip-item="skip"
            @change-date="changeDate"
            @edit-address="$router.push('/details/personal-info')"
            @edit-payment="$router.push('/details')"
            @apply-discount="applyDiscount"
            @add-to-next-order="addToNextOrder"
            @update-envelope-quantity="updateEnvelopeQuantity"
            @show-purchase-upsell="
              ($event) => {
                showingUpsellForProduct = $event
                upsellContext = 'next_order_upsell'
              }
            "
          ></NextOrder>
          <NonSubscriberCta v-else></NonSubscriberCta>
        </div>
      </template>
      <template #aside>
        <div class="Stack">
          <SimpleCta
            v-if="authedUser.rechargeId"
            :text="t('future_orders_cta')"
            @click="router.push('/future-orders')"
          ></SimpleCta>
          <SimpleCta
            v-if="authedUser.rechargeId"
            :text="t('your_subscriptions_cta')"
            @click="router.push('/subscriptions')"
          ></SimpleCta>
          <SimpleCta
            v-if="authedUser.rechargeId"
            :text="t('your_details_cta')"
            @click="router.push('/details')"
          ></SimpleCta>
          <SimpleCta
            :text="t('previous_orders_cta')"
            @click="router.push('/previous-orders')"
          ></SimpleCta>
          <StatsCard>
            <template #intro-title>{{
              t('plastic_waste_metrics_title')
            }}</template>
            <template #intro-content>
              <p
                v-html="
                  t('plastic_waste_metrics_copy', {
                    weight: customerPlasticWasteSavedCount.toFixed(2),
                    equivalent: Math.ceil(
                      (customerPlasticWasteSavedCount * 1000) / 14
                    ).toFixed(0),
                  })
                "
              ></p>
            </template>
          </StatsCard>
          <!-- <ImageCta
            text="Protecting seagrass with the MCS"
            href="https://bowercollective.com/pages/marine-conservation-society"
            link-text="Learn more"
            image-url="https://cdn.shopify.com/s/files/1/0112/6822/7134/files/sandy-seaweed_700x.jpg?v=1645024145"
          ></ImageCta> -->
        </div>
      </template>
    </Layout>
    <Teleport to="body">
      <UpsellModal
        v-if="showingUpsellForProduct"
        :upsell-product="upsellProduct"
        :product-load-state="productLoadState"
        :is-busy="
          subscriptionStore.workState === AsyncOperationState.IN_PROGRESS ||
          onetimeStore.workState === AsyncOperationState.IN_PROGRESS
        "
        @add-to-order="addUpsellToNextOrder"
        @close="showingUpsellForProduct = null"
      ></UpsellModal>
    </Teleport>
  </div>
</template>
