<script setup lang="ts">
import type { NavProduct } from '@/stores/navigation'
import { formatMoneyWithCurrency } from '@/lib/formatters/money'
import Button from '@/components/Button.vue'

defineProps<{
  label: string
  product: NavProduct
  version?: string
}>()

const getPricePer = (product: NavProduct) => {
  const productPrice = getProductPrice(product)
  if (product.size && product.size.value) {
    return `${formatMoneyWithCurrency(
      (((100 / product.size.value) * productPrice) / 100).toString()
    )} / 100${product.size.unit}`
  } else {
    return ''
  }
}

const getProductPrice = (product: NavProduct) => {
  const selectedVariant = product.variants[product.selectedVariantId]
  const subscriptionDiscount = Math.max(
    ...selectedVariant.sellingPlanAllocations.map(
      (allocation) => allocation.sellingPlan.price_adjustments[0].value
    )
  )
  return (parseInt(selectedVariant.price) * (100 - subscriptionDiscount)) / 100
}
</script>

<template>
  <div
    class="NavFeaturedProduct"
    :class="{ [`NavFeaturedProduct--${version}`]: !!version }"
  >
    <div>
      <span class="Label Label--dark Label--small">
        {{ label }}
      </span>
      <img :src="product.image" :alt="product.title" />
    </div>
    <div>
      <p>{{ product.title }}</p>
      <p class="NavFeaturedProduct__PriceLine">
        <span>
          {{
            formatMoneyWithCurrency((getProductPrice(product) / 100).toString())
          }}
        </span>
        <span>
          {{ getPricePer(product) }}
        </span>
      </p>
      <Button :modifiers="['block', 'inverted', 'small']" :href="product.url">
        Shop now
      </Button>
    </div>
  </div>
</template>

<style>
.NavFeaturedProduct {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;

  & > div {
    width: 50%;
  }

  & .Label {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
  }

  & img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: var(--radius-small);
  }

  & p {
    margin: 4px 0;
  }

  &.NavFeaturedProduct--single-col {
    display: block;
    width: 260px;

    & > div {
      width: 100%;
    }

    & img {
      aspect-ratio: 3/2;
    }
  }
}

.NavFeaturedProduct__PriceLine {
  display: flex;

  & > :first-child {
    flex-grow: 1;
    font-weight: bold;
  }

  & > :nth-child(2) {
    text-align: right;
    color: var(--color-forest-50);
  }
}
</style>
