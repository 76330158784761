import Bugsnag from '@bugsnag/js'
import { defineStore } from 'pinia'
import { getOrders, getOrder } from '../services/order'
import { AsyncOperationState } from './lib/AsyncOperationState'

interface LineItem {
  id: string
  name: string
  image: {
    small: string
    medium: string
    large: string
    original: string
    altText: string | null
  }
  quantity: number
  discountedUnitPriceSet: Money
}

interface Money {
  presentmentMoney: {
    amount: string
  }
}

export interface Order {
  id: string
  name: string
  createdAt: string
  discountCode: string
  lineItems: { edges: { node: LineItem }[] }
  totalPriceSet: Money
  subtotalPriceSet: Money
  totalShippingPriceSet: Money
  totalTaxSet: Money
  totalDiscountsSet: Money
  totalRefundedSet: Money
  totalReceivedSet: Money
  billingAddress: { formatted: string[] }
  shippingAddress?: { formatted: string[] }
  fulfillments: {
    status: string
    trackingInfo: {
      company: string
      number: string
      url: string
    }[]
  }[]
}

export interface PageInfo {
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageStartCursor: string
  pageEndCursor: string
}

export const useOrderStore = defineStore('order', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    orders: [] as Order[],
    order: null as Order | null,
    pageInfo: null as PageInfo | null,
  }),
  actions: {
    async initSingle(shopifyId: string, orderId: string) {
      if (
        !this.order ||
        this.order.id !== orderId ||
        this.loadState === AsyncOperationState.IDLE
      ) {
        this.order = null
        const inLoadedOrders = this.orders.find(
          (order) => order.id.replace('gid://shopify/Order/', '') === orderId
        )
        if (inLoadedOrders) {
          this.order = inLoadedOrders
        } else {
          this.loadState = AsyncOperationState.IN_PROGRESS
          try {
            const res = await getOrder(shopifyId, orderId)
            this.order = res.order
            this.loadState = AsyncOperationState.DONE
          } catch (e) {
            Bugsnag.notify(e as Error)
            this.loadState = AsyncOperationState.ERRORED
          }
        }
      }
    },
    async init(shopifyId: string) {
      if (!this.orders.length || this.loadState === AsyncOperationState.IDLE) {
        await this.load(shopifyId)
      }
    },
    async load(shopifyId: string) {
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const res = await getOrders(shopifyId)
        this.orders = res.orders
        this.pageInfo = res.pageInfo
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
    async loadNextPage(shopifyId: string) {
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const res = await getOrders(
          shopifyId,
          this.pageInfo?.pageEndCursor,
          'after'
        )
        this.orders = res.orders
        this.pageInfo = res.pageInfo
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
    async loadPreviousPage(shopifyId: string) {
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const res = await getOrders(
          shopifyId,
          this.pageInfo?.pageStartCursor,
          'before'
        )
        this.orders = res.orders
        this.pageInfo = res.pageInfo
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
  },
})
