import fetch from './lib/fetch'

export async function getNextOrder(rechargeId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/next-order`
  )
}

export async function getOrderSchedule(rechargeId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/next-charges`
  )
}

export async function getLastOrder(shopifyId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/ecommerce/${shopifyId}/last-order`
  )
}

export async function getOrders(
  shopifyId: string,
  cursor?: string,
  cursorType?: string
) {
  const qs = new URLSearchParams()
  if (cursor) qs.append('cursor', cursor)
  if (cursorType) qs.append('cursorType', cursorType)
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/ecommerce/${shopifyId}/orders?${qs.toString()}`
  )
}

export async function getOrder(shopifyId: string, id: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/ecommerce/${shopifyId}/order/${id}`
  )
}

export async function getCustomerWasteMetrics(shopifyId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/ecommerce/${shopifyId}/plastic-waste-metrics`
  )
}

export async function changeDate(rechargeId: string, from: string, to: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/change-date`,
    {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ from, to }),
    }
  )
}
