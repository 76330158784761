<script lang="ts" setup>
import Layout from '@/components/layout/Layout.vue'
import Loading from '@/components/Loading.vue'

import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import { useNextOrderStore } from '@/stores/nextOrder'
import type { AuthenticatedEntity } from '@/stores/auth'
import { computed } from 'vue'
import {
  useSubscriptionCustomerStore,
  type AddressField,
} from '@/stores/subscriptionCustomer'
import Onboarding from '../components/Onboarding.vue'
import { useOnboardingStore } from '@/stores/onboarding'
import { useRouter } from 'vue-router'

const props = defineProps<{
  authedUser: AuthenticatedEntity
}>()

const nextOrderStore = useNextOrderStore()
const subscriptionCustomerStore = useSubscriptionCustomerStore()
const onboardingStore = useOnboardingStore()

const router = useRouter()

nextOrderStore.init(props.authedUser.rechargeId)
subscriptionCustomerStore.init(props.authedUser.rechargeId)

const isLoading = computed(() => {
  return (
    subscriptionCustomerStore.loadState === AsyncOperationState.IN_PROGRESS ||
    nextOrderStore.loadState === AsyncOperationState.IN_PROGRESS
  )
})
const hasError = computed(() => {
  return (
    subscriptionCustomerStore.loadState === AsyncOperationState.ERRORED ||
    nextOrderStore.loadState === AsyncOperationState.ERRORED ||
    (subscriptionCustomerStore.loadState === AsyncOperationState.DONE &&
      !subscriptionCustomerStore.customer)
  )
})

const onSubmit = async (
  fromDate: string,
  toDate: string,
  addressId: string
) => {
  if (
    subscriptionCustomerStore.customer &&
    subscriptionCustomerStore.customer.include.addresses.length > 1
  ) {
    const address = subscriptionCustomerStore.customer.include.addresses.find(
      (ad) => `${ad.id}` === addressId
    )
    if (address) {
      const addressDetails = {
        first_name: subscriptionCustomerStore.customer.first_name,
        last_name: subscriptionCustomerStore.customer.last_name,
        address: {
          first_name: address.first_name,
          last_name: address.last_name,
          company: address.company,
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          phone: address.phone,
          province: address.province,
          zip: address.zip,
        } as Record<AddressField, string>,
      }
      await onboardingStore.confirmAddress(
        props.authedUser.rechargeId,
        addressDetails
      )
    }
  }
  await onboardingStore.setDate(props.authedUser.rechargeId, fromDate, toDate)
  router.replace('/')
}
</script>

<template>
  <div class="AppLoadingState" v-if="isLoading">
    <Loading />
  </div>
  <div v-else-if="hasError">Error</div>
  <Layout v-else>
    <template #main>
      <Onboarding
        :customer="subscriptionCustomerStore.customer!"
        :next-order="nextOrderStore.order"
        :is-busy="onboardingStore.workState === AsyncOperationState.IN_PROGRESS"
        @submit="onSubmit"
      >
      </Onboarding>
    </template>
  </Layout>
</template>
