<script lang="ts" setup>
import ProductListItem from '@/components/ProductListItem.vue'
import ProductListItemLoading from '@/components/ProductListItemLoading.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import DatePicker from './DatePicker.vue'
import DiscountInput from './DiscountInput.vue'
import Message from '@/components/Message.vue'
import Button from '@/components/Button.vue'
import NextOrderPlasticWasteMetrics from './NextOrderPlasticWasteMetrics.vue'
import ExpandableSection from './ExpandableSection.vue'
import HorizontalProductList from './HorizontalProductList.vue'
import NonSubscriberCta from './NonSubscriberCta.vue'
import QuantityControl from './QuantityControl.vue'

import IconSubscription from '@/assets/icons/subscription.svg?component'
import IconStatusWarning from '@/assets/icons/status-warning.svg?component'
import IconPencil from '@/assets/icons/pencil.svg?component'

import { formatMoneyWithCurrency } from '@/lib/formatters/money'

import { computed, ref } from 'vue'

import { t } from '../lib/locale'

import type { Order } from '@/stores/nextOrder'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import type { RecommendationItem } from '@/stores/recommendation'
import type { ImageMap } from '@/stores/productImage'

import { formatDate } from '@/lib/formatters/date'

import { returnEnvelopeId } from '@/lib/envelope'

const props = defineProps<{
  order: Order | null
  orderLoadState: AsyncOperationState
  discountState: AsyncOperationState
  cardLastFourDigits: string | null
  imagesByProductId: Record<string, ImageMap[] | undefined>
  isWorking: boolean
  wasteGSaved?: number
  wasteTonnesSaved?: number
  recommendationItems?: RecommendationItem[]
}>()

const emit = defineEmits<{
  (e: 'edit-item', id: string): void
  (e: 'skip-item', id: string): void
  (e: 'change-date', update: { from: string; to: string }): void
  (e: 'edit-address'): void
  (e: 'edit-payment'): void
  (e: 'apply-discount', discountCode: string): void
  (
    e: 'add-to-next-order',
    productId: string,
    variantId: string,
    context?: string,
    quantity?: number
  ): void
  (e: 'update-envelope-quantity', quantity: number): void
  (e: 'show-purchase-upsell', upsellProduct: RecommendationItem): void
}>()

const getImagesForProduct = (id: string) => {
  const imageList = props.imagesByProductId[id]
  if (imageList) {
    return imageList[0]
  } else {
    return undefined
  }
}

const amountUnderShipping = computed(() => {
  if (!props.order) return null
  if (shipping.value === null || shipping.value === 0) return null
  const totalBeforeDiscount =
    parseFloat(props.order.subtotal_price) * 100 +
    parseFloat(props.order.total_discounts) * 100
  const diff = (4000 - totalBeforeDiscount) / 100
  if (diff > 0) {
    return diff.toFixed(2)
  } else {
    return null
  }
})

const shipping = computed(() => {
  if (!props.order) return null
  return props.order.shipping_lines.reduce((accum, line) => {
    return accum + parseFloat(line.price) * 100
  }, 0)
})

const showDatePicker = ref(false)

const onDateChangeConfirm = (d: Date) => {
  showDatePicker.value = false
  if (props.order) {
    const to = formatDate('YYYY-MM-DD', d)
    emit('change-date', {
      from: props.order.scheduled_at,
      to,
    })
  }
}

const startChangingDate = () => {
  if (!props.isWorking) {
    showDatePicker.value = true
  }
}

const envelopeInOrder = computed(() =>
  props.order?.line_items.find(
    (item) =>
      item.external_product_id.ecommerce.toString() ===
      returnEnvelopeId.toString()
  )
)
</script>

<template>
  <div class="NextOrder Card">
    <div
      class="NextOrder__Loading NextOrder--is-loading"
      v-if="
        [AsyncOperationState.IN_PROGRESS, AsyncOperationState.IDLE].includes(
          orderLoadState
        )
      "
    >
      <div class="NextOrder__header">
        <h2 class="h4 GhostUI">Lorem ipsum lorem ipsum</h2>
        <p class="GhostUI">Lorem ipsum lorem ipsum lo</p>
        <Button :modifiers="['link']" class="GhostUI">Lorem ipsum lorem</Button>
      </div>
      <ul class="ProductList">
        <ProductListItemLoading />
        <ProductListItemLoading />
        <ProductListItemLoading />
        <ProductListItemLoading />
      </ul>
    </div>
    <div v-else-if="order">
      <div class="NextOrder__header">
        <h2 class="h4">
          {{ t('next_order_header_title') }}
        </h2>
        <p>
          <span
            v-html="
              t('next_order_header_subtitle', {
                date: formatDate('Weekday, DD Month', order.scheduled_at),
              })
            "
          ></span>
          <br />
          {{ t('next_order_header_copy') }}
        </p>
        <Button
          :modifiers="['link']"
          :class="{ 'Link--disabled': isWorking }"
          @click="startChangingDate"
        >
          <template #iconBefore><IconSubscription /></template>
          {{ t('next_order_change_date_cta') }}
        </Button>
        <transition name="DatePicker" appear>
          <div class="NextOrder__DatePicker__Outer" v-if="showDatePicker">
            <DatePicker
              :back-link-text="t('next_order_datepicker_back_cta')"
              :title="t('next_order_datepicker_title')"
              :description="t('next_order_datepicker_description')"
              :button-text="t('next_order_datepicker_save_cta')"
              :initialSelectedDate="new Date(order.scheduled_at)"
              :message="{
                text: t('next_order_datepicker_detail_description'),
                Icon: IconStatusWarning,
              }"
              @close="showDatePicker = false"
              @confirm="onDateChangeConfirm"
            ></DatePicker>
          </div>
        </transition>
      </div>
      <NextOrderPlasticWasteMetrics
        v-if="
          typeof wasteGSaved !== 'undefined' &&
          typeof wasteTonnesSaved !== 'undefined'
        "
        :g-saved="wasteGSaved"
        :tonnes-saved="wasteTonnesSaved"
      ></NextOrderPlasticWasteMetrics>
      <div class="NextOrder__PouchReturn">
        <p>
          Pre-paid returns envelope
          <br />
          <small
            >One envelope fits 4+ empty pouches (<a
              href="https://bowercollective.com/pages/how-pouch-returns-works"
              >?</a
            >)</small
          >
        </p>
        <QuantityControl
          :value="envelopeInOrder ? envelopeInOrder.quantity : 0"
          @change-quantity="emit('update-envelope-quantity', $event)"
          :is-busy="isWorking"
          :max-value="3"
          :min-value="0"
        ></QuantityControl>
      </div>
      <ul class="ProductList">
        <li
          v-for="lineItem in order.line_items.filter(
            (item) =>
              item.external_product_id.ecommerce.toString() !==
              returnEnvelopeId.toString()
          )"
          :key="lineItem.title"
        >
          <ProductListItem
            :id="lineItem.purchase_item_id.toString()"
            :title="lineItem.title"
            :variant-title="lineItem.variant_title"
            :images="
              getImagesForProduct(
                `${lineItem.external_product_id.ecommerce}:${lineItem.external_variant_id.ecommerce}`
              )
            "
            :price="lineItem.unit_price"
            :quantity="lineItem.quantity"
            :is-disabled="isWorking"
            :allow-skip="lineItem.purchase_item_type === 'subscription'"
            @edit="emit('edit-item', $event)"
            @skip="emit('skip-item', $event)"
          />
        </li>
      </ul>
      <div class="Divider"></div>
      <ExpandableSection
        :title="t('next_order_upsell_title')"
        v-if="recommendationItems && recommendationItems.length"
        :is-expanded="true"
      >
        <HorizontalProductList
          :products="
            recommendationItems.map((p) => ({
              title: p.title,
              imageUrl:
                p.images.find((i) => i.id === p.variants[0].image_id)?.src ||
                p.image.src,
              url: `https://bowercollective.com/products/${p.handle}`,
              hasMore: p.variants.length > 1,
              action: {
                text: t('upsell_add_cta'),
                callback: () => {
                  emit('show-purchase-upsell', p)
                },
              },
            }))
          "
        ></HorizontalProductList>
      </ExpandableSection>
      <div class="OrderMeta">
        <DiscountInput
          :active-discount-code="order.discounts[0]?.code"
          @apply-discount="$emit('apply-discount', $event)"
          :is-loading="discountState === AsyncOperationState.IN_PROGRESS"
          :error="
            discountState === AsyncOperationState.ERRORED
              ? t('discount_input_invalid_message')
              : ''
          "
        />
        <ul class="OrderMeta__CostBreakdown">
          <li v-if="order.discounts[0]?.code">
            <span> {{ t('order_meta_discount_code_heading') }} </span>
            <span class="DiscountCode">{{ order.discounts[0]?.code }}</span>
          </li>
          <li v-if="parseFloat(order.total_discounts) > 0">
            {{ t('order_meta_total_discount_heading') }}
            <b>-{{ formatMoneyWithCurrency(order.total_discounts) }}</b>
          </li>
          <li>
            <span v-html="t('order_meta_subtotal_heading')"></span>
            <b>{{ formatMoneyWithCurrency(order.subtotal_price) }}</b>
          </li>
          <li>
            <span>{{ t('order_meta_delivery_heading') }}</span>
            <b v-if="shipping && shipping !== 0">{{
              formatMoneyWithCurrency((shipping / 100).toString())
            }}</b>
            <b v-else>{{ t('order_meta_delivery_value_free') }}</b>
          </li>
          <li class="OrderMeta__OrderTotal h4">
            {{ t('order_meta_total_heading') }}
            <b>{{ formatMoneyWithCurrency(order.total_price) }}</b>
          </li>
        </ul>
        <Message
          v-if="amountUnderShipping"
          mode="dark"
          pointerPosition="topleft"
          pointerDirection="vertical"
        >
          <template v-slot:icon><IconStatusWarning /></template>
          <p>
            {{
              t('next_order_amount_extra_free_delivery', {
                money: amountUnderShipping,
              })
            }}
          </p>
        </Message>

        <div class="OrderMeta__Edit">
          {{ t('next_order_footer_delivers_to_label') }}
          {{ order.shipping_address.zip }}
          <Button :modifiers="['link']" @click="$emit('edit-address')">
            <template #iconBefore><IconPencil /></template>
            {{ t('next_order_footer_delivers_to_edit_cta') }}
          </Button>
        </div>
        <div class="OrderMeta__Edit">
          {{ t('next_order_footer_payment_method_label') }}
          {{ cardLastFourDigits || '…' }}
          <Button :modifiers="['link']" @click="$emit('edit-payment')">
            <template #iconBefore><IconPencil /></template>
            {{ t('next_order_footer_payment_method_edit_cta') }}
          </Button>
        </div>
      </div>
    </div>
    <div v-else>
      <ErrorMessage v-if="orderLoadState === AsyncOperationState.ERRORED">
        <template v-slot:title>
          {{ t('next_order_load_error_title') }}
        </template>
        <p>
          {{ t('next_order_load_error_subtitle') }}
        </p>
      </ErrorMessage>
      <NonSubscriberCta v-else></NonSubscriberCta>
    </div>
  </div>
</template>

<style>
.NextOrder__header {
  position: relative;
  padding: 20px 104px 30px 24px;

  &::before {
    content: '';
    display: block;
    background-image: url('@/assets/images/products-illustration.png');
    width: 141px;
    height: 180px;
    background-size: 141px 180px;
    position: absolute;
    top: -29px;
    right: -40px;
    z-index: 1;
  }

  & h2 {
    margin-bottom: 0;
  }
}

.ProductList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.OrderMeta {
  background-color: var(--color-lichen-100);
  padding: 24px;
  @media (--mq-tablet-and-up) {
    border-radius: 0 0 8px 8px;
  }
}

.OrderMeta__Cost {
  display: flex;
  justify-content: space-between;
  margin: 0 0 6px;
}

.OrderMeta__Edit {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.NextOrder__DatePicker__Outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  background-color: var(--color-white);
  overflow-y: auto;
  overscroll-behavior-y: none;
  padding-bottom: 85px;
}

.DatePicker-enter-from,
.DatePicker-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}

.DatePicker-enter-to,
.DatePicker-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.DatePicker-enter-active,
.DatePicker-leave-active {
  transition: opacity 0.2s ease-in, transform 0.18s ease-in;
}

@media (--mq-tablet-and-up) {
  .NextOrder__DatePicker__Outer {
    position: fixed;
    background-color: transparent;
    z-index: 5;
    width: 570px;
    height: auto;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior: none;
  }

  .DatePicker-enter-from,
  .DatePicker-leave-to {
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  .DatePicker-enter-to,
  .DatePicker-leave-from {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.NextOrder__PouchReturn {
  background-color: var(--color-deep-ocean-100);
  color: var(--color-warm-white-100);
  font-size: var(--font-size--small);
  display: flex;
  align-items: center;
  border-radius: var(--radius-large);
  margin: 24px 24px 0;

  & p {
    margin-bottom: 0;
    padding: 16px;
    flex-grow: 1;

    & a {
      color: var(--color-warm-white-100);
    }
  }

  & .QuantityControl {
    flex-shrink: 0;
    margin-right: 16px;
  }
}
</style>
