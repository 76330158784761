<script setup lang="ts">
import Layout from '@/components/layout/Layout.vue'
import EditablePanel from '@/components/EditablePanel.vue'
import Message from '@/components/Message.vue'
import PaymentMethodList from '@/components/PaymentMethodList.vue'
import { formatDate } from '@/lib/formatters/date'

import { useSubscriptionCustomerStore } from '@/stores/subscriptionCustomer'
import type { AuthenticatedEntity } from '@/stores/auth'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import { computed } from 'vue'

import { useBackLink } from './lib/backLink'
import { useNewPayment } from './lib/newPayment'

import { t } from '@/lib/locale'

const props = defineProps<{ authedUser: AuthenticatedEntity }>()

const { newPaymentHasError } = useNewPayment(props.authedUser.rechargeId)
const { backTo } = useBackLink()

const subscriptionCustomerStore = useSubscriptionCustomerStore()
subscriptionCustomerStore.init(props.authedUser.rechargeId)

const isLoading = computed(() =>
  [AsyncOperationState.IN_PROGRESS, AsyncOperationState.IDLE].includes(
    subscriptionCustomerStore.loadState
  )
)
</script>

<template>
  <Layout
    :title="t('your_details_title')"
    :description="t('your_details_description')"
    :back-link="{ text: t('your_account_back_cta') }"
    @back="backTo('/')"
  >
    <template #main>
      <div class="Stack">
        <EditablePanel
          :title="t('personal_info_title_short')"
          :loading="isLoading"
          @edit="$router.push('/details/personal-info')"
        >
          <dl v-if="subscriptionCustomerStore.customer">
            <dt>{{ t('personal_info_first_name_label') }}</dt>
            <dd>{{ subscriptionCustomerStore.customer.first_name }}</dd>
            <dt>{{ t('personal_info_last_name_label') }}</dt>
            <dd>{{ subscriptionCustomerStore.customer.last_name }}</dd>
            <dt>{{ t('personal_info_phone_label') }}</dt>
            <dd>
              {{ subscriptionCustomerStore.phoneNumber }}
            </dd>
            <dt>{{ t('personal_info_delivery_address_label') }}</dt>
            <dd>
              {{
                subscriptionCustomerStore.customer.include.addresses
                  .filter((address) => !!address.id)
                  .map((address) => `${address.address1} ${address.zip}`)[0]
              }}
            </dd>
          </dl>
        </EditablePanel>
        <div class="Stack">
          <Message v-if="newPaymentHasError" mode="error">
            {{ t('payment_method_add_error') }}
          </Message>
          <PaymentMethodList
            v-if="subscriptionCustomerStore.customer"
            :payment-methods="
              subscriptionCustomerStore.customer.include.payment_methods
            "
            :is-busy="
              subscriptionCustomerStore.workState ===
              AsyncOperationState.IN_PROGRESS
            "
            :is-loading="
              !subscriptionCustomerStore.customer &&
              subscriptionCustomerStore.loadState !==
                AsyncOperationState.IN_PROGRESS
            "
            @set-active="
              subscriptionCustomerStore.setActivePaymentMethod(
                props.authedUser.rechargeId,
                $event
              )
            "
            @remove="
              subscriptionCustomerStore.removePaymentMethod(
                props.authedUser.rechargeId,
                $event
              )
            "
            @add-payment-method="$router.push('/details/new-payment-method')"
          />
        </div>
        <EditablePanel
          :title="t('login_details_title')"
          :loading="isLoading"
          :can-edit="false"
        >
          <dl v-if="subscriptionCustomerStore.customer">
            <dt>{{ t('login_details_email_label') }}</dt>
            <dd>{{ subscriptionCustomerStore.customer.email }}</dd>
            <dt>{{ t('login_details_first_charge_label') }}</dt>
            <dd>
              {{
                subscriptionCustomerStore.customer.first_charge_processed_at
                  ? formatDate(
                      'DD Month YYYY',
                      subscriptionCustomerStore.customer
                        .first_charge_processed_at
                    )
                  : 'No charges processed yet'
              }}
            </dd>
            <dt>{{ t('login_details_active_subscriptions_label') }}</dt>
            <dd>
              {{
                subscriptionCustomerStore.customer.subscriptions_active_count
              }}
            </dd>
            <!-- <dt>{{ t('login_details_password_label') }}</dt>
            <dd>
              <Button :modifiers="['link']">{{
                t('login_details_reset_password_cta')
              }}</Button>
            </dd> -->
          </dl>
        </EditablePanel>
      </div>
    </template>
  </Layout>
</template>
