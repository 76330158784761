import fetch from './lib/fetch'

export async function getAuthenticatedIdentity() {
  return await fetch(`${import.meta.env.VITE_API_URL}/protected/identity`)
}

export async function getToken(tempToken?: string) {
  return await fetch(`${import.meta.env.VITE_API_URL}/auth/get-token`, {
    method: 'POST',
    headers: tempToken
      ? {
          Authorization: `Bearer ${tempToken}`,
        }
      : undefined,
  })
}
