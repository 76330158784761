import { defineStore } from 'pinia'
import { changeDate } from '../services/order'
import { getOnboardingStatus } from '@/services/onboarding'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { useTaskStore } from './task'
import { t } from '@/lib/locale'
import type { Customer } from '@/stores/subscriptionCustomer'
import { saveCustomerDetails } from '@/services/subscriptionCustomer'
import Bugsnag from '@bugsnag/js'

type AddressField = keyof Customer['include']['addresses'][number]

interface Status {
  hasSetDate: boolean
}

export const useOnboardingStore = defineStore('onboarding', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    workState: AsyncOperationState.IDLE,
    status: null as Status | null,
    stale: true,
  }),
  actions: {
    async init(rechargeId: string): Promise<Status> {
      if (
        !this.stale &&
        this.status &&
        this.loadState === AsyncOperationState.DONE
      ) {
        return this.status
      }
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const status = await getOnboardingStatus(rechargeId)
        this.status = status
        this.loadState = AsyncOperationState.DONE
        return status
      } catch (e) {
        this.loadState = AsyncOperationState.ERRORED
        throw e
      }
    },
    async setDate(rechargeId: string, oldDate: string, newDate: string) {
      const taskStore = useTaskStore()
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskId = taskStore.setTask(
        t('onboarding_date_change_toast_progess_1'),
        this.workState
      )
      let timeout = setTimeout(() => {
        taskStore.updateTask(
          taskId,
          t('onboarding_date_change_toast_progess_2'),
          this.workState
        )
        timeout = setTimeout(() => {
          taskStore.updateTask(
            taskId,
            t('onboarding_date_change_toast_progess_3'),
            this.workState
          )
        }, 5000)
      }, 3000)
      try {
        await changeDate(rechargeId, oldDate, newDate)
        clearTimeout(timeout)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('onboarding_date_change_toast_success'),
          this.workState
        )
      } catch (e) {
        clearTimeout(timeout)
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('onboarding_date_change_toast_error'),
          this.workState
        )
      }
    },
    async confirmAddress(
      rechargeId: string,
      addressDetails: {
        first_name: string
        last_name: string
        address: Record<AddressField, string>
      }
    ) {
      const taskStore = useTaskStore()
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskId = taskStore.setTask(
        t('onboarding_address_confirmation_toast_progess'),
        this.workState
      )
      try {
        await saveCustomerDetails(rechargeId, addressDetails)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('onboarding_address_confirmation_toast_success'),
          this.workState
        )
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('onboarding_address_confirmation_toast_error'),
          this.workState
        )
      }
    },
  },
})
