import { ref } from 'vue'

const easingFns = {
  easeOutCubic(x: number) {
    return 1 - Math.pow(1 - x, 3)
  },
}

export function countup(startValue: number) {
  const r = ref<number>(startValue)
  return {
    ref: r,
    start: (
      endValue: number,
      ms: number,
      easingFn: keyof typeof easingFns = 'easeOutCubic'
    ) => {
      const animationStart = new Date().getTime()
      const frame = () => {
        const elapsed = new Date().getTime() - animationStart
        if (elapsed > ms) {
          r.value = endValue
          return
        } else {
          r.value = easingFns[easingFn](elapsed / ms) * endValue
        }
        requestAnimationFrame(frame)
      }
      requestAnimationFrame(frame)
    },
  }
}
