import Bugsnag from '@bugsnag/js'
import { defineStore } from 'pinia'
import { getNavigation } from '../services/navigation'
import { AsyncOperationState } from './lib/AsyncOperationState'

export interface NavProduct {
  id: string
  url: string
  image: string
  title: string
  selectedVariantId: number
  labels: string
  defaultFrequency: string | null
  sellingPlanGroups: {
    id: string
    name: string
    options: { name: string; position: number; values: string[] }[]
    selling_plans: SellingPlan[]
    app_id: string
  }[]
  requiresSellingPlan: boolean
  size?: {
    unit: string | null
    value: number | null
  }
  variants: {
    [key: string]: {
      id: number
      image: string | null
      available: boolean
      title: string
      compareAtPrice: string
      price: string
      sku: string
      weight: number | null
      sellingPlanAllocations: {
        sellingPlanGroupId: string
        price: number
        compareAtPrice: number
        sellingPlan: SellingPlan
      }[]
      inventoryQuantity: number
      inventoryPolicy: string
    }
  }
  variantOptionLabel: string
  starterKitSubscriptionConfig:
    | {
        productVariantId: string
        quantity: number
        frequency: number
      }[]
    | null
  preSale: boolean
  waitlistPageUrl: string
  compatibleDispenser: string[] | null
  fragranceGroupId: string | null
  fragranceName: string | null
  type: string
  onboardingUsageSettings: number[] | null
  onboardingUsageMetric: 'bathrooms' | 'people' | null
  available: boolean
  relatedProductReviews: string | null
}

interface SellingPlan {
  id: number
  name: string
  description: null | string
  options: { name: string; position: number; value: string }[]
  recurring_deliveries: boolean
  price_adjustments: {
    order_count: null | number
    position: number
    value_type: string
    value: number
  }[]
  checkout_charge: { value_type: string; value: number }
}

interface NavItem {
  title: string
  handle: string
  children: NavItem[]
  url: string
}
export type Nav = NavItem[]

export interface NavSettings {
  featuredProduct: NavProduct | null
  featuredProductLabel: string
  aboutUsHeading: string
  aboutUsText: string
  sections: {
    name: string
    text: string
    cta_text: string | null
    cta_target: string | null
  }[]
}

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    main: null as Nav | null,
    mainMobile: null as Nav | null,
    footer: null as Nav | null,
    navSettings: null as NavSettings | null,
  }),
  actions: {
    async load() {
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const nav = await getNavigation()
        this.main = nav.main
        this.mainMobile = nav.mainMobile
        this.footer = nav.footer
        this.navSettings = nav.navSettings
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
  },
})
