<script setup lang="ts">
import { useOrderStore } from '@/stores/order'
import type { AuthenticatedEntity } from '@/stores/auth'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'

import Layout from '@/components/layout/Layout.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import OrderDetail from '../components/OrderDetail.vue'

import { t } from '@/lib/locale'

import { useBackLink } from './lib/backLink'
const { backTo } = useBackLink()

const props = defineProps<{
  id: string
  authedUser: AuthenticatedEntity
}>()

const orderStore = useOrderStore()
orderStore.initSingle(props.authedUser.shopifyId, props.id)
</script>

<template>
  <Layout
    :back-link="{ text: t('previous_orders_back_cta') }"
    @back="backTo('/previous-orders')"
  >
    <template #main>
      <OrderDetail
        :order="orderStore.order || undefined"
        v-if="
          orderStore.order ||
          orderStore.loadState === AsyncOperationState.IN_PROGRESS
        "
      ></OrderDetail>
      <template
        v-else-if="orderStore.loadState === AsyncOperationState.ERRORED"
      >
        <div class="Card">
          <ErrorMessage>
            <template #title>{{ t('order_not_found_title') }}</template>
            <p>{{ t('order_not_found_copy') }}</p>
          </ErrorMessage>
        </div>
      </template>
    </template>
  </Layout>
</template>
