import dictionary from '../../locales/en.json'

export function t(key: keyof typeof dictionary, data?: Record<string, string>) {
  const templateString = dictionary[key]
  if (!data) {
    return templateString
  } else {
    return Object.keys(data).reduce((prevString, key) => {
      return prevString.replace(`#{${key}}`, data[key])
    }, templateString)
  }
}
