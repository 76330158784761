<script lang="ts" setup>
import { computed } from 'vue'
import IconArrow from '../assets/icons/arrow.svg?component'

const props = defineProps<{
  text?: string
  href?: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'click'): void
}>()

const type = computed(() => {
  if (props.href) {
    return 'a'
  } else {
    return 'button'
  }
})

const emitClick = function () {
  emit('click')
}
</script>

<template>
  <component
    :is="type"
    :href="href"
    class="SimpleCta Card"
    :disabled="disabled"
    @click="emitClick"
  >
    <span class="SimpleCta__text h4">{{ text }}</span>
    <div class="SimpleCta__Icon">
      <IconArrow />
    </div>
  </component>
</template>

<style>
.SimpleCta {
  border: none;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: var(--color-forest-100);
  transition: box-shadow var(--global-transition);
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.SimpleCta__Icon {
  margin-left: 20px;
  width: 24px;
  transition: transform var(--global-transition);
}

.SimpleCta:hover {
  text-decoration: none;
  box-shadow: 0 0 5px 0 rgba(var(--rgb-forest-100), 20%);
}

.SimpleCta:hover .SimpleCta__Icon {
  transform: translateX(4px);
}

@media (--mq-tablet-and-up) {
  .SimpleCta {
    padding: 20px 24px;
  }
}
</style>
