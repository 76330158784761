import { loadStripe } from '@stripe/stripe-js'
import { useTaskStore } from '@/stores/task'
import { onMounted, ref } from 'vue'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import { useSubscriptionCustomerStore } from '@/stores/subscriptionCustomer'
import Bugsnag from '@bugsnag/js'
import mixpanel from 'mixpanel-browser'

export function useNewPayment(rechargeId: string) {
  const taskStore = useTaskStore()
  const subscriptionCustomerStore = useSubscriptionCustomerStore()

  const newPaymentHasError = ref(false)

  onMounted(async () => {
    // we get redirected to this page with a querystring when a new payment method has been created
    const setupIntentClientSecret = new URLSearchParams(
      window.location.search
    ).get('setup_intent_client_secret')

    let taskId
    try {
      if (setupIntentClientSecret) {
        const stripe = await loadStripe(
          import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
        )
        if (!stripe) throw new Error('Failed to load Stripe')
        taskId = taskStore.setTask(
          'Waiting for payment information to arrive',
          AsyncOperationState.IN_PROGRESS
        )

        let tries = 0
        const retrieveSetupIntentWithRetry = () => {
          stripe
            .retrieveSetupIntent(setupIntentClientSecret)
            .then(async (res) => {
              tries++
              if (res.setupIntent && res.setupIntent.payment_method) {
                await subscriptionCustomerStore.savePaymentMethod(
                  rechargeId,
                  res.setupIntent.payment_method
                )
                mixpanel.track('add_payment_method', { success: true })
              } else {
                if (tries > 10) {
                  newPaymentHasError.value = true
                  Bugsnag.notify(
                    new Error(
                      'Could not retrieve payment method for setup intent, max retries reached'
                    ),
                    (e) => {
                      if (res.setupIntent)
                        e.addMetadata('stripe', res.setupIntent)
                    }
                  )
                  mixpanel.track('add_payment_method', { success: false })
                  return
                }
                setTimeout(retrieveSetupIntentWithRetry, 5000)
              }
            })
        }
        retrieveSetupIntentWithRetry()
      }
    } catch (e) {
      mixpanel.track('add_payment_method', { success: false })
      if (taskId !== undefined) taskStore.clearTask(taskId)
      Bugsnag.notify(e as unknown as Error)
      newPaymentHasError.value = true
    }
  })
  return { newPaymentHasError }
}
