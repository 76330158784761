<script lang="ts" setup>
import { ref } from 'vue'
import IconAdd from '@/assets/icons/add.svg?component'
import IconMinus from '../assets/icons/minus.svg?component'
import Button from './Button.vue'

import { t } from '../lib/locale'

const props = withDefaults(
  defineProps<{
    isExpanded?: boolean
    error?: string
    isLoading?: boolean
    activeDiscountCode?: string
  }>(),
  {
    isExpanded: false,
    isloading: false,
  }
)

defineEmits<{
  (e: 'apply-discount', discountCode: string): void
}>()

const sectionIsExpanded = ref(props.isExpanded)

const toggleDiscountInput = function () {
  if (sectionIsExpanded.value === false) {
    sectionIsExpanded.value = true
  } else {
    sectionIsExpanded.value = false
  }
}

const discountCode = ref<string>(props.activeDiscountCode || '')
</script>

<template>
  <div
    class="DiscountInput"
    :class="[
      { 'DiscountInput--is-expanded': sectionIsExpanded },
      { 'DiscountInput--has-error': !!error },
    ]"
  >
    <div class="DiscountInput__Main">
      <Button :modifiers="['link']" @click="toggleDiscountInput">
        <template #iconBefore>
          <IconAdd v-if="!sectionIsExpanded" />
          <IconMinus v-else />
        </template>
        {{ t('discount_input_show_cta') }}
      </Button>
    </div>
    <transition name="DiscountInput__Content">
      <div class="DiscountInput__Content" v-show="sectionIsExpanded">
        <form class="DiscountInput__Form" @submit.prevent>
          <label for="discountCode" class="DiscountInput__Label sr-only"
            >{{ t('discount_input_label') }}:</label
          >
          <div class="DiscountInput__TextFieldWrapper">
            <input
              type="text"
              name="discountCode"
              placeholder="Gift card or discount code"
              class="DiscountInput__TextField"
              :disabled="isLoading"
              :class="[
                {
                  'DiscountInput__TextField--has-error Input--inline-error':
                    !!error,
                },
              ]"
              v-model="discountCode"
            />
            <span class="DiscountInput__InlineErrorMessage" v-if="error">
              {{ error }}
            </span>
          </div>
          <Button
            :modifiers="[]"
            :loading="isLoading"
            @click="$emit('apply-discount', discountCode)"
          >
            {{ t('discount_input_apply_cta') }}
          </Button>
        </form>
      </div>
    </transition>
  </div>
</template>

<style>
.DiscountInput {
  margin-bottom: 16px;
}

.DiscountInput__Content {
  overflow: hidden;
  padding: 16px 0 0;
}

.DiscountInput__Form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.DiscountInput__TextFieldWrapper {
  width: 100%;
  margin-bottom: 16px;
  padding: 1px;
}

.DiscountInput__TextField {
  width: 100%;
  min-height: 46px;
  position: relative;
  top: -2px;
}

.DiscountInput__Form .Button {
  min-height: 40px;
}

.DiscountInput__InlineErrorMessage {
  font-size: var(--font-size--small);
  color: var(--color-error-100);
  display: block;
  padding-top: 5px;
}

.DiscountInput__ActiveDiscountCode {
  margin: 16px 0 0;

  & span {
    font-weight: 600;
  }
}

@media (--mq-tablet-and-up) {
  .DiscountInput__TextFieldWrapper {
    margin: 0 16px 0 0;
    width: 240px;
  }
}

.DiscountInput__Content-enter-from,
.DiscountInput__Content-leave-to {
  opacity: 0;
}

.DiscountInput__Content-enter-to,
.DiscountInput__Content-leave-from {
  opacity: 1;
}

.DiscountInput__Content-enter-active,
.DiscountInput__Content-leave-active {
  transition: opacity 0.25s linear;
  overflow: hidden;
}
</style>
