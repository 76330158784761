<script lang="ts" setup>
import ProductCard from './ProductCard.vue'
import Button from './Button.vue'
import type { Onetime } from '@/stores/onetime'
import SubscriptionIcon from '../assets/icons/subscription.svg?component'
import IconBin from '../assets/icons/bin.svg?component'
import type { ImageMap } from '@/stores/productImage'
import OnetimeManager from './OnetimeManager.vue'

import { formatDate } from '@/lib/formatters/date'

import { t } from '@/lib/locale'

const props = defineProps<{
  item: Onetime
  link?: string
  isWorking: boolean
  inNextOrder: boolean
  productImages: Record<string, ImageMap[] | undefined>
}>()

defineEmits<{
  (e: 'cancel'): void
  (e: 'save', update: { quantity: number }): void
}>()

const imageForProduct = (id: string) => {
  const images = props.productImages[id]
  if (images) {
    return images[0].medium
  } else {
    return undefined
  }
}
</script>

<template>
  <ProductCard
    class="Card"
    :title="item.product_title"
    :link="link"
    :image-url="
      imageForProduct(
        `${item.external_product_id.ecommerce}:${item.external_variant_id.ecommerce}`
      )
    "
    :label="
      inNextOrder
        ? {
            text: t('purchase_item_in_next_order_label'),
            Icon: SubscriptionIcon,
          }
        : undefined
    "
    :is-working="isWorking"
  >
    <template #description>
      <div
        v-html="
          t('purchase_item_onetime_summary', {
            quantity: item.quantity.toString(),
            date: formatDate('DD Mon', item.next_charge_scheduled_at),
          })
        "
      ></div>
    </template>
  </ProductCard>

  <Transition name="fade" appear>
    <div v-if="item.next_charge_scheduled_at">
      <OnetimeManager
        :quantity="item.quantity"
        :is-working="isWorking"
        @save-changes="$emit('save', $event)"
      ></OnetimeManager>
    </div>
  </Transition>

  <Transition name="fade" appear>
    <Button
      :disabled="isWorking"
      :modifiers="['card', 'inverted', 'error']"
      @click="$emit('cancel')"
      v-if="item.next_charge_scheduled_at"
    >
      <template #iconBefore><IconBin /></template>
      {{ t('purchase_item_onetime_cancel_cta') }}
    </Button>
  </Transition>
</template>
