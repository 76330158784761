// type format = 'DD Month YYYY' | 'Weekday, DD Month' |

const formatters = {
  'DD Month YYYY': (d: Date) =>
    new Intl.DateTimeFormat('en-GB', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(d),

  'Weekday, DD Month': (d: Date) =>
    new Intl.DateTimeFormat('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    }).format(d),

  'DD Mon': (d: Date) =>
    new Intl.DateTimeFormat('en-GB', {
      month: 'short',
      day: 'numeric',
    }).format(d),

  'DD Month': (d: Date) =>
    new Intl.DateTimeFormat('en-GB', {
      month: 'long',
      day: 'numeric',
    }).format(d),
  DDth: (d: Date) => {
    const date = d.getDate()
    const suffixes = ['th', 'st', 'nd', 'rd']
    const ord = suffixes[(date - 20) % 10] || suffixes[date] || suffixes[0]
    return `${date}${ord}`
  },
  'YYYY-MM-DD': (d: Date) => {
    return `${d.getFullYear()}-${(d.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
  },
}

export function formatDate(
  format: keyof typeof formatters,
  date: Date | string
) {
  const d = new Date(date)
  return formatters[format](d)
}
