<script lang="ts" setup>
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import Button from './Button.vue'
import Message from './Message.vue'
import IconChevronButton from '../assets/icons/chevron-button.svg?component'
import IconClose from '../assets/icons/close.svg?component'
import IconArrow from './icons/IconArrow.vue'
import type { Component } from 'vue'
import { usePageOverlay } from '@/lib/pageOverlay'

const props = withDefaults(
  defineProps<{
    today?: Date
    initialSelectedDate?: Date
    title?: string
    description?: string
    buttonText?: string
    backLinkText?: string
    message?: {
      text: string
      Icon: Component
    }
  }>(),
  {
    today: () => new Date(),
  }
)

const emit = defineEmits<{
  (e: 'change', d: Date): void
  (e: 'confirm', d: Date): void
  (e: 'close'): void
}>()

function getDaysInMonth(d: Date) {
  return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()
}

function getMonthStartDay(d: Date) {
  return new Date(d.getFullYear(), d.getMonth(), 1).getDay()
}

function getMonthEndDay(d: Date) {
  return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDay()
}

function isSameDate(d1: Date, d2?: Date) {
  if (!d2) return false
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

const selectedDate = ref(props.initialSelectedDate)
watch(selectedDate, () => {
  if (selectedDate.value) emit('change', selectedDate.value)
})

interface CalendarDay {
  date: number
  isToday: boolean
  isSelected: boolean
  isSelectable: boolean
}

interface CalendarMonth {
  length: number
  name: string
  year: number
  month: number
  isCurrentMonth: boolean
  startDay: number
  endDay: number
  placeholderDaysPre: string[]
  days: CalendarDay[]
  placeholderDaysPost: string[]
  isSelectedMonth: boolean
}
const calendar = computed<CalendarMonth[]>(() => {
  const calendar: CalendarMonth[] = []
  for (let i = -1; i <= 3; i++) {
    const monthDate = new Date(
      props.today.getFullYear(),
      props.today.getMonth() + i
    )
    const length = getDaysInMonth(monthDate)
    const isCurrentMonth =
      monthDate.getFullYear() === props.today.getFullYear() &&
      monthDate.getMonth() === props.today.getMonth()
    const startDay = getMonthStartDay(monthDate)
    const endDay = getMonthEndDay(monthDate)
    const year = monthDate.getFullYear()
    const month = monthDate.getMonth()
    calendar.push({
      length,
      year,
      name: monthDate.toLocaleString('en-gb', { month: 'long' }),
      month,
      isCurrentMonth,
      startDay,
      endDay,
      placeholderDaysPre: Array(startDay)
        .fill(true)
        .map((_, i) =>
          calendar[calendar.length - 1]
            ? `${calendar[calendar.length - 1].length - (startDay - 1 - i)}`
            : '-'
        ),
      days: Array(length)
        .fill(true)
        .map((_, i) => ({
          date: i + 1,
          isToday: isCurrentMonth && i + 1 === props.today.getDate(),
          isSelectable: !isCurrentMonth || i + 1 > props.today.getDate(),
          isSelected:
            !!selectedDate.value &&
            isSameDate(new Date(year, month, i + 1), selectedDate.value),
        })),
      placeholderDaysPost: Array(6 - endDay)
        .fill(true)
        .map((_, i) => `${i + 1}`),
      isSelectedMonth:
        !!selectedDate.value &&
        selectedDate.value.getFullYear() === year &&
        selectedDate.value.getMonth() === month,
    })
  }
  return calendar
})

const selectedMonthIndex = calendar.value.findIndex(
  (month) => month.isSelectedMonth
)
const visibleMonthIndex = ref(selectedMonthIndex > 0 ? selectedMonthIndex : 1)
const visibleMonth = computed(() => calendar.value[visibleMonthIndex.value])

const showNextMonth = () => {
  visibleMonthIndex.value = Math.min(
    visibleMonthIndex.value + 1,
    calendar.value.length - 1
  )
}

const showPrevMonth = () => {
  visibleMonthIndex.value = Math.max(visibleMonthIndex.value - 1, 1)
}

const selectDate = (d: number, month: CalendarMonth) => {
  selectedDate.value = new Date(month.year, month.month, d)
}

const confirm = () => {
  if (selectedDate.value) emit('confirm', selectedDate.value)
}

const { show, hide } = usePageOverlay(
  (e) => {
    if (document.querySelector('.PageOverlay') === e.target) {
      emit('close')
    }
  },
  (e) => {
    if (e.key === 'Escape') {
      emit('close')
    }
  }
)

onBeforeMount(show)
onBeforeUnmount(hide)
</script>

<template>
  <div class="DatePicker Card">
    <button class="DatePicker__Close ButtonClose" @click="$emit('close')">
      <IconClose />
    </button>

    <div class="DatePicker__Back">
      <Button :modifiers="['link']" @click="$emit('close')">
        <template #iconBefore><IconArrow :flip-y="true"></IconArrow></template>
        {{ backLinkText }}
      </Button>
    </div>

    <div class="DatePicker__Main Card">
      <h4 class="DatePicker__Title" v-if="title">{{ title }}</h4>
      <p v-if="description">{{ description }}</p>
      <div class="DatePicker__Calendar">
        <div class="DatePicker__CalendarHeader">
          <button
            @click="showPrevMonth"
            v-if="visibleMonthIndex > 1"
            class="DatePicker__MonthSelect DatePicker__MonthSelect--prev"
          >
            <IconChevronButton />
          </button>
          <span class="DatePicker__MonthName">{{ visibleMonth.name }}</span>
          <button
            class="DatePicker__MonthSelect DatePicker__MonthSelect--next"
            @click="showNextMonth"
            v-if="visibleMonthIndex < calendar.length - 1"
          >
            <IconChevronButton />
          </button>
        </div>
        <div class="DatePicker__Grid">
          <span
            v-for="d in ['S', 'M', 'T', 'W', 'T', 'F', 'S']"
            :key="d"
            class="DatePicker__Grid__Cell DatePicker__Grid__Cell--header"
          >
            {{ d }}
          </span>
          <span
            v-for="d in visibleMonth.placeholderDaysPre"
            :key="d"
            class="DatePicker__Grid__Cell DatePicker__Grid__Cell--inactive"
            >{{ d }}</span
          >
          <span
            v-for="day in visibleMonth.days"
            :key="day.date"
            class="DatePicker__Grid__Cell DatePicker__Grid__Cell"
            :class="{
              'DatePicker__Grid__Cell--selected': day.isSelected,
              'DatePicker__Grid__Cell--selectable': day.isSelectable,
              'DatePicker__Grid__Cell--today': day.isToday,
            }"
            @click="day.isSelectable && selectDate(day.date, visibleMonth)"
          >
            {{ day.date }}
          </span>
          <span
            v-for="d in visibleMonth.placeholderDaysPost"
            :key="d"
            class="DatePicker__Grid__Cell DatePicker__Grid__Cell--inactive"
            >{{ d }}</span
          >
        </div>
      </div>

      <Message
        mode="dark"
        v-if="
          message &&
          selectedDate &&
          !isSameDate(selectedDate, initialSelectedDate)
        "
      >
        <template #icon><component :is="message.Icon" /></template>
        {{ message.text }}
      </Message>

      <Button
        :modifiers="['block']"
        @click="confirm"
        :disabled="
          !selectedDate || isSameDate(selectedDate, initialSelectedDate)
        "
      >
        {{ buttonText }}
      </Button>
    </div>
  </div>
</template>

<style>
.DatePicker__Back {
  background-color: var(--color-lichen-25);
  padding: 40px 24px 16px;
}

.DatePicker__Main {
  background-color: var(--color-white);
  padding: 24px;
  position: relative;

  &::before {
    content: '';
    display: block;
    background-image: url('../assets/images/calendar.png');
    background-size: 133px;
    width: 133px;
    height: 133px;
    position: absolute;
    top: -50px;
    right: -0;
    z-index: 1;
  }
}

.DatePicker__Title {
  padding-right: 91px;
  margin: 0 0 16px;
  min-height: 56px;
}

.DatePicker__Calendar {
  background-color: var(--color-lichen-100);
  padding: 16px;
  border-radius: var(--radius-large);
  margin-bottom: 16px;
}

.DatePicker__CalendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  height: 36px;
}

.DatePicker__MonthName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.DatePicker__MonthSelect {
  background: transparent;
  border: 0;
  cursor: pointer;
  width: 36px;
  height: 36px;
  color: var(--color-ocean-100);
  border-radius: var(--radius-small);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--global-transition);
  position: absolute;
  top: 0;

  & svg {
    width: 6px;
  }

  &:hover {
    background-color: var(--color-forest-100);
    color: var(--color-white);
  }
}

.DatePicker__MonthSelect--prev {
  transform: rotate(-180deg);
  left: 0;
}

.DatePicker__MonthSelect--next {
  right: 0;
}

.DatePicker__Grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
}

.DatePicker__Grid__Cell {
  color: var(--color-forest-50);
  border-radius: var(--radius-small);
  transition: background-color var(--global-transition),
    all var(--global-transition);
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-child(-n + 7) {
    margin: 0 -8px;
  }

  &:nth-child(1) {
    margin-left: 0;
  }

  &:nth-child(7) {
    margin-right: 0;
  }
}

.DatePicker__Grid__Cell--selectable {
  color: var(--color-forest-100);
}

.DatePicker__Grid__Cell--selectable:not(.DatePicker__Grid__Cell--selected) {
  &:hover {
    background-color: var(--color-lichen-25);
    cursor: pointer;
  }
}

.DatePicker__Grid__Cell--today:not(.DatePicker__Grid__Cell--selected) {
  background-color: var(--color-lichen-25);
}

.DatePicker__Grid__Cell--selected {
  background-color: var(--color-forest-100);
  color: var(--color-warm-white-100);
}

.DatePicker__Grid__Cell--header {
  border-bottom: 1px solid var(--color-lichen-150);
  color: var(--color-forest-100);
  border-radius: 0;
}

.DatePicker__Close {
  display: none;
}

.DatePicker .Message {
  margin-bottom: 24px;
}

@media (--mq-tablet-and-up) {
  .DatePicker {
    position: relative;
    box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 20%);
  }

  .DatePicker__Main::before {
    display: none;
    content: '';
  }

  .DatePicker__Back {
    display: none;
  }

  .DatePicker__Close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 24px;
    right: 24px;
    background-color: var(--color-ocean-100);
    color: var(--color-white);
    border: 0;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    transition: all var(--global-transition);
    z-index: 1;

    & svg {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background-color: var(--color-forest-100);
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  .DatePicker__Title {
    padding-right: none;
    max-width: 380px;
    min-height: 0;
  }
}
</style>
