import fetch from './lib/fetch'

export async function getOnetimes(rechargeId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/onetimes`
  )
}

export async function cancelOnetime(rechargeId: string, onetimeId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/onetime/${onetimeId}/cancel`,
    {
      method: 'POST',
    }
  )
}

export async function updateOnetime(
  rechargeId: string,
  subscriptionId: string,
  update: {
    quantity: number
  }
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/onetime/${subscriptionId}`,
    {
      method: 'PUT',
      body: JSON.stringify(update),
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export async function addToNextOrder(
  rechargeId: string,
  addressId: string,
  productId: string,
  variantId: string,
  nextOrderDate: string,
  quantity: number
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/onetime`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        address_id: addressId,
        product_id: productId,
        variant_id: variantId,
        scheduled_at: nextOrderDate,
        quantity,
      }),
    }
  )
}
