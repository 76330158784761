<script lang="ts" setup>
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import ProductListItem from '../components/ProductListItem.vue'
import LoadingCta from '../components/LoadingCta.vue'
import ExpandableSection from '@/components/ExpandableSection.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import type { ImageMap } from '@/stores/productImage'
import { formatDate } from '@/lib/formatters/date'
import { returnEnvelopeId } from '@/lib/envelope'

import { t } from '../lib/locale'

import type { Order } from '@/stores/futureOrder'
import { computed } from 'vue'

const props = defineProps<{
  orders: Order[]
  loadState: AsyncOperationState
  workState?: AsyncOperationState
  imagesByProductId: Record<string, ImageMap[] | undefined>
}>()

const orders = computed(() => props.orders.slice(1))

defineEmits<{
  (e: 'previous'): void
  (e: 'next'): void
  (e: 'edit-item', itemId: string): void
}>()

const getImagesForProduct = (id: string) => {
  const imageList = props.imagesByProductId[id]
  if (imageList) {
    return imageList[0]
  } else {
    return undefined
  }
}
</script>

<template>
  <div
    class="Stack"
    v-if="
      [AsyncOperationState.IN_PROGRESS, AsyncOperationState.IDLE].includes(
        loadState
      )
    "
  >
    <LoadingCta />
    <LoadingCta />
    <LoadingCta />
    <LoadingCta />
  </div>

  <ErrorMessage v-else-if="[AsyncOperationState.ERRORED].includes(loadState)">
    <template #title>{{ t('future_orders_load_error_title') }}</template>
    <p>{{ t('future_orders_load_error_subtitle') }}</p>
  </ErrorMessage>

  <div v-else>
    <div v-if="orders[0]" class="Stack">
      <ExpandableSection
        v-for="(order, i) in orders"
        :title="formatDate('DD Month YYYY', order.scheduled_at)"
        :is-expanded="i === 0"
        :key="order.id"
      >
        <ProductListItem
          v-for="item in order.line_items.filter(
            (item) =>
              item.external_product_id.ecommerce.toString() !==
              returnEnvelopeId.toString()
          )"
          :key="item.title"
          :id="item.purchase_item_id.toString()"
          :title="item.title"
          :variant-title="item.variant_title"
          :quantity="item.quantity"
          :price="item.unit_price"
          :allow-edit="item.purchase_item_type === 'subscription'"
          :images="
            getImagesForProduct(
              `${item.external_product_id.ecommerce}:${item.external_variant_id.ecommerce}`
            )
          "
          :is-disabled="workState === AsyncOperationState.IN_PROGRESS"
          @edit="$emit('edit-item', $event)"
        />
      </ExpandableSection>
    </div>

    <ErrorMessage v-else>
      <template #title>{{ t('future_orders_empty_title') }}</template>
      <p>{{ t('future_orders_empty_subtitle') }}</p>
    </ErrorMessage>
  </div>
</template>
