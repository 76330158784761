import fetch from './lib/fetch'

export async function createPaymentMethodSetupIntent(rechargeId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/payment-method/setup-intent`,
    { method: 'POST' }
  )
}

export async function savePaymentMethod(
  rechargeId: string,
  stripePaymentMethodId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/payment-method`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ stripe_payment_method_id: stripePaymentMethodId }),
    }
  )
}

export async function setActivePaymentMethod(
  rechargeId: string,
  paymentMethodId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/payment-method/set-active`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ id: paymentMethodId }),
    }
  )
}

export async function removePaymentMethod(
  rechargeId: string,
  paymentMethodId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/payment-method/${paymentMethodId}`,
    {
      method: 'DELETE',
    }
  )
}

export async function requestPaymentMethodEmail(
  rechargeId: string,
  paymentMethodId: string
) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/payment-method/${paymentMethodId}/request-email`,
    {
      method: 'POST',
    }
  )
}
