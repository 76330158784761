import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'

export interface Task {
  id: number
  name: string
  state: AsyncOperationState
}

let taskId = 1

export const useTaskStore = defineStore('task', {
  state: () => ({
    task: null as Task | null,
  }),
  actions: {
    setTask(name: string, state: AsyncOperationState) {
      this.task = { id: taskId++, name, state }
      return this.task.id
    },
    updateTask(id: number, name: string, state: AsyncOperationState) {
      if (this.task?.id === id) {
        this.task = { id, name, state }
        if (this.task.state === AsyncOperationState.DONE) {
          setTimeout(() => this.clearTask(id), 5000)
        }
      }
    },
    clearTask(id: number) {
      if (this.task?.id === id) {
        this.task = null
      }
    },
  },
})
