import { formatProductTitle } from '@/lib/formatters/product-title'
import Bugsnag from '@bugsnag/js'
import { defineStore } from 'pinia'
import { getOrderSchedule } from '../services/order'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { useProductImageStore } from './productImage'
import { useProductLinkStore } from './productLink'

export interface LineItem {
  purchase_item_id: string
  title: string
  variant_title?: string
  quantity: number
  total_price: string
  unit_price: string
  external_product_id: {
    ecommerce: string
  }
  external_variant_id: {
    ecommerce: string
  }
  images?: {
    small: string
    medium: string
    large: string
    original: string
  }
  purchase_item_type: 'subscription' | 'onetime'
}

export interface Discount {
  id: string
  code: string
  value: number
  value_type: string
}

export interface Order {
  id: string
  scheduled_at: string
  line_items: LineItem[]
  shipping_address: {
    address1: string
    address2?: string
    city: string
    company?: string
    country_code?: string
    first_name: string
    last_name: string
    phone?: string
    province?: string
    zip: string
  }
}

export const useFutureOrderStore = defineStore('futureOrder', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    orders: [] as Order[],
    stale: true as boolean,
  }),
  actions: {
    async init(rechargeId?: string) {
      if (
        (this.stale || this.loadState === AsyncOperationState.IDLE) &&
        rechargeId
      ) {
        await this.load(rechargeId)
      }
    },
    async load(rechargeId: string) {
      const productImageStore = useProductImageStore()
      const productLinkStore = useProductLinkStore()
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const response = await getOrderSchedule(rechargeId)
        const orders = response
        orders.forEach((order: Order) => {
          productImageStore.loadImagesForProducts(
            order.line_items.map(
              (li) =>
                `${li.external_product_id.ecommerce}:${li.external_variant_id.ecommerce}`
            )
          )
          productLinkStore.loadLinksForProducts(
            order.line_items.map((li) => `${li.external_product_id.ecommerce}`)
          )
          order.line_items.forEach((li) => {
            li.title = formatProductTitle(li.title)
          })
        })
        this.orders = orders
        this.loadState = AsyncOperationState.DONE
        this.stale = false
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
  },
})
