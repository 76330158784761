<script setup lang="ts">
import MinimalLayout from '@/components/layout/MinimalLayout.vue'

import { useAuthStore } from '@/stores/auth'
import { RouterLink } from 'vue-router'

const authStore = useAuthStore()
</script>

<template>
  <MinimalLayout align="center">
    <b>{{ authStore.user?.shopifyId }} </b> recharge user
    <b>{{ authStore.user?.rechargeId }} </b>!
    <ul>
      <li><RouterLink to="/logout">Logout</RouterLink></li>
      <li><RouterLink to="/notfound">Not found page</RouterLink></li>
      <li><RouterLink to="/style-guide">Style guide</RouterLink></li>
      <li><RouterLink to="/details">Your details</RouterLink></li>
    </ul>
  </MinimalLayout>
</template>
