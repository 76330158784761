<script setup lang="ts">
import { useRouter } from 'vue-router'

import Layout from '@/components/layout/Layout.vue'
import SubscriptionList from '@/components/SubscriptionList.vue'

import type { AuthenticatedEntity } from '@/stores/auth'
import { useSubscriptionStore } from '@/stores/subscription'
import { useProductImageStore } from '@/stores/productImage'
import { useBackLink } from './lib/backLink'

import { t } from '@/lib/locale'
import { ref, watch } from 'vue'

const router = useRouter()
const { backTo } = useBackLink()

const props = defineProps<{
  authedUser: AuthenticatedEntity
}>()

const edit = (id: string) => {
  router.push(`/subscriptions/${id}`)
}

const subscriptionStore = useSubscriptionStore()
subscriptionStore.init(props.authedUser.rechargeId)

const productImageStore = useProductImageStore()

const nextCharge = ref<string | undefined>()
watch(
  () => subscriptionStore.subscriptions,
  () => {
    nextCharge.value = subscriptionStore.subscriptions.reduce(
      (nearestDate: string | undefined, sub) => {
        if (!nearestDate) return sub.next_charge_scheduled_at
        if (!sub.next_charge_scheduled_at) return nearestDate
        if (sub.next_charge_scheduled_at < nearestDate) {
          return sub.next_charge_scheduled_at
        }
        return nearestDate
      },
      undefined
    )
  },
  { immediate: true }
)
</script>

<template>
  <Layout
    :title="t('your_subscriptions_title')"
    :description="t('your_subscriptions_description')"
    :back-link="{ text: t('your_account_back_cta') }"
    @back="backTo('/')"
  >
    <template #main>
      <div class="Stack">
        <SubscriptionList
          :title="t('your_subscriptions_active_title')"
          subscription-type="active"
          :load-state="subscriptionStore.loadState"
          :work-state="subscriptionStore.workState"
          :is-expanded="true"
          :subscriptions="subscriptionStore.activeSubscriptions"
          :images-by-product-id="productImageStore.productImages"
          :next-charge-date="nextCharge"
          @edit-item="edit($event)"
        >
        </SubscriptionList>
        <SubscriptionList
          :title="t('your_subscriptions_cancelled_title')"
          subscription-type="cancelled"
          :load-state="subscriptionStore.loadState"
          :work-state="subscriptionStore.workState"
          :is-expanded="false"
          :subscriptions="subscriptionStore.cancelledSubscriptions"
          :images-by-product-id="productImageStore.productImages"
          @edit-item="edit($event)"
        >
        </SubscriptionList>
      </div>
    </template>
  </Layout>
</template>

<style>
.Subscriptions__Loading {
  animation: opacity-fade 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes opacity-fade {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
</style>
