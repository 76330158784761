<script lang="ts" setup>
import { computed } from 'vue'
import IconLoading from '../assets/icons/loading-spinner.svg?component'

const props = defineProps<{
  href?: string
  modifiers?: string[]
  disabled?: boolean
  loading?: boolean
  extraAttributes?: Record<string, string>
}>()

const emit = defineEmits<{
  (e: 'click'): void
}>()

const type = computed(() => {
  if (props.href) {
    return 'a'
  } else {
    return 'button'
  }
})

const modifierClasses = computed(() =>
  (props.modifiers || [])
    .map((m) => `Button--${m}`)
    .concat(props.disabled ? ['Button--is-disabled'] : [])
)

const emitClick = function () {
  if (!props.loading && !props.disabled) emit('click')
}
</script>

<template>
  <component
    :is="type"
    :href="href"
    class="Button"
    :class="modifierClasses"
    :disabled="disabled"
    v-bind="extraAttributes"
    @click="emitClick"
  >
    <template v-if="!loading">
      <i v-if="$slots.iconBefore" class="Button__Icon Button__Icon--before">
        <slot name="iconBefore" />
      </i>
      <slot />
      <i v-if="$slots.iconAfter" class="Button__Icon Button__Icon--after">
        <slot name="iconAfter" />
      </i>
    </template>
    <template v-else>
      <i class="Button__Icon Button__Icon--before">
        <IconLoading />
      </i>
      <span>
        <slot />
      </span>
    </template>
  </component>
</template>

<style>
.Button {
  position: relative;
  appearance: none;
  outline: none;
  background-color: var(--color-ocean-100);
  border: solid 2px var(--color-ocean-100);
  font-size: var(--font-size--base);
  line-height: 1;
  font-family: var(--font-family-rund);
  color: var(--color-warm-white-100);
  border-radius: var(--radius-huge);
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 12px 20px;
  cursor: pointer;
  transition: color ease-in-out 0.15s, background-color ease-in-out 0.15s,
    border-color ease-in-out 0.15s;
  text-decoration: none;

  &.Icon {
    transition: all ease-in-out 0.15s;
  }

  &:focus-visible {
    box-shadow: 0 0 0 1px rgba(var(--rgb-white), 0.5),
      0 0 0 3px rgba(var(--rgb-ocean-100), 0.75);
    outline: none;
  }
}

.ButtonGroup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px 10px;
}

.Button:enabled:hover {
  text-decoration: none;
  background-color: var(--color-deep-ocean-100);
  border-color: var(--color-deep-ocean-100);
  color: var(--color-white);
}

.Button__Icon {
  width: 16px;
  height: auto;
}

.Button__Icon--before {
  margin: 0 8px 0 0;
}

.Button__Icon--after {
  margin: 0 0 0 8px;
}

.Button--card {
  display: flex;
  width: 100%;
  border-width: 0;
  border-radius: 0;
  min-height: 64px;

  &.Button.Button--inverted.Button--error {
    border-color: transparent;
  }

  & .Button__Icon {
    display: inline;
  }
  @media (--mq-tablet-and-up) {
    border-radius: var(--radius-large);
  }
}

.Button--block {
  display: flex;
  width: 100%;
}

.Button--link.Button--block {
  justify-content: center;
}

.Button--inverted {
  background-color: transparent;
  border-color: var(--color-ocean-100);
  color: var(--color-ocean-100);

  &:focus-visible {
    box-shadow: inset 0 0 0 2px var(--color-ocean-100), var(--box-shadow-focus);
  }

  &:enabled:hover {
    background-color: var(--color-lichen-100);
    border-color: var(--color-ocean-100);
    color: var(--color-ocean-100);
  }
}

.Button .Button__Icon--loading {
  width: 50px;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -10px;
}

.Button.Button--is-disabled {
  opacity: 0.65;
  cursor: not-allowed;
  background-color: var(--color-lichen-100);
  border-color: var(--color-lichen-100);
  color: var(--color-forest-100);
}

/* Link Variant */
.Button--link {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  line-height: var(--line-height--base);
  color: var(--color-ocean-100);
  text-align: left;
  justify-content: flex-start;

  &:enabled:hover,
  &:enabled:focus {
    text-decoration: underline;
    background-color: transparent;
    color: var(--color-deep-ocean-100);
  }
}

.Button.Button--link.Button--is-disabled {
  background-color: transparent;
}

/* Error Styles */
.Button.Button--error {
  background-color: var(--color-error-100);
  color: var(--color-white);
  border-color: transparent;

  & .Icon {
    color: var(--color-white);
  }
}

.Button.Button--warning {
  background-color: var(--color-warning-100);
  color: var(--color-white);
  border-color: transparent;

  & .Icon {
    color: var(--color-white);
  }
}

.Button.Button--success {
  background-color: var(--color-success-100);
  color: var(--color-white);
  border-color: transparent;

  & .Icon {
    color: var(--color-white);
  }
}

.Button.Button--inverted.Button--error {
  background-color: var(--color-white);
  color: var(--color-error-100);
  border-color: var(--color-error-100);
  border-width: 2px;
  border-style: solid;

  & .Icon {
    color: var(--color-error-100);
  }
}

.Button.Button--inverted.Button--warning {
  background-color: var(--color-white);
  color: var(--color-warning-100);
  border-color: var(--color-warning-100);

  & .Icon {
    color: var(--color-warning-100);
  }
}

.Button.Button--inverted.Button--success {
  background-color: var(--color-white);
  color: var(--color-success-100);
  border-color: var(--color-success-100);

  & .Icon {
    color: var(--color-success-100);
  }
}

.Button.Button--error:hover {
  background-color: var(--color-white);
  color: var(--color-error-100);
  border-color: var(--color-error-100);

  & .Icon {
    color: var(--color-error-100);
  }
}

.Button.Button--warning:hover {
  background-color: var(--color-white);
  color: var(--color-warning-100);
  border-color: var(--color-warning-100);

  & .Icon {
    color: var(--color-warning-100);
  }
}

.Button.Button--success:hover {
  background-color: var(--color-white);
  color: var(--color-success-100);
  border-color: var(--color-success-100);

  & .Icon {
    color: var(--color-success-100);
  }
}

.Button.Button--inverted.Button--error:hover {
  background-color: var(--color-lichen-100);
}

.Button.Button--inverted.Button--warning:hover {
  background-color: var(--color-lichen-100);
}

.Button.Button--inverted.Button--success:hover {
  background-color: var(--color-lichen-100);
}

/* Link Errors */
.Button.Button--link.Button--error {
  background-color: transparent;
  color: var(--color-error-100);
  border-color: transparent;
}

.Button.Button--link.Button--warning {
  background-color: transparent;
  color: var(--color-warning-100);
  border-color: transparent;
}

.Button.Button--link.Button--success {
  background-color: transparent;
  color: var(--color-success-100);
  border-color: transparent;
}
</style>
