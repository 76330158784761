interface RechargeProductSummary {
  products: {
    [key: string]: {
      subscription_options: {
        updated_at: string
        is_prepaid: boolean
        storefront_purchase_options: string
      }
      bundle_product: string | null
      external_product_id: number
      options: {
        values: {
          id: string | null
          position: number
          label: string
        }[]
        position: number
        id: number
        name: string
      }[]
      id: number
      selling_plan_groups: {
        selling_plans: {
          id: string | null
          order_interval_unit_type: string
          selling_plan_name: string
          price_adjustments_value_type: string
          price_adjustments_value: number
          order_interval_frequency: number
          selling_plan_id: number
        }[]
        selling_plan_group_id: number
        subscription_product_id: string | null
      }[]
      in_recharge: boolean
      variants: {
        prices: {
          compare_at_price: null
          discounted_price: string
          unit_price: string
          zero_decimal_currency: false
        }
        option_values: {
          id: number
          label: string
        }[]
        selling_plan_allocations: {
          discounted_price: string
          selling_plan_group_id: number
          selling_plan_id: number
        }[]
        id: number
      }[]
    }
  }[]
}

const getRechargeProductSummary = async (): Promise<RechargeProductSummary> => {
  return (
    await fetch(
      `https://static.rechargecdn.com/store/bower-collective.myshopify.com/product/2020-12/products.json`
    )
  ).json()
}

export const rechargeProductSummary = getRechargeProductSummary()

export async function getSellingPlan(
  productId: string,
  sellingPlanId?: string
) {
  const summary = await rechargeProductSummary
  const product = summary.products.find((p) => p[productId])
  if (!product) return null
  const sellingPlans = product[productId].selling_plan_groups.flatMap(
    (spg) => spg.selling_plans
  )
  return sellingPlans.find(
    (sp) => sp.selling_plan_id.toString() === sellingPlanId
  )
}
