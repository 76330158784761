import { getRecommendations } from '@/services/recommendation'
import Bugsnag from '@bugsnag/browser'
import { defineStore } from 'pinia'
import { AsyncOperationState } from './lib/AsyncOperationState'

const initialState = () => ({
  loadState: AsyncOperationState.IDLE,
  items: [] as RecommendationItem[],
  stale: true,
})

type CategoryKey = 'buy-again' | 'new'

export interface RecommendationItem {
  id: string
  title: string
  body_html: string
  vendor: string
  product_type: string
  created_at: string
  handle: string
  updated_at: string
  published_at: string
  template_suffix: string
  status: string
  published_scope: string
  tags: string
  variants: {
    id: string
    product_id: string
    title: string
    price: string
    sku: string
    position: number
    inventory_policy: string
    compare_at_price: string | null
    fulfillment_service: string
    inventory_management: string
    option1: string | null
    option2: string | null
    option3: string | null
    created_at: string
    updated_at: string
    taxable: boolean
    barcode: string
    grams: number
    image_id: string | null
    weight: number
    weight_unit: string
    inventory_item_id: string
    inventory_quantity: number
    old_inventory_quantity: number
    requires_shipping: boolean
    admin_graphql_api_id: string
    link: string
  }[]
  options: {
    id: string
    product_id: string
    name: string
    position: number
    values: string[]
  }[]
  images: {
    id: string
    product_id: string
    position: number
    created_at: string
    updated_at: string
    alt: string | null
    width: number
    height: number
    src: string
    variant_ids: string[]
    admin_graphql_api_id: string[]
    metafields: string[]
  }[]
  image: {
    id: string
    product_id: string
    position: number
    created_at: string
    updated_at: string
    alt: string | null
    width: number
    height: number
    src: string
    variant_ids: string[]
    admin_graphql_api_id: string[]
    metafields: string[]
  }
}

export const useRecommendationStore = defineStore('recommendation', {
  state: () => ({
    'buy-again': initialState(),
    new: initialState(),
  }),
  actions: {
    async init(
      shopifyId: string,
      category: CategoryKey,
      meta?: Record<string, string>
    ) {
      if (
        (this[category].stale ||
          this[category].loadState === AsyncOperationState.IDLE) &&
        shopifyId
      ) {
        await this.load(shopifyId, category, meta)
      }
    },
    async load(
      shopifyId: string,
      category: CategoryKey,
      meta?: Record<string, string>
    ) {
      this[category].loadState = AsyncOperationState.IN_PROGRESS
      try {
        this[category].items = await getRecommendations(
          shopifyId,
          category,
          meta
        )
        this[category].loadState = AsyncOperationState.DONE
        this[category].stale = false
      } catch (e) {
        Bugsnag.notify(e as Error)
        this[category].loadState = AsyncOperationState.ERRORED
      }
    },
  },
})
