<script lang="ts" setup>
import { useAuthStore } from '@/stores/auth'
import Loading from '@/components/Loading.vue'
const authStore = useAuthStore()
authStore.logout().then(() => {
  window.location.href = 'https://bowercollective.com/account/logout'
})
</script>

<template>
  <div class="AppLoadingState">
    <Loading />
  </div>
</template>
