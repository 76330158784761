import fetch from './lib/fetch'
import type { AddressField } from '../stores/subscriptionCustomer'

export async function getCustomer(rechargeId: string) {
  return await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/customer`
  )
}

export async function saveCustomerDetails(
  rechargeId: string,
  details: {
    first_name: string
    last_name: string
    address: Record<AddressField, string>
  }
) {
  await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/protected/subscriptions/${rechargeId}/customer/details`,
    {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(details),
    }
  )
}
