<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps<{
  value: number
  maxValue?: number
  minValue?: number
  isBusy: boolean
  debounceDelay?: number
}>()

const emit = defineEmits<{
  (e: 'changeQuantity', quantity: number): void
}>()

const maxValue = props.maxValue || 10
const minValue = props.minValue !== undefined ? props.minValue : 1

const showingValue = ref(props.value)

const debounce = (fn: () => void, delay: number) => {
  let t: number | undefined = undefined
  return () => {
    clearTimeout(t)
    t = setTimeout(fn, delay) as unknown as number
  }
}

const updateQuantity = debounce(() => {
  emit('changeQuantity', showingValue.value)
}, props.debounceDelay || 500)

const decrease = () => {
  showingValue.value = showingValue.value - 1
  updateQuantity()
}

const increase = () => {
  showingValue.value = showingValue.value + 1
  updateQuantity()
}

watch(
  () => props.value,
  () => (showingValue.value = props.value)
)
</script>

<template>
  <span class="QuantityControl">
    <button
      class="QuantityControl__Button QuantityControl__Decrease"
      :disabled="isBusy || showingValue <= minValue"
      @click="decrease"
    >
      -
    </button>
    <span class="QuantityControl__Value">{{ showingValue }}</span>
    <button
      class="QuantityControl__Button QuantityControl__Increase"
      :disabled="isBusy || showingValue >= maxValue"
      @click="increase"
    >
      +
    </button>
  </span>
</template>

<style>
.QuantityControl {
  display: inline-block;
  background-color: #fff;
  border: 1px solid var(--color-lichen-150);
  border-radius: var(--radius-small);
  color: var(--color-forest-100);
}

.QuantityControl__Button {
  background: transparent;
  border: none;
  color: var(--color-forest-100);
  font-family: var(--font-family-rund);
  line-height: var(--line-height--base);
  font-size: var(--font-size--base);
  padding: 6px 8px;
  margin: 0;

  &:disabled {
    opacity: 0.5;
  }
}

.QuantityControl__Value {
  display: inline-block;
  min-width: 20px;
  text-align: center;
  padding: 0 5px;
  line-height: var(--line-height--base);
}
</style>
