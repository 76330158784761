<script lang="ts" setup>
import { useAuthStore } from '../stores/auth'
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import ErrorMessage from '../components/ErrorMessage.vue'
import Loading from '../components/Loading.vue'

import { t } from '../lib/locale'

const hasError = ref(false)
const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()

const redirectTo = route.query.redirect_to as string

authStore
  .authenticate(route.query.token as string | undefined)
  .then(() => {
    if (authStore.isAuthenticated) {
      router.replace(redirectTo ? redirectTo : '/')
    } else {
      hasError.value = true
    }
  })
  .catch(() => {
    hasError.value = true
  })
</script>

<template>
  <div v-if="!hasError" class="AppLoadingState">
    <Loading />
  </div>
  <div v-else>
    <ErrorMessage>
      <template #title>{{ t('login_error_title') }}</template>
      {{ t('login_error_copy') }}
    </ErrorMessage>
  </div>
</template>
