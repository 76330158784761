<script setup lang="ts">
import { NavSettings, Nav } from '@/stores/navigation'
import IconChevron from '@/assets/icons/chevron-small.svg?component'
import IconInbox from '@/assets/icons/email-inbox.svg?component'
import IconPhone from '@/assets/icons/phone.svg?component'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import NavFeaturedProduct from '@/components/NavFeaturedProduct.vue'

const props = defineProps<{
  navStructure: Nav
  settings: NavSettings
}>()

const baseMainSiteUrl = (url: string) => {
  return new URL(url, 'https://bowercollective.com').toString()
}

const selectedSection = ref<Nav[number] | null>(null)
const menuY = computed(() => {
  if (!rootEl.value || !selectedSection.value) return 0
  const rect = rootEl.value.getBoundingClientRect()
  return rect.top + rect.height
})

const showMenuFor = (navItem: Nav[number]) => {
  selectedSection.value = navItem
}

const navSettings = computed(() => {
  const s = selectedSection.value
  if (!s) return null
  return props.settings.sections.find(
    (section) =>
      s.title.trim().toLowerCase() === section.name.trim().toLowerCase()
  )
})

const rootEl = ref<HTMLElement | null>(null)
const menuEl = ref<HTMLElement | null>(null)

const onDocumentClick = (event: Event) => {
  if (!menuEl.value) return

  if (!menuEl.value.contains(event.target as Node)) {
    selectedSection.value = null
  }
}
onMounted(() => {
  document.addEventListener('click', onDocumentClick)
})
onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick)
})
</script>

<template>
  <nav class="MainNavigationDesktop" ref="rootEl">
    <ul class="MainNavigationDesktop__TopLevelList">
      <li v-for="navItem in navStructure" :key="navItem.title">
        <a
          class="MainNavigationDesktop__TopLevelList__Item"
          v-if="
            navItem.url && navItem.url !== '#' && navItem.children.length === 0
          "
          :href="baseMainSiteUrl(navItem.url)"
        >
          <span>{{ navItem.title }}</span>
        </a>
        <span
          class="MainNavigationDesktop__TopLevelList__Item"
          v-else
          @click.stop="showMenuFor(navItem)"
        >
          <span>{{ navItem.title }}</span>
          <IconChevron
            class="MainNavigationDesktop__TopLevelList__Item__Chevron"
          />
        </span>
      </li>
    </ul>
    <Teleport to="body">
      <Transition name="MainNavigationDesktop__Menu">
        <div
          class="MainNavigationDesktop__Menu"
          :style="`top:${menuY}px`"
          v-if="selectedSection"
        >
          <div class="MainNavigationDesktop__Menu__Inner" ref="menuEl">
            <template
              v-if="selectedSection.title.trim().toLowerCase() === 'shop'"
            >
              <div class="MainNavigationDesktop__Menu__ColLayout">
                <div
                  class="MainNavigationDesktop__Menu__Col"
                  v-for="child in selectedSection.children"
                  :key="child.title"
                >
                  <div class="MainNavigationDesktop__Menu__NavList">
                    <p>
                      <strong>{{ child.title }}</strong>
                    </p>
                    <div
                      class="MainNavigationDesktop__Menu__NavList__Container"
                    >
                      <ul>
                        <li
                          v-for="menuItem in child.children"
                          :key="menuItem.title"
                        >
                          <a :href="baseMainSiteUrl(menuItem.url)">{{
                            menuItem.title
                          }}</a>
                        </li>
                      </ul>
                    </div>
                    <p class="MainNavigationDesktop__Menu__All">
                      <a :href="baseMainSiteUrl(child.url)">
                        All {{ child.title.toLowerCase() }} →
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  v-if="settings.featuredProduct"
                  class="MainNavigationDesktop__Menu__Col MainNavigationDesktop__Menu__Col--no-grow"
                >
                  <NavFeaturedProduct
                    v-if="settings.featuredProduct"
                    :product="settings.featuredProduct"
                    :label="settings.featuredProductLabel"
                    version="single-col"
                  >
                  </NavFeaturedProduct>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="MainNavigationDesktop__Menu__ColLayout">
                <div
                  class="MainNavigationDesktop__Menu__Col MainNavigationDesktop__Menu__Col--bg"
                  v-if="navSettings"
                >
                  <h3>
                    {{ navSettings.text }}
                  </h3>
                  <p v-if="navSettings.cta_target && navSettings.cta_text">
                    <a :href="baseMainSiteUrl(navSettings.cta_target)">
                      {{ navSettings.cta_text }}
                    </a>
                  </p>
                </div>
                <div class="MainNavigationDesktop__Menu__Col">
                  <div class="MainNavigationDesktop__Menu__NavList">
                    <p>
                      <strong>{{ selectedSection.title }}</strong>
                    </p>
                    <div
                      class="MainNavigationDesktop__Menu__NavList__Container"
                    >
                      <ul
                        :class="{
                          'has-columns':
                            selectedSection.title.trim().toLowerCase() ===
                            'starter kits',
                        }"
                      >
                        <li
                          v-for="menuItem in selectedSection.children"
                          :key="menuItem.title"
                        >
                          <a :href="baseMainSiteUrl(menuItem.url)">{{
                            menuItem.title
                          }}</a>
                        </li>
                      </ul>
                    </div>
                    <p
                      class="MainNavigationDesktop__Menu__All"
                      v-if="selectedSection.url && selectedSection.url !== '#'"
                    >
                      <a :href="baseMainSiteUrl(selectedSection.url)">
                        All {{ selectedSection.title.toLowerCase() }} →
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  class="MainNavigationDesktop__Menu__Col"
                  v-if="
                    selectedSection.title.trim().toLowerCase() === 'about us'
                  "
                >
                  <div class="MainNavigationDesktop__AboutUs">
                    <p>
                      <strong>{{ settings.aboutUsHeading }}</strong>
                    </p>
                    <p>{{ settings.aboutUsText }}</p>
                    <p>
                      <IconInbox />
                      <a href="mailto:hello@bowercollective.com">
                        hello@bowercollective.com
                      </a>
                    </p>
                    <p>
                      <IconPhone />
                      <a href="tel:+441225435180">01225 435180</a>
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </Transition>
    </Teleport>
  </nav>
</template>

<style>
.MainNavigationDesktop {
  display: none;
  flex-grow: 1;

  @media (--mq-lap-and-up) {
    display: block;
  }
}

.MainNavigationDesktop__TopLevelList {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.MainNavigationDesktop__TopLevelList__Item {
  color: var(--color-forest-100);
  text-decoration: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 1000px;
  transition: background-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: var(--color-lichen-100);
    text-decoration: none;
  }

  &.is-active {
    background-color: var(--color-lichen-150);
  }

  .MainNavigationDesktop__TopLevelList > :first-child & {
    background-color: var(--color-white);

    &:hover {
      background-color: var(--color-lichen-100);
    }
  }
}

.MainNavigationDesktop__TopLevelList__Item__Chevron {
  position: relative;
  top: 1px;
}

.MainNavigationDesktop__Menu {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5;
}

.MainNavigationDesktop__Menu__Inner {
  margin-top: 16px;
  width: 95%;
  max-width: 1144px;
  background-color: var(--color-white);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 20%);
  border-radius: var(--radius-large);
  overflow: hidden;
}

.MainNavigationDesktop__Menu__ColLayout {
  display: flex;
  justify-content: stretch;
  gap: 32px;
}

.MainNavigationDesktop__Menu__Col {
  padding: 32px 0 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    padding-right: 32px;
  }
}

.MainNavigationDesktop__Menu__Col--no-grow {
  flex-grow: 0;
}

.MainNavigationDesktop__Menu__Col--bg {
  background-color: var(--color-lichen-25);
  padding: 32px;
  width: 35%;
  flex-shrink: 0;
  flex-grow: 0;
}

.MainNavigationDesktop__Menu__NavList {
  height: 100%;
  display: flex;
  flex-direction: column;

  & > p {
    margin: 0;
  }
}

.MainNavigationDesktop__Menu__NavList__Container {
  flex-grow: 1;

  & ul {
    list-style: none;
    padding: 0 0 var(--grid-gap);
    margin: 0;

    & > li {
      margin: 8px 0;

      & > a {
        color: var(--color-forest-100);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.has-columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      margin-top: 8px;

      & > li {
        margin: 0;
      }
    }
  }
}

.MainNavigationDesktop__Menu__All {
  border-top: 1px solid var(--color-lichen-100);
  padding: 8px 0;

  & > a {
    color: var(--color-forest-100);
  }
}

.MainNavigationDesktop__AboutUs {
  & > p:first-child {
    margin: 0;
  }

  & > p:nth-child(2) {
    margin-top: 8px;
  }

  & p {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.MainNavigationDesktop__Menu-enter-active,
.MainNavigationDesktop__Menu-leave-active {
  transition: opacity 0.15s ease-in-out;
}

.MainNavigationDesktop__Menu-enter-from,
.MainNavigationDesktop__Menu-leave-to {
  opacity: 0;
}
</style>
