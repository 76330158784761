<script lang="ts" setup>
import type { Order } from '@/stores/nextOrder'
import IconStatusError from '../assets/icons/status-error.svg?component'
import IconStatusSuccess from '../assets/icons/status-success.svg?component'
import Message from './Message.vue'
import { formatDate } from '@/lib/formatters/date'

const props = defineProps<{
  nextOrder: Order
}>()

const pastOrderMessage = "We couldn't process your most recent order"
const presentOrderMessage = "We're having trouble processing your order."

const retryMessage = (date: string) => {
  return `Your failed order will automatically be retried on ${formatDate(
    'DD Month',
    new Date(date)
  )}.`
}

const helpMessage = `If you would like any help with this issue, please get in touch with us at <a href="mailto:hello@bowercollective.com">hello@bowercollective.com</a>`

let title: string
let message: string
let showUpdateDetailsPrompt = false
let showRetryMessage = true
switch (props.nextOrder.error_type) {
  case 'CARD_DECLINED':
  case 'CARDNUMBER_INCORRECT':
    title = pastOrderMessage
    message = 'Your card was declined.'
    showUpdateDetailsPrompt = true
    break
  case 'INSUFFICIENT_FUNDS':
    title = pastOrderMessage
    message = 'Your payment method had insufficient funds.'
    showUpdateDetailsPrompt = true
    break
  case 'CARD_EXPIRED':
    title = pastOrderMessage
    message = 'Your payment method has expired.'
    showUpdateDetailsPrompt = true
    showRetryMessage = false
    break
  case 'INSUFFICIENT_INVENTORY':
    title = pastOrderMessage
    message = 'One or more items on your order are out of stock.'
    break
  case 'CLOSED_MAX_RETRIES_REACHED':
    title = pastOrderMessage
    message = 'We retried multiple times.'
    showRetryMessage = false
    break
  default:
    title = presentOrderMessage
}
</script>

<template>
  <div class="ChargeErrorBanner">
    <Message
      mode="success"
      v-if="nextOrder.error_type === 'CARD_UPDATED_NOW_PENDING_NEXT_ATTEMPT'"
    >
      <template #icon><IconStatusSuccess /></template>
      <p>
        <strong>Thanks for updating your payment details.</strong>
      </p>
      <p v-if="nextOrder.retry_date">
        {{ retryMessage(nextOrder.retry_date) }}
      </p>
    </Message>
    <Message mode="error" v-else>
      <template #icon><IconStatusError /></template>
      <p>
        <strong>{{ title }}</strong>
      </p>
      <p v-if="message">{{ message }}</p>
      <p v-if="showUpdateDetailsPrompt">
        Please check
        <a href="/details" @click.prevent="$router.push('/details')">
          your payment details
        </a>
        and ensure they are up to date. Once you have updated your details, your
        order will be processed the next day.
      </p>
      <p v-if="nextOrder.retry_date && showRetryMessage">
        {{ retryMessage(nextOrder.retry_date) }}
      </p>
      <p v-html="helpMessage"></p>
    </Message>
  </div>
</template>

<style>
.ChargeErrorBanner {
  margin: 0 24px var(--stack-default);
  @media (--mq-tablet-and-up) {
    margin: 0 0 var(--stack-default);
  }
}
</style>
