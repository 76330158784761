<script lang="ts" setup>
import { ref, watch } from 'vue'

import type { Onetime } from '@/stores/onetime'
import type { Subscription } from '@/stores/subscription'
import type { ImageMap } from '@/stores/productImage'

import ProductCard from '@/components/ProductCard.vue'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import PurchaseItemSubscription from '@/components/PurchaseItemSubscription.vue'
import PurchaseItemOnetime from '@/components/PurchaseItemOnetime.vue'
import SubscriptionIcon from '../assets/icons/subscription.svg?component'
import CancellationModal from '@/components/CancellationModal.vue'

import { t } from '@/lib/locale'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import type { useCancellationInterventionStore } from '@/stores/cancellationIntervention'

const props = defineProps<{
  item: Subscription | Onetime | null
  workState: AsyncOperationState
  inNextOrder: boolean
  productImages: Record<string, ImageMap[] | undefined>
  link?: string
  tempItem?: {
    title: string
    productId: string
    variantId: string
  }
  nextOrderDate?: string
  cancellationInterventionStore: ReturnType<
    typeof useCancellationInterventionStore
  >
  track: (event: string, data?: Record<string, unknown>) => void
}>()

const emit = defineEmits<{
  (e: 'save', update: { quantity: number; frequency: number }): void
  (e: 'save-onetime', update: { quantity: number }): void
  (e: 'cancel', reason: string, comments?: string): void
  (e: 'add-to-next-order'): void
  (e: 'reactivate'): void
  (e: 'skip'): void
  (
    e: 'cancel-intervention-swap',
    itemId: string,
    newExternalProductId: string
  ): void
  (e: 'cancel-intervention-add-to-next-order', itemId: string): void
  (e: 'cancel-intervention-delay', itemId: string, until: string): void
}>()

const imageForProduct = (id: string) => {
  const images = props.productImages[id]
  if (images) {
    return images[0].medium
  } else {
    return undefined
  }
}

const confirmingCancel = ref(false)
props.cancellationInterventionStore.reset()
watch(
  () => props.item,
  async () => {
    if (props.item && 'order_interval_frequency' in props.item) {
      await props.cancellationInterventionStore.loadAlternativeProducts(
        props.item.id
      )
    }
  },
  { immediate: true }
)
</script>

<template>
  <div class="Stack">
    <template v-if="item">
      <template v-if="'order_interval_frequency' in item">
        <PurchaseItemSubscription
          :item="item"
          :link="link"
          :in-next-order="inNextOrder"
          :product-images="productImages"
          :is-working="workState === AsyncOperationState.IN_PROGRESS"
          @save="emit('save', $event)"
          @cancel="confirmingCancel = true"
          @add-to-next-order="emit('add-to-next-order')"
          @reactivate="emit('reactivate')"
          @skip="emit('skip')"
        />
        <Teleport to="body">
          <CancellationModal
            v-if="confirmingCancel"
            :work-state="workState"
            :alternative-products-load-state="
              cancellationInterventionStore.loadState
            "
            :alternative-products="
              cancellationInterventionStore.alternativeProducts
            "
            :intervention-work-state="cancellationInterventionStore.workState"
            :item="item"
            :in-next-order="inNextOrder"
            :track="track"
            @cancel="(reason: string, comments?: string) => emit('cancel', reason, comments)"
            @clear="confirmingCancel = false"
            @swap="
              (event) => {
                if (item) emit('cancel-intervention-swap', item.id, event)
              }
            "
            @add-to-next-order="
              () => {
                if (item) emit('cancel-intervention-add-to-next-order', item.id)
              }
            "
            @delay="
              (event) => {
                if (item) emit('cancel-intervention-delay', item.id, event)
              }
            "
          >
          </CancellationModal>
        </Teleport>
      </template>
      <template v-else>
        <PurchaseItemOnetime
          :item="item"
          :link="link"
          :in-next-order="inNextOrder"
          :is-working="workState === AsyncOperationState.IN_PROGRESS"
          :product-images="productImages"
          @cancel="confirmingCancel = true"
          @save="emit('save-onetime', $event)"
        />
        <Teleport to="body">
          <Modal
            v-if="confirmingCancel"
            :title="t('purchase_item_cancel_onetime_modal_title')"
            @close="confirmingCancel = false"
          >
            <slot>
              <p>
                {{ t('purchase_item_cancel_onetime_modal_copy') }}
              </p>
              <div className="ButtonGroup">
                <Button
                  :modifiers="['error', 'inverted']"
                  @click="
                    () => {
                      emit('cancel', 'onetime')
                      confirmingCancel = false
                    }
                  "
                >
                  {{ t('purchase_item_cancel_onetime_modal_confirm_cta') }}
                </Button>
                <Button
                  :modifiers="['inverted']"
                  @click="confirmingCancel = false"
                >
                  {{ t('purchase_item_cancel_onetime_modal_cancel_cta') }}
                </Button>
              </div>
            </slot>
          </Modal>
        </Teleport>
      </template>
    </template>
    <template v-else-if="tempItem">
      <ProductCard
        class="Card"
        :title="tempItem.title"
        :image-url="
          imageForProduct(`${tempItem.productId}:${tempItem.variantId}`)
        "
        :label="{
          text: t('purchase_item_in_next_order_label'),
          Icon: SubscriptionIcon,
        }"
      />
    </template>
    <div v-else>
      <ProductCard class="Card" :loading="true" title="Loading" />
    </div>
  </div>
</template>
