<script lang="ts" setup>
import type { Nav, NavSettings } from '@/stores/navigation'
import { inject, onMounted, onUnmounted, type Ref, ref } from 'vue'
import LogoFull from '../../assets/logo-full.svg?component'
import IconClose from '../../assets/icons/close.svg?component'
import IconMenu from '../icons/IconMenu.vue'
import IconSearch from '../icons/IconSearch.vue'
import IconBasket from '../icons/IconBasket.vue'
import IconAccount from '../icons/IconAccount.vue'
import { watch } from 'vue'
import { t } from '../../lib/locale'
import { usePageOverlay } from '@/lib/pageOverlay'
import NavDesktop from '@/components/NavDesktop.vue'
import NavMobile from '@/components/NavMobile.vue'
import { useSubscriptionCustomerStore } from '@/stores/subscriptionCustomer'
import { useAuthStore } from '@/stores/auth'

const subscriptionCustomerStore = useSubscriptionCustomerStore()
const authStore = useAuthStore()

const nav = inject<Ref<Nav>>('MAIN_NAV')
const mobileNav = inject<Ref<Nav>>('MAIN_NAV_MOBILE')
const navSettings = inject<Ref<NavSettings>>('NAV_SETTINGS')

const baseMainSiteUrl = (url: string) => {
  return new URL(url, 'https://bowercollective.com').toString()
}

const mobileMenuIsOpen = ref(false)
const activeSubNavIndex = ref<number | null>(null)
const activeFloatingNavIndex = ref<number | null>(null)
const searchFormIsOpen = ref(false)

const closeMobileNav = (e: Event) => {
  if (
    mobileNavEl.value &&
    e.target &&
    !mobileNavEl.value.contains(e.target as Node) &&
    mobileNavButtonEl.value !== e.target
  ) {
    mobileMenuIsOpen.value = false
    activeSubNavIndex.value = null
  }
}

const closeSearchForm = (e: Event) => {
  if (
    SearchFormEl.value &&
    e.target &&
    !SearchFormEl.value.contains(e.target as Node) &&
    SearchFormButtonEl.value !== e.target
  ) {
    searchFormIsOpen.value = false
  }
}

const onKeyupNavOverlay = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    mobileMenuIsOpen.value = false
    activeSubNavIndex.value = null
  }
}
const onKeyupSearchOverlay = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    searchFormIsOpen.value = false
  }
}
const { show: showNavOverlay, hide: hideNavOverlay } = usePageOverlay(
  closeMobileNav,
  onKeyupNavOverlay
)
const { show: showSearchOverlay, hide: hideSearchOverlay } = usePageOverlay(
  closeSearchForm,
  onKeyupSearchOverlay
)

watch(mobileMenuIsOpen, (isOpen) => {
  if (isOpen) {
    showNavOverlay()
  } else {
    hideNavOverlay()
  }
})
watch(searchFormIsOpen, (isOpen) => {
  if (isOpen) {
    showSearchOverlay()
  } else {
    hideSearchOverlay()
  }
})

const closeDesktopNav = (e: Event) => {
  if (
    desktopNavEl.value &&
    e.target &&
    !desktopNavEl.value.contains(e.target as Node)
  ) {
    activeFloatingNavIndex.value = null
  }
}

const onKeyupFloatingNav = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    activeFloatingNavIndex.value = null
  }
}

watch(activeFloatingNavIndex, (newValue) => {
  if (newValue !== null) {
    document.body.addEventListener('click', closeDesktopNav)
    document.addEventListener('keyup', onKeyupFloatingNav)
  } else {
    document.body.removeEventListener('click', closeDesktopNav)
    document.removeEventListener('keyup', onKeyupFloatingNav)
  }
})

const mobileNavEl = ref<Element | null>(null)
const mobileNavButtonEl = ref<Element | null>(null)
const desktopNavEl = ref<Element | null>(null)
const SearchFormEl = ref<Element | null>(null)
const SearchFormButtonEl = ref<Element | null>(null)
const accountPopoverEl = ref<Element | null>(null)

const showingAccountPopover = ref(false)

const docClick = (e: Event) => {
  if (
    accountPopoverEl.value &&
    !accountPopoverEl.value.contains(e.target as Node)
  ) {
    showingAccountPopover.value = false
  }
}

onMounted(() => document.addEventListener('click', docClick))
onUnmounted(() => document.removeEventListener('click', docClick))
</script>

<template>
  <header class="Header" role="banner">
    <div class="Container">
      <div class="Header__Container">
        <a href="https://bowercollective.com/" class="Header__Logo">
          <LogoFull />
        </a>

        <NavDesktop
          v-if="nav && navSettings"
          :nav-structure="nav"
          :settings="navSettings"
        ></NavDesktop>

        <div class="Header__Actions">
          <a
            class="Header__AccountLink"
            href="/"
            @click.prevent.stop="showingAccountPopover = true"
          >
            <IconAccount />
          </a>
          <div
            v-if="authStore.user && showingAccountPopover"
            class="Header__AccountPopover"
            ref="accountPopoverEl"
          >
            <p>
              <strong v-if="subscriptionCustomerStore.customer">
                Welcome back,
                {{ subscriptionCustomerStore.customer.first_name }}!
              </strong>
              <strong v-else>Welcome back!</strong>
            </p>
            <ul>
              <li><a href="/">Your account</a></li>
              <li><a href="/logout">Logout</a></li>
            </ul>
          </div>
          <button
            class="Header__SearchToggle"
            :class="{ 'Header__SearchToggle--is-open': searchFormIsOpen }"
            :aria-expanded="searchFormIsOpen ? 'true' : 'false'"
            aria-label="Open Search"
            @click="searchFormIsOpen = true"
            ref="SearchFormButtonEl"
          >
            <IconSearch />
          </button>

          <a class="Header__CartLink" href="https://bowercollective.com/cart">
            <IconBasket />
          </a>
        </div>

        <button
          class="Header__MobileMenuToggle"
          aria-label="Open navigation"
          ref="mobileNavButtonEl"
          data-show-mobile-nav
        >
          <IconMenu />
        </button>
      </div>
    </div>

    <div
      class="SearchForm"
      :class="{ 'SearchForm--is-open': searchFormIsOpen }"
      :aria-hidden="searchFormIsOpen ? 'false' : 'true'"
      ref="SearchFormEl"
    >
      <form
        :action="baseMainSiteUrl('/pages/search-results-page')"
        method="get"
        role="search"
      >
        <label for="q" class="SearchForm__Label sr-only"
          >Search household and personal care products</label
        >
        <input
          type="search"
          name="q"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="none"
          autofocus
          placeholder="Search for products…"
          class="SearchForm__Input"
        />
        <button
          class="SearchForm__Buton SearchForm__Submit"
          type="submit"
          aria-label="Search"
        >
          {{ t('search_cta') }}
        </button>
      </form>
      <button
        class="SearchForm__Buton SearchForm__Close"
        aria-label="Close search form"
        @click="searchFormIsOpen = false"
      >
        <IconClose />
      </button>
    </div>

    <NavMobile
      v-if="mobileNav && navSettings"
      :nav-structure="mobileNav"
      :settings="navSettings"
    ></NavMobile>
  </header>
</template>

<style>
.Header {
  text-align: center;
  padding: 16px 0 var(--grid-gap);
  position: relative;
}

.Header .Container,
.Header__Container {
  position: relative;
}

.Header__Container {
  z-index: 3;
}

.Header__Logo {
  width: 110px;
  margin: 0 auto;
  display: block;
  color: var(--color-forest-100);

  & svg {
    width: 100%;
    height: auto;
  }
}

.Header__AccountLink {
  font-family: var(--font-family-domaine);
  font-size: var(--font-size--h5);
  font-weight: 600;
  line-height: 1;
}

.Header__AccountLinkText .Icon {
  width: 9px;
  height: auto;
  margin-left: 5px;
}

.Header__MobileMenuToggle,
.Header__SearchToggle,
.Header__CartLink,
.Header__AccountLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  color: var(--color-forest-100);
  cursor: pointer;
  transition: all var(--global-transition);
  margin: 0;
  padding: 0;
  border-radius: var(--radius-small);
  position: relative;
  text-decoration: none;

  & .Icon {
    width: 36px;
    height: 36px;
    pointer-events: none;
    position: relative;
    top: -1px;
  }

  &:hover {
    text-decoration: none;
    background-color: #ecf4f4;
  }

  &:active {
    text-decoration: none;
  }
}

.Header__CartCount {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: auto;
  min-width: 13px;
  height: 13px;
  background-color: var(--color-forest-100);
  color: var(--color-white);
  border-radius: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  transition: background-color var(--global-transition);

  &.is-visible {
    display: flex;
  }
}

.Header__CartLink:hover .Header__CartCount {
  background-color: var(--color-ocean-100);
}

/* Focus */
.Header__MobileMenuToggle,
.Header__SearchToggle,
.Header__CartLink,
.Header__AccountLink,
.MainMenu__Link,
.Header__Logo {
  &:focus-visible {
    box-shadow: 0 0 0 1px #fff, 0 0 0 2.5px #ffc702;
    outline: 0;
  }
}

/* Mobile Layout */
@media (--mq-lap-down) {
  .Header__MobileMenuToggle,
  .Header__SearchToggle,
  .Header__CartLink,
  .Header__AccountLink {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .Header__MobileMenuToggle {
    left: 0;
  }

  .Header__SearchToggle {
    left: 34px;
  }

  .Header__SearchToggleText {
    display: none;
  }

  .Header__AccountLink {
    right: 36px;
  }

  .Header__CartLink {
    right: 0;
  }
}

/* Desktop Layout */
@media (--mq-lap-and-up) {
  .Header {
    padding: 40px 0;
  }

  .Header__Logo {
    width: 130px;
    margin: 0 19px 0 0;
  }

  .Header__Container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .Header__Actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }

  .Header__SearchToggle,
  .Header__CartLink,
  .Header__AccountLink {
    margin-left: 16px;
  }

  .Header__MobileMenuToggle {
    display: none;
  }

  .Header__AccountLink .Header__AccountLinkText {
    display: block;
    white-space: nowrap;
  }
}

@media (--mq-lap-and-up) {
  .Header__Logo {
    width: 160px;
    margin: 0 30px 0 0;
  }
}

@media (--mq-desk) {
  .Header__Logo {
    margin: 0 70px 0 0;
  }
}

.SearchForm {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 10;
  width: 100%;
  height: auto;
  background-color: var(--color-white);
  padding: 14px 24px;
  text-align: left;
  box-shadow: 2px 0 10px var(--color-shadow-30),
    -2px 0 10px var(--color-shadow-30);
  opacity: 0;
  visibility: hidden;
  transition: all var(--global-transition);

  @media (--mq-tablet-and-up) {
    top: 80px;
  }

  @media (--mq-landscape-tablet-and-up) {
    top: 140px;
    padding: var(--grid-gap);
    border-radius: var(--radius-small);
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    max-width: 1144px;
  }
}

.SearchForm form {
  margin-right: 54px;
  position: relative;
}

.SearchForm--is-open {
  opacity: 1;
  visibility: visible;
}

input[type='search'].SearchForm__Input {
  font-size: var(--font-size--base);
  line-height: 1;
  border: 0;
  max-width: none;
  width: 100%;
  padding: 0 40px 0 0;
  transition: all var(--global-transition);
  outline: 0;

  &:focus {
    outline: 0;
  }

  @media (--mq-tablet-and-up) {
    font-family: var(--font-family-domaine);
    font-size: var(--font-size--h4);
    line-height: var(--line-heigh--h4);
  }
}

.SearchForm__Buton {
  border: 0;
  background-color: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-forest-100);
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  border-radius: var(--radius-small);
  transition: all var(--global-transition);

  & .Icon {
    width: 18px;
    height: 18px;
    transition: all var(--global-transition);
  }

  &:hover {
    color: var(--color-forest-100);
    background-color: var(--color-lichen-150);
  }
}

.SearchForm__Close {
  top: 50%;
  right: 24px;
  width: 32px;
  height: 32px;
}

.SearchForm__Submit {
  right: 0;
  top: 50%;
  font-family: var(--font-family-domaine);
  font-size: var(--font-size--h4);
  line-height: var(--line-heigh--h4);
  width: auto;
  height: auto;
}

.Header__AccountPopover {
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: var(--radius-large);
  background: var(--color-white);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 20%);
  z-index: 100;
  width: 280px;
  padding: 12px 16px;
  text-align: left;

  & p {
    margin-bottom: 8px;
  }

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li a {
    color: var(--color-forest-100);
  }
}
</style>
