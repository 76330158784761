<script lang="ts" setup>
import type { PaymentMethod } from '@/stores/subscriptionCustomer'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Label from '@/components/Label.vue'
import CardAmex from '../assets/images/card-amex.png'
import CardVisa from '../assets/images/card-visa.png'
import CardShopPay from '../assets/images/card-shop-pay.png'
import CardMastercard from '../assets/images/card-mastercard.png'
import CardApplePay from '../assets/images/card-applePay.png'
import CardGooglePay from '../assets/images/card-googlePay.png'
import CardGeneral from '../assets/images/card-general.png'
import IconArrow from './icons/IconArrow.vue'
import ErrorMessage from './ErrorMessage.vue'
import { ref } from 'vue'

import { t } from '../lib/locale'

defineProps<{
  isBusy: boolean
  paymentMethods: PaymentMethod[]
}>()

defineEmits<{
  (e: 'remove', id: string): void
  (e: 'set-active', id: string): void
  (e: 'add-payment-method'): void
}>()

const creditCardImage = function (type: string, brand: string) {
  let imageURL

  if (type.toLowerCase() === 'apple_pay') {
    return CardApplePay
  } else if (type.toLowerCase() === 'google_pay') {
    return CardGooglePay
  } else if (type.toLowerCase() === 'shop_pay') {
    return CardShopPay
  }

  switch (brand.toLowerCase()) {
    case 'visa':
      imageURL = CardVisa
      break
    case 'mastercard':
      imageURL = CardMastercard
      break
    case 'amex':
      imageURL = CardAmex
      break
    default:
      imageURL = CardGeneral
  }
  return imageURL
}

const creditCardName = (type: string, brand: string) => {
  if (type.toLowerCase() === 'apple_pay') return 'Apple Pay'
  if (type.toLowerCase() === 'google_pay') return 'Google Pay'
  if (type.toLowerCase() === 'shop_pay') return 'Shop Pay'
  return `${brand[0].toUpperCase()}${brand.slice(1)}`
}

const confirmingRemoveForId = ref<string | null>(null)
</script>

<template>
  <div class="PaymentMethodList Card">
    <h2 class="h5">{{ t('payment_method_list_title') }}</h2>
    <ul class="PaymentMethodList__List" v-if="paymentMethods[0]">
      <li
        class="PaymentMethodList__Item"
        v-for="method in paymentMethods"
        :key="method.id"
      >
        <img
          :src="
            creditCardImage(method.payment_type, method.payment_details.brand)
          "
          alt=""
          role="presentation"
          class="PaymentMethodList__Image"
        />
        <p class="PaymentMethodList__Description">
          <span>
            <b>{{
              creditCardName(method.payment_type, method.payment_details.brand)
            }}</b>
            {{ t('payment_method_list_card_ending_in') }}
            <b>{{ method.payment_details.last4 }}</b
            >.&nbsp;</span
          >
          <span>
            {{ t('payment_method_list_card_expires') }}:
            <b>
              {{ method.payment_details.exp_month }} /
              {{ method.payment_details.exp_year }}</b
            >
          </span>
        </p>

        <div class="PaymentMethodList__Actions">
          <Label v-if="method.default" :mode="'success'">Active</Label>
          <Button
            v-else
            :modifiers="['link']"
            @click="$emit('set-active', method.id)"
            :disabled="isBusy"
          >
            {{ t('payment_method_list_set_active_cta') }}
          </Button>
          <Button
            v-if="!method.default"
            :modifiers="['link', 'error']"
            @click="confirmingRemoveForId = method.id"
            :disabled="isBusy"
          >
            {{ t('payment_method_list_remove_cta') }}
          </Button>
        </div>
      </li>
    </ul>
    <div v-else>
      <ErrorMessage>
        <template #title>{{ t('payment_method_list_empty_title') }}</template>
      </ErrorMessage>
    </div>
    <Button
      :modifiers="['link']"
      @click="$emit('add-payment-method')"
      class="PaymentMethodList__NewPaymentButton"
    >
      {{ t('payment_method_list_add_cta')
      }}<template #iconAfter><IconArrow /></template>
    </Button>
    <Teleport to="body">
      <Modal
        v-if="confirmingRemoveForId !== null"
        :title="t('payment_method_list_remove_confirmation_title')"
        @close="confirmingRemoveForId = null"
      >
        <slot>
          <p>{{ t('payment_method_list_remove_confirmation_copy') }}</p>
          <div className="ButtonGroup">
            <Button
              :modifiers="['error']"
              @click="() => { $emit('remove', confirmingRemoveForId as string); confirmingRemoveForId = null }"
            >
              {{ t('payment_method_list_remove_confirmation_confirm_cta') }}
            </Button>
            <Button
              :modifiers="['inverted']"
              @click="confirmingRemoveForId = null"
            >
              {{ t('payment_method_list_remove_confirmation_cancel_cta') }}
            </Button>
          </div>
        </slot>
      </Modal>
    </Teleport>
  </div>
</template>

<style>
.PaymentMethodList {
  padding: 24px;
}

.PaymentMethodList__List {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.PaymentMethodList__Item {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-top: 20px;
  border-top: solid 1px var(--color-lichen-100);
  @media (--mq-tablet-and-up) {
    justify-content: space-between;
    align-items: center;
  }
}

.PaymentMethodList__Image {
  width: 30px;
  margin-right: 10px;
  position: relative;
  top: 5px;
  @media (--mq-tablet-and-up) {
    top: 0;
  }
}

.PaymentMethodList__Description {
  margin: 0;

  & span {
    display: block;
  }
  @media (--mq-tablet-and-up) {
    flex: 1 1 auto;

    & span {
      display: inline-block;
    }
  }
}

.PaymentMethodList__Actions {
  display: inline-block;
  flex: 0 0 100%;
  margin-top: 20px;

  & .Button + .Button {
    margin-left: 10px;
  }

  @media (--mq-landscape-tablet-and-up) {
    flex: 0 1 auto;
    margin-top: 0;
    margin-left: 10px;
  }
}

.PaymentMethodList__NewPaymentButton {
  margin-top: 30px;
}
</style>
