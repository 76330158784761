<script setup lang="ts">
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import { t } from '@/lib/locale'
import IconChevronButton from '@/assets/icons/chevron-button.svg?component'
import IconEmail from '@/assets/icons/email.svg?component'
import IconWhatsApp from '@/assets/icons/whatsapp.svg?component'
import type { Subscription } from '@/stores/subscription'
import { onMounted, ref, watch } from 'vue'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import { formatDate } from '@/lib/formatters/date'

import IconLoading from '../assets/icons/loading-spinner.svg?component'
import happyPouchUrl from '../assets/images/happy-pouch.png'
import deadPouchUrl from '../assets/images/dead-pouch.png'
import type { AlternativeProduct } from '@/stores/cancellationIntervention'
import HorizontalProductList from '@/components/HorizontalProductList.vue'

const props = defineProps<{
  workState: AsyncOperationState
  alternativeProductsLoadState: AsyncOperationState
  alternativeProducts: AlternativeProduct[]
  interventionWorkState: AsyncOperationState
  item: Subscription
  inNextOrder: boolean
  track: (e: string, data?: Record<string, unknown>) => void
}>()

const emit = defineEmits<{
  (e: 'clear'): void
  (e: 'cancel', reason: string, comments?: string): void
  (e: 'loadAlternativeProducts'): void
  (e: 'swap', newExternalProductId: string): void
  (e: 'addToNextOrder'): void
  (e: 'delay', until: string): void
}>()

const delayLength = ref<'3' | '6'>('3')
const cancelReason = ref('')
const cancellationComments = ref('')

const initiatedCancel = ref(false)
const cancel = (reason: string, comments?: string) => {
  initiatedCancel.value = true
  emit('cancel', reason, comments)
}

const initiatedIntervention = ref(false)
const intervene = () => {
  initiatedIntervention.value = true
}

const swap = (newExternalProductId: string) => {
  intervene()
  emit('swap', newExternalProductId)
}

const addToNextOrder = () => {
  intervene()
  emit('addToNextOrder')
}

const delay = () => {
  if (props.item.next_charge_scheduled_at) {
    const d = new Date(props.item.next_charge_scheduled_at)
    d.setMonth(d.getMonth() + parseInt(delayLength.value))
    intervene()
    emit('delay', formatDate('YYYY-MM-DD', d))
  }
}

onMounted(() => {
  props.track('view_cancel_modal')
})

watch(cancelReason, () => {
  if (cancelReason.value) {
    props.track('select_cancel_reason', { reason: cancelReason.value })
  }
})
</script>

<template>
  <Modal
    class="CancellationModal"
    v-if="initiatedCancel && workState !== AsyncOperationState.IDLE"
    @close="emit('clear')"
  >
    <template v-if="workState === AsyncOperationState.DONE">
      <div class="CancellationModal__Confirmation">
        <img
          :src="happyPouchUrl"
          alt=""
          role="presentation"
          height="125"
          width="92.6"
        />
        <h3 class="CancellationModal__ConfirmationTitle">It's cancelled.</h3>
        <p>
          We’ve removed this product from all future orders, so you won’t get it
          again.
        </p>
      </div>
    </template>
    <template v-else-if="workState === AsyncOperationState.IN_PROGRESS">
      <div class="CancellationModal__Confirmation">
        <div class="CancellationModal__Loading">
          <IconLoading />
        </div>
        <h3 class="CancellationModal__ConfirmationTitle">
          We're working on it…
        </h3>
      </div>
    </template>
    <template v-else-if="workState === AsyncOperationState.ERRORED">
      <div class="CancellationModal__Confirmation">
        <img
          :src="deadPouchUrl"
          alt=""
          role="presentation"
          height="125"
          width="92.6"
        />
        <h3 class="CancellationModal__ConfirmationTitle">
          Something went wrong.
        </h3>
        <p>Please try again or get in touch with us.</p>
      </div>
    </template>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="
      initiatedIntervention &&
      interventionWorkState !== AsyncOperationState.IDLE
    "
    @close="emit('clear')"
  >
    <template v-if="interventionWorkState === AsyncOperationState.DONE">
      <div class="CancellationModal__Confirmation">
        <img
          :src="happyPouchUrl"
          alt=""
          role="presentation"
          height="125"
          width="92.6"
        />
        <h3 class="CancellationModal__ConfirmationTitle">It's done!</h3>
        <p>We've made that change for you.</p>
      </div>
    </template>
    <template
      v-else-if="interventionWorkState === AsyncOperationState.IN_PROGRESS"
    >
      <div class="CancellationModal__Confirmation">
        <div class="CancellationModal__Loading">
          <IconLoading />
        </div>
        <h3 class="CancellationModal__ConfirmationTitle">
          We're working on it…
        </h3>
        <p v-if="cancelReason === 'need_sooner'">
          We need to keep all your future orders in sync so updating your next
          order can take a while. Please bear with us…
        </p>
      </div>
    </template>
    <template v-else-if="interventionWorkState === AsyncOperationState.ERRORED">
      <div class="CancellationModal__Confirmation">
        <img
          :src="deadPouchUrl"
          alt=""
          role="presentation"
          height="125"
          width="92.6"
        />
        <h3 class="CancellationModal__ConfirmationTitle">
          Something went wrong.
        </h3>
        <p>Please try again or get in touch with us.</p>
      </div>
    </template>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'enough'"
    @close="emit('clear')"
  >
    <span class="CancellationModal__BackButton" @click="cancelReason = ''">
      <IconChevronButton />
      Back
    </span>
    <h3 class="CancellationModal__Title">
      Would you like to delay this product?
    </h3>
    <p v-if="item.next_charge_scheduled_at">
      It's next due on
      {{ formatDate('DD Month', new Date(item.next_charge_scheduled_at)) }}.
      Select how long you want to delay it by:
    </p>
    <div>
      <label class="RadioInput">
        <input type="radio" name="delay" value="3" v-model="delayLength" />
        <span>Delay by 3 months</span>
      </label>
      <label class="RadioInput">
        <input type="radio" name="delay" value="6" v-model="delayLength" />
        <span>Delay by 6 months</span>
      </label>
    </div>
    <div class="ButtonGroup">
      <button class="Button Button--block" @click="delay()">
        Confirm delay
      </button>
      <button
        class="Button Button--block Button--link"
        @click="cancel(cancelReason)"
      >
        No thanks, cancel product
      </button>
    </div>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'need_sooner'"
    @close="emit('clear')"
  >
    <span class="CancellationModal__BackButton" @click="cancelReason = ''">
      <IconChevronButton />
      Back
    </span>
    <template v-if="inNextOrder">
      <h3 class="CancellationModal__Title">Are you sure you want to cancel?</h3>
      <div class="ButtonGroup">
        <button class="Button Button--block" @click="cancel(cancelReason)">
          Yes, cancel product
        </button>
        <button
          class="Button Button--inverted Button--block"
          @click="emit('clear')"
        >
          No, keep this product
        </button>
      </div>
    </template>
    <template v-else>
      <h3 class="CancellationModal__Title">Get this product sooner</h3>
      <p>
        Run out quicker than you expected? Add this product to your next order.
      </p>
      <div class="ButtonGroup">
        <button class="Button Button--block" @click="addToNextOrder">
          Add to next order
        </button>
        <button
          class="Button Button--block Button--link"
          @click="cancel(cancelReason)"
        >
          No thanks, cancel product
        </button>
      </div>
    </template>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'switch_fragrance'"
    @close="emit('clear')"
  >
    <template
      v-if="
        alternativeProductsLoadState === AsyncOperationState.DONE &&
        alternativeProducts.length > 1
      "
    >
      <span class="CancellationModal__BackButton" @click="cancelReason = ''">
        <IconChevronButton />
        Back
      </span>
      <template
        v-if="alternativeProductsLoadState === AsyncOperationState.DONE"
      >
        <h3 class="CancellationModal__Title">Switch fragrance</h3>
        <p>
          This product comes in a few different, gorgeous fragrances. If you'd
          like to try a new one, please select from the list below.
        </p>
        <div>
          <HorizontalProductList
            :products="
              alternativeProducts.map((p) => ({
                title: `${p.fragrance_name?.value || p.title} ${
                  `${p.variants[0].id.replace(
                    'gid://shopify/ProductVariant/',
                    ''
                  )}` === item.external_variant_id.ecommerce
                    ? '(current)'
                    : ''
                }`,
                imageUrl: p.images.medium,
                action: {
                  text: `Pick this fragrance`,
                  callback: () => {
                    swap(
                      p.variants[0].id.replace(
                        /gid:\/\/shopify\/ProductVariant\//,
                        ''
                      )
                    )
                  },
                  disabled:
                    `${p.variants[0].id.replace(
                      'gid://shopify/ProductVariant/',
                      ''
                    )}` === item.external_variant_id.ecommerce,
                },
              }))
            "
          ></HorizontalProductList>
        </div>
      </template>
      <div class="ButtonGroup">
        <button
          class="Button Button--block Button--link"
          @click="cancel(cancelReason)"
        >
          No thanks, cancel product
        </button>
      </div>
    </template>
    <template v-else>
      <span class="CancellationModal__BackButton" @click="cancelReason = ''">
        <IconChevronButton @click="emit('clear')" />
        Back
      </span>
      <h3 class="CancellationModal__Title">How could we do better?</h3>
      <p>
        Please let us know how we could improve the fragrance of this product,
        or what kind of fragrance you'd like us to offer. Thanks!
      </p>
      <div class="FormRow">
        <div class="FormCol">
          <textarea
            placeholder="Enter your comments (max 1024 characters)"
            class="CancellationModal__FeedbackText"
            v-model="cancellationComments"
            maxlength="1024"
          ></textarea>
        </div>
      </div>
      <button
        class="Button Button--block"
        @click="cancel(cancelReason, cancellationComments)"
      >
        Cancel subscription
      </button>
    </template>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'no_longer_use'"
    @close="emit('clear')"
  >
    <span class="CancellationModal__BackButton" @click="cancelReason = ''">
      <IconChevronButton />
      Back
    </span>
    <h3 class="CancellationModal__Title">How could we do better?</h3>
    <p>Your feedback is really helpful for us.</p>
    <div class="FormRow">
      <div class="FormCol">
        <textarea
          placeholder="Enter your comments (max 1024 characters)"
          class="CancellationModal__FeedbackText"
          v-model="cancellationComments"
          maxlength="1024"
        ></textarea>
      </div>
    </div>
    <button
      class="Button Button--block"
      @click="cancel(cancelReason, cancellationComments)"
    >
      Cancel subscription
    </button>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'accident'"
    @close="emit('clear')"
  >
    <span class="CancellationModal__BackButton" @click="cancelReason = ''">
      <IconChevronButton />
      Back
    </span>
    <h3 class="CancellationModal__Title">Are you sure you want to cancel?</h3>
    <div class="ButtonGroup">
      <button class="Button Button--block" @click="cancel(cancelReason)">
        Yes, cancel product
      </button>
      <button
        class="Button Button--inverted Button--block"
        @click="emit('clear')"
      >
        No, keep this product
      </button>
    </div>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'too_expensive'"
    @close="emit('clear')"
  >
    <span class="CancellationModal__BackButton" @click="cancelReason = ''">
      <IconChevronButton />
      Back
    </span>
    <h3 class="CancellationModal__Title">Are you sure you want to cancel?</h3>
    <p>Any further information you can provide would be really helpful.</p>
    <div class="FormRow">
      <div class="FormCol">
        <textarea
          placeholder="Enter your comments (max 1024 characters)"
          class="CancellationModal__FeedbackText"
          v-model="cancellationComments"
          maxlength="1024"
        ></textarea>
      </div>
    </div>
    <button
      class="Button Button--block"
      @click="cancel(cancelReason, cancellationComments)"
    >
      Cancel subscription
    </button>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'difficulty_managing'"
    @close="emit('clear')"
  >
    <span class="CancellationModal__BackButton" @click="cancelReason = ''">
      <IconChevronButton />
      Back
    </span>
    <h3 class="CancellationModal__Title">Get help managing your account</h3>
    <p>
      As a subscriber you get access to <strong>priority support</strong>. If
      you'd like to, get in touch with us via WhatsApp or email.
    </p>
    <div class="ButtonGroup">
      <Button
        :modifiers="['block']"
        href="whatsapp://send?phone=+441225435185&abid=+441225435185"
        @click="track('cancel_intervention_support_whatsapp')"
      >
        <template #iconBefore>
          <IconWhatsApp />
        </template>
        Get help via WhatsApp
      </Button>
      <Button
        :modifiers="['block', 'inverted']"
        href="mailto:hello@bowercollective.com"
        @click="track('cancel_intervention_support_email')"
      >
        <template #iconBefore>
          <IconEmail />
        </template>
        Get help via email
      </Button>
      <button
        class="Button Button--block Button--link"
        @click="cancel(cancelReason)"
      >
        No thanks, cancel subscription
      </button>
    </div>
  </Modal>
  <Modal
    class="CancellationModal"
    v-else-if="cancelReason === 'other'"
    @close="emit('clear')"
  >
    <span class="CancellationModal__BackButton" @click="cancelReason = ''">
      <IconChevronButton @click="emit('clear')" />
      Back
    </span>
    <h3 class="CancellationModal__Title">How could we do better?</h3>
    <p>
      Please let us know why you’re cancelling, all your feedback helps us
      continuously improve our products and service. Thanks!
    </p>
    <div class="FormRow">
      <div class="FormCol">
        <textarea
          placeholder="Enter your comments (max 1024 characters)"
          class="CancellationModal__FeedbackText"
          v-model="cancellationComments"
          maxlength="1024"
        ></textarea>
      </div>
    </div>
    <button
      class="Button Button--block"
      @click="cancel(cancelReason, cancellationComments)"
    >
      Cancel subscription
    </button>
  </Modal>
  <Modal class="CancellationModal" v-else @close="emit('clear')">
    <h3 class="CancellationModal__Title">
      {{ t('purchase_item_cancel_subscription_modal_title') }}
    </h3>
    <div class="CancellationModal__ReasonList">
      <span @click="cancelReason = 'enough'">
        I don't need any more of this right now
      </span>
      <span @click="cancelReason = 'need_sooner'"> I need it sooner </span>
      <span @click="cancelReason = 'switch_fragrance'">
        I want a different fragrance
      </span>
      <span @click="cancelReason = 'no_longer_use'">
        I no longer use this product
      </span>
      <span @click="cancelReason = 'accident'"> I subscribed by accident </span>
      <span @click="cancelReason = 'too_expensive'"> It's too expensive </span>
      <span @click="cancelReason = 'difficulty_managing'">
        I'm having trouble managing my account
      </span>
      <span @click="cancelReason = 'other'">Another reason</span>
    </div>
  </Modal>
</template>

<style>
.CancellationModal__Title {
  margin: 10px 35px 12px 0;
}

.CancellationModal__ReasonList {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & span {
    background-color: var(--color-lichen-25);
    border-radius: var(--radius-small);
    cursor: pointer;
    padding: 9px 12px;

    &:hover {
      background-color: var(--color-lichen-100);
    }
  }
}

.CancellationModal__BackButton {
  cursor: pointer;
  padding: 3px 0;
  display: inline-block;

  & svg {
    width: 6px;
    transform: rotate(180deg);
  }
}

.CancellationModal__Confirmation {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding: calc(2 * var(--grid-gap)) 0 var(--grid-gap);
}

.CancellationModal__ConfirmationTitle {
  margin: 15px 0 12px;
}

.CancellationModal__Loading {
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--grid-gap) * 2);

  & svg {
    width: 45px;
    height: 45px;
  }
}

.CancellationModal .HorizontalProductList {
  margin-bottom: 16px;

  & .HorizontalProductList__Products {
    padding: 0;
    gap: 0 16px;

    & .ProductCardMini {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
