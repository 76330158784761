<script lang="ts" setup>
import { inject, onMounted, ref, type Ref } from 'vue'

import TwitterIcon from '../../assets/icons/twitter.svg?component'
import FacebookIcon from '../../assets/icons/facebook.svg?component'
import InstagramIcon from '../../assets/icons/instagram.svg?component'

import BCorpLogo from '../../assets/images/b-corp.svg?url'
import ClimatePartnerLogo from '../../assets/images/climate-partner.svg?url'

import type { Nav } from '@/stores/navigation'
import { useAuthStore } from '@/stores/auth'

const baseMainSiteUrl = (url: string) => {
  return new URL(url, 'https://bowercollective.com').toString()
}

const nav = inject<Ref<Nav>>('FOOTER_NAV')

type TpWindow = typeof window & {
  Trustpilot?: { loadFromElement(el: Element): void }
}

const trustbox = ref<Element | null>(null)
const showTrustpilot = () => {
  if ((window as TpWindow).Trustpilot && trustbox.value) {
    ;(window as TpWindow).Trustpilot?.loadFromElement(trustbox.value)
  }
}

const authStore = useAuthStore()

onMounted(() => showTrustpilot())
</script>

<template>
  <footer role="contentinfo" class="Footer">
    <div class="Footer__Banner" v-if="authStore.user">
      <p>
        You are logged in •
        <a class="Footer__LogoutButton" href="/">Your account</a> •
        <a class="Footer__LogoutButton" href="/logout">Log out</a>
      </p>
    </div>
    <div class="Container">
      <div class="Footer__Container">
        <div class="Footer__Block Footer__Block--company">
          <div class="Footer__CompanyInfo">
            <h2 class="h5">Bower Collective</h2>
            <p>
              Highly effective natural household products in reusable packaging
              to help you create a more sustainable home.
            </p>

            <ul class="SocialList">
              <li class="SocialList__item">
                <a
                  class="SocialLink"
                  href="https://twitter.com/CollectiveBower"
                  target="_blank"
                  rel="noopener"
                  aria-label="Twitter"
                >
                  <TwitterIcon />
                </a>
              </li>
              <li class="SocialList__item">
                <a
                  class="SocialLink"
                  href="https://www.facebook.com/bowercollective"
                  target="_blank"
                  rel="noopener"
                  aria-label="Facebook"
                >
                  <FacebookIcon />
                </a>
              </li>
              <li class="SocialList__item">
                <a
                  class="SocialLink"
                  href="https://instagram.com/bowercollective"
                  target="_blank"
                  rel="noopener"
                  aria-label="Instagram"
                >
                  <InstagramIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <nav class="Footer__Block Footer__Block--nav Footer__nav">
          <h2 class="h5">Additional information</h2>
          <ul>
            <li v-for="menuItem in nav" :key="menuItem.title">
              <a :href="baseMainSiteUrl(menuItem.url)">{{ menuItem.title }}</a>
            </li>
          </ul>
        </nav>

        <div class="Footer__Block Footer__Block--trustpilot">
          <!-- TrustBox widget - Micro Star -->
          <div
            class="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="5df75011e5b7f100012e201f"
            data-style-height="150px"
            data-style-width="100%"
            data-theme="dark"
            ref="trustbox"
          >
            <a
              href="https://uk.trustpilot.com/review/www.bowercollective.com"
              target="_blank"
              rel="noopener"
              >Trustpilot</a
            >
          </div>
          <!-- End TrustBox widget -->

          <div class="Footer__Logos">
            <img
              class="Footer__MailingList__BCorp"
              :src="BCorpLogo"
              width="44"
              height="68"
              loading="lazy"
            />
            <img
              class="Footer__MailingList__PlanetMark"
              :src="ClimatePartnerLogo"
              width="105"
              height="92"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div class="Footer__Meta">
        <p>&copy; {{ new Date().getFullYear() }} Bower Collective</p>
      </div>
    </div>
  </footer>
</template>

<style>
.Footer {
  background-color: var(--color-forest-100);
  color: var(--color-lichen-100);
  padding: 32px 0 var(--grid-gap);

  & a {
    color: var(--color-lichen-100);
  }
}

.Footer__Banner {
  display: none;
  margin-bottom: var(--grid-gap);
  padding: var(--grid-gap);
  margin-top: -32px;
  background-color: var(--color-ocean-100);

  & p {
    margin: 0;
    text-align: center;
  }

  @media (--mq-lap-and-up) {
    display: block;
  }
}

.SocialList {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.SocialList__item {
  margin: 0 12px;
  padding: 0;
}

.SocialLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-deep-ocean-100);
  border-radius: 9999em;
  color: var(--color-lichen-100);
  transition: background-color var(--global-transition);

  & svg {
    width: auto;
    height: 18px;
  }

  &:hover {
    background-color: var(--color-ocean-100);
    color: var(--color-lichen-100);
  }
}

.Footer.SocialLink:hover {
  color: var(--color-lichen-100);
}

.Footer .trustpilot-widget {
  margin-top: var(--grid-gap);
  max-width: 202px;
}

.Footer__nav ul {
  list-style-type: none;
  padding: 0;

  & li {
    display: block;
    margin-bottom: 10px;
  }
}

.Footer__Newsletter {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--grid-gap);

  & input[type='email'] {
    background-color: #3b474a;
    border-color: #3b474a;
    color: var(--color-lichen-100);
    flex-grow: 1;
    margin-right: 16px;
  }
}

.Footer__Logos {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & img {
    margin-right: 20px;
  }
}

.Footer__Meta {
  border-top: solid 1px var(--color-forest-80);
  color: var(--color-forest-50);
  margin-top: var(--grid-gap);
  padding: 16px 0 32px;
  font-size: var(--font-size--small);
}

@media (--mq-landscape-tablet-and-up) {
  .Footer__Container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--grid-gap);
  }

  .Footer__Block--company {
    display: flex;
    flex-direction: column;
  }

  .SocialList {
    order: 2;
    justify-content: flex-start;
  }

  .SocialList__item {
    margin: 0 16px 0 0;
  }

  .Footer__nav ul {
    column-count: 2;
    column-gap: var(--grid-gap);
  }

  .Footer__Logos {
    justify-content: flex-end;
    margin-top: 80px;
  }
}
</style>
