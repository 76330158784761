<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  mode?: string
}>()

const classes = computed(() => {
  const classes = []
  if (props.mode) classes.push(`Label--${props.mode}`)
  return classes
})
</script>

<template>
  <div class="Label" :class="classes">
    <div v-if="$slots.icon" class="Label__Icon">
      <slot name="icon" />
    </div>
    <div class="Label__Content">
      <slot />
    </div>
  </div>
</template>

<style>
.Label {
  display: inline-flex;
  border-radius: 9999em;
  border: solid 1px transparent;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size--base);
  line-height: var(--line-height-base);
  font-family: var(--font-family-rund);
  background-color: var(--color-white);
  color: var(--color-forest-100);
  padding: 6px 14px 6px 12px;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.Label__Content p {
  padding: 0;
  margin: 0;
  line-height: 20px;
}

.Label__Icon {
  margin-right: 10px;
}

.Label--small {
  font-size: calc((13 / 16) * 1rem); /* 13px */
  line-height: 1;
  padding: 4px 10px 4px 8px;

  & .Label__Icon {
    margin-left: -3px;
  }

  & .Label__IconRight > svg {
    height: 10px;
    width: 10px;
  }
}

.Label.Label--error {
  border-color: transparent;
}

.Label__IconRight {
  margin-left: 10px;
  height: 100%;
  height: 12px;
  width: 12px;
  display: flex;
}

.Label--clickable {
  cursor: pointer;
}
</style>
