<script lang="ts" setup>
import type { Customer } from '@/stores/subscriptionCustomer'
import Message from '@/components/Message.vue'
import IconStatusInfo from '@/assets/icons/status-info.svg?component'
import { t } from '@/lib/locale'
import { ref, watch } from 'vue'

const props = defineProps<{ customer: Customer }>()
const emit = defineEmits<{
  (e: 'change', id: string): void
}>()

const selectedAddressId = ref<string>(
  props.customer.include.addresses[0].id.toString()
)
emit('change', selectedAddressId.value.toString())

watch(selectedAddressId, () => {
  emit('change', selectedAddressId.value.toString())
})
</script>

<template>
  <div class="Card OnboardingPanel">
    <h5 class="OnboardingPanel__Title">{{ t('onboarding_address_title') }}</h5>
    <p>
      {{ t('onboarding_address_copy') }}
    </p>
    <p>
      <select v-model="selectedAddressId">
        <option
          :value="address.id.toString()"
          v-for="address in customer.include.addresses"
          :key="address.id"
        >
          {{ address.address1 }}, {{ address.address2 }} {{ address.city }}
          {{ address.zip }}
        </option>
      </select>
    </p>
    <Message mode="mid">
      <template #icon><IconStatusInfo /></template>
      <p>
        {{ t('onboarding_address_info_box_copy') }}
      </p>
    </Message>
  </div>
</template>
