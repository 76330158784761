<script lang="ts" setup>
import { computed } from 'vue'

type PointerPosition = 'topright' | 'topleft' | 'bottomleft' | 'bottomright'
type PointerDirection = 'horizontal' | 'vertical'
const props = withDefaults(
  defineProps<{
    mode?: string
    clickable?: boolean
    pointerPosition?: PointerPosition
    pointerDirection?: PointerDirection
  }>(),
  {
    mode: undefined,
    clickable: false,
    pointerDirection: undefined,
    pointerPosition: undefined,
  }
)

const classes = computed(() => {
  const classes = []
  if (props.mode) classes.push(`Message--${props.mode}`)
  if (props.clickable) classes.push(`Message--clickable`)
  return classes
})

const pointerClasses = computed(() => {
  const classes = []
  if (props.pointerPosition && props.pointerDirection) {
    classes.push(
      `Message__Pointer--${props.pointerPosition}-${props.pointerDirection}`
    )
  }
  return classes
})
</script>

<template>
  <div class="Message" :class="classes" @click="$emit('click')">
    <span
      v-if="pointerPosition"
      class="Message__Pointer"
      :class="pointerClasses"
    ></span>
    <div v-if="$slots.icon" class="Message__Icon">
      <slot name="icon" />
    </div>
    <div class="Message__Content">
      <slot />
    </div>
  </div>
</template>

<style>
.Message {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: var(--radius-small);
  font-family: var(--font-family-rund);
  padding: 8px 13px;
  border: solid 1px transparent;
  @media (--mq-tablet-and-up) {
    display: inline-flex;
    padding: 11px 16px;
  }
}

.Message__Content {
  flex-grow: 1;
  line-height: 1.5;
}

.Message__Content > p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--font-size--small);
}

.Message__Content > p + p {
  margin-top: 0.25rem;
}

.Message__Icon {
  margin-right: 10px;
  margin-top: 1px;
  @media (--mq-tablet-and-up) {
    margin-right: 15px;
    margin-top: 3px;
  }
}

.Message--clickable {
  cursor: pointer;
}

.Message__Pointer {
  position: absolute;
  border-color: transparent;
  border-width: 8px;
  border-style: solid;
}

.Message__Pointer--topleft-vertical {
  top: -16px;
  left: 20px;
  border-bottom-color: var(--color-white);
}

.Message__Pointer--topright-vertical {
  top: -16px;
  right: 20px;
  border-bottom-color: var(--color-white);
}

.Message__Pointer--topleft-vertical,
.Message__Pointer--topright-vertical {
  @nest .Message--dark & {
    border-bottom-color: var(--color-forest-100);
  }
  @nest .Message--mid & {
    border-bottom-color: var(--color-lichen-150);
  }
  @nest .Message--success & {
    border-bottom-color: var(--color-success-100);
  }
  @nest .Message--warning & {
    border-bottom-color: var(--color-warning-100);
  }
  @nest .Message--error & {
    border-bottom-color: var(--color-error-100);
  }
}

.Message__Pointer--bottomleft-vertical {
  bottom: -16px;
  left: 20px;
  border-top-color: var(--color-white);
}

.Message__Pointer--bottomright-vertical {
  bottom: -16px;
  right: 20px;
  border-top-color: var(--color-white);
}

.Message__Pointer--bottomleft-vertical,
.Message__Pointer--bottomright-vertical {
  @nest .Message--dark & {
    border-top-color: var(--color-forest-100);
  }
  @nest .Message--mid & {
    border-top-color: var(--color-lichen-150);
  }
  @nest .Message--success & {
    border-top-color: var(--color-success-100);
  }
  @nest .Message--warning & {
    border-top-color: var(--color-warning-100);
  }
  @nest .Message--error & {
    border-top-color: var(--color-error-100);
  }
}

.Message__Pointer--topleft-horizontal {
  top: 20px;
  left: -16px;
  border-right-color: var(--color-white);
}

.Message__Pointer--bottomleft-horizontal {
  bottom: 20px;
  left: -16px;
  border-right-color: var(--color-white);
}

.Message__Pointer--topleft-horizontal,
.Message__Pointer--bottomleft-horizontal {
  @nest .Message--dark & {
    border-right-color: var(--color-forest-100);
  }
  @nest .Message--mid & {
    border-right-color: var(--color-lichen-150);
  }
  @nest .Message--success & {
    border-right-color: var(--color-success-100);
  }
  @nest .Message--warning & {
    border-right-color: var(--color-warning-100);
  }
  @nest .Message--error & {
    border-right-color: var(--color-error-100);
  }
}

.Message__Pointer--topright-horizontal {
  top: 20px;
  right: -16px;
  border-left-color: var(--color-white);
}

.Message__Pointer--bottomright-horizontal {
  bottom: 20px;
  right: -16px;
  border-left-color: var(--color-white);
}

.Message__Pointer--topright-horizontal,
.Message__Pointer--bottomright-horizontal {
  @nest .Message--dark & {
    border-left-color: var(--color-forest-100);
  }
  @nest .Message--mid & {
    border-left-color: var(--color-lichen-150);
  }
  @nest .Message--success & {
    border-left-color: var(--color-success-100);
  }
  @nest .Message--warning & {
    border-left-color: var(--color-warning-100);
  }
  @nest .Message--error & {
    border-left-color: var(--color-error-100);
  }
}
</style>
