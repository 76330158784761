<script setup lang="ts">
import { NavSettings, Nav } from '@/stores/navigation'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import IconClose from '@/assets/icons/close.svg?component'
import { usePageOverlay } from '@/lib/pageOverlay'
import IconChevron from '@/assets/icons/chevron-button.svg?component'
import IconInbox from '@/assets/icons/email-inbox.svg?component'
import IconPhone from '@/assets/icons/phone.svg?component'
import NavFeaturedProduct from '@/components/NavFeaturedProduct.vue'

const showing = ref(false)
const selectedSection = ref<Nav[number] | null>(null)

const props = defineProps<{
  navStructure: Nav
  settings: NavSettings
}>()

const baseMainSiteUrl = (url: string) => {
  return new URL(url, 'https://bowercollective.com').toString()
}

const pageOverlay = usePageOverlay(
  (e) => {
    if (document.querySelector('.PageOverlay') === e.target) {
      showing.value = false
    }
  },
  (e) => {
    if (e.key === 'Escape') {
      showing.value = false
    }
  }
)

const maybeShowNav = (event: Event) => {
  if ((event.target as HTMLElement).hasAttribute('data-show-mobile-nav')) {
    showing.value = true
  }
}

onMounted(() => {
  document.addEventListener('click', maybeShowNav)
})

onUnmounted(() => {
  document.removeEventListener('click', maybeShowNav)
})

watch(showing, (newVal) => {
  if (newVal) {
    pageOverlay.show()
  } else {
    pageOverlay.hide()
  }
})

const showMenuFor = (navItem: Nav[number]) => {
  selectedSection.value = navItem
}

const navSettings = computed(() => {
  const s = selectedSection.value
  if (!s) return null
  return props.settings.sections.find(
    (section) =>
      s.title.trim().toLowerCase() === section.name.trim().toLowerCase()
  )
})
</script>

<template>
  <nav class="MainNavigationMobile">
    <Teleport to="body">
      <Transition name="TransitionSlideInLeft">
        <div class="MainNavigationMobile__Overlay" v-if="showing">
          <IconClose
            class="MainNavigationMobile__CloseIcon"
            @click="showing = false"
          />
          <ul class="MainNavigationMobile__TopLevelList">
            <li v-for="navItem in navStructure" :key="navItem.title">
              <a
                class="MainNavigationMobile__TopLevelList__Item"
                v-if="
                  navItem.url &&
                  navItem.url !== '#' &&
                  navItem.children.length === 0
                "
                :href="baseMainSiteUrl(navItem.url)"
              >
                <span>{{ navItem.title }}</span>
              </a>
              <span
                class="MainNavigationMobile__TopLevelList__Item"
                v-else
                @click.stop="showMenuFor(navItem)"
              >
                <span>{{ navItem.title }}</span>
                <IconChevron
                  class="MainNavigationMobile__TopLevelList__Item__Chevron"
                />
              </span>
            </li>
          </ul>
          <Transition name="TransitionFadeIn">
            <div
              class="MainNavigationMobile__SubMenuOverlay"
              v-if="selectedSection"
            ></div>
          </Transition>
          <Transition name="TransitionSlideInRight">
            <div class="MainNavigationMobile__SubMenu" v-if="selectedSection">
              <div class="MainNavigationMobile__SubMenu__Header">
                <button
                  class="MainNavigationMobile__SubMenu__Link"
                  @click="selectedSection = null"
                >
                  ← Back
                </button>
                <template v-if="navSettings && navSettings.text">
                  <h4>{{ navSettings.text }}</h4>
                  <p v-if="navSettings.cta_target && navSettings.cta_text">
                    <a
                      :href="baseMainSiteUrl(navSettings.cta_target)"
                      class="MainNavigationMobile__SubMenu__Link"
                    >
                      {{ navSettings.cta_text }}
                    </a>
                  </p>
                </template>
              </div>
              <div class="MainNavigationMobile__SubMenu__Inner" ref="menuEl">
                <template
                  v-if="selectedSection.title.trim().toLowerCase() === 'shop'"
                >
                  <div class="Stack">
                    <NavFeaturedProduct
                      v-if="settings.featuredProduct"
                      :product="settings.featuredProduct"
                      :label="settings.featuredProductLabel"
                    >
                    </NavFeaturedProduct>
                    <div
                      class=""
                      v-for="child in selectedSection.children"
                      :key="child.title"
                    >
                      <div class="MainNavigationMobile__SubMenu__NavList">
                        <h5>{{ child.title }}</h5>
                        <div
                          class="MainNavigationMobile__SubMenu__NavList__Container"
                        >
                          <ul>
                            <li
                              v-for="menuItem in child.children"
                              :key="menuItem.title"
                            >
                              <a :href="baseMainSiteUrl(menuItem.url)">{{
                                menuItem.title
                              }}</a>
                            </li>
                          </ul>
                        </div>
                        <p class="MainNavigationMobile__SubMenu__All">
                          <a :href="baseMainSiteUrl(child.url)">
                            All {{ child.title.toLowerCase() }} →
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="">
                    <div class="">
                      <div class="MainNavigationMobile__SubMenu__NavList">
                        <h5>{{ selectedSection.title }}</h5>
                        <div
                          class="MainNavigationMobile__SubMenu__NavList__Container"
                        >
                          <ul>
                            <li
                              v-for="menuItem in selectedSection.children"
                              :key="menuItem.title"
                            >
                              <a :href="baseMainSiteUrl(menuItem.url)">{{
                                menuItem.title
                              }}</a>
                            </li>
                          </ul>
                        </div>
                        <p
                          class="MainNavigationMobile__SubMenu__All"
                          v-if="
                            selectedSection.url && selectedSection.url !== '#'
                          "
                        >
                          <a :href="baseMainSiteUrl(selectedSection.url)">
                            All {{ selectedSection.title.toLowerCase() }} →
                          </a>
                        </p>
                      </div>
                    </div>
                    <div
                      class=""
                      v-if="
                        selectedSection.title.trim().toLowerCase() ===
                        'about us'
                      "
                    >
                      <div class="MainNavigationMobile__AboutUs">
                        <h5>{{ settings.aboutUsHeading }}</h5>
                        <p>{{ settings.aboutUsText }}</p>
                        <p>
                          <IconInbox />
                          <a href="mailto:hello@bowercollective.com">
                            hello@bowercollective.com
                          </a>
                        </p>
                        <p>
                          <IconPhone />
                          <a href="tel:+441225435180">01225 435180</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </Transition>
        </div>
      </Transition>
    </Teleport>
  </nav>
</template>

<style>
.MainNavigationMobile__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  background-color: var(--color-white);
  z-index: 100;
  overflow: hidden;
}

.MainNavigationMobile__CloseIcon.Icon {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 4px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  pointer-events: all;
}

.MainNavigationMobile__TopLevelList {
  margin: 72px 20px 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0;
}

.MainNavigationMobile__TopLevelList__Item {
  font-family: var(--font-family-domaine);
  color: var(--color-forest-100);
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  & > span,
  & > a {
    flex-grow: 1;
  }
}

.MainNavigationMobile__TopLevelList__Item__Chevron {
  width: 8px;
}

.MainNavigationMobile__SubMenuOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 25%);
  z-index: 1;
}

.MainNavigationMobile__SubMenu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 20px;
  background-color: var(--color-white);
  z-index: 2;
}

.MainNavigationMobile__SubMenu__Header {
  background-color: var(--color-lichen-25);
  margin: -20px -20px 20px;
  padding: 20px;

  & h4 {
    margin-top: 24px;
  }

  & p {
    margin: 8px 0 0;
  }
}

.MainNavigationMobile__SubMenu__Link {
  background: none;
  border: none;
  cursor: pointer;
  font-family: var(--font-family-rund);
  font-size: 16px;
  color: var(--color-forest-100);
  margin: 0;
  padding: 0;
}

.MainNavigationMobile__SubMenu__NavList {
  & > h5 {
    margin: 0;
  }

  & ul {
    margin-top: 0;
    list-style: none;
    padding: 0;

    & > li {
      margin: 8px 0;

      & > a {
        color: var(--color-forest-100);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.MainNavigationMobile__SubMenu__All {
  font-weight: bold;
  margin: 0;

  & > a {
    color: var(--color-forest-100);
  }
}

.MainNavigationMobile__AboutUs {
  margin-top: var(--grid-gap);

  & h5 {
    margin: 0;
  }

  & > p:first-child {
    margin: 0;
  }

  & > p:nth-child(2) {
    margin-top: 8px;
  }

  & p {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.TransitionSlideInLeft-enter-active,
.TransitionSlideInLeft-leave-active,
.TransitionSlideInRight-enter-active,
.TransitionSlideInRight-leave-active {
  transition: transform 0.3s ease-in-out;
}

.TransitionSlideInLeft-enter-to,
.TransitionSlideInLeft-leave-from,
.TransitionSlideInRight-enter-to,
.TransitionSlideInRight-leave-from {
  transform: translateX(0);
}

.TransitionSlideInLeft-enter-from,
.TransitionSlideInLeft-leave-to {
  transform: translateX(-100%);
}

.TransitionSlideInRight-enter-from,
.TransitionSlideInRight-leave-to {
  transform: translateX(100%);
}

.TransitionSlideInRight-enter-to,
.TransitionSlideInRight-leave-from {
  transform: translateX(0);
}

.TransitionFadeIn-enter-active,
.TransitionFadeIn-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.TransitionFadeIn-enter-from,
.TransitionFadeIn-leave-to {
  opacity: 0;
}

.TransitionFadeIn-enter-to,
.TransitionFadeIn-leave-from {
  opacity: 1;
}
</style>
