import { defineStore } from 'pinia'
import { getNextOrder, changeDate } from '../services/order'
import { useFutureOrderStore } from './futureOrder'
import { AsyncOperationState } from './lib/AsyncOperationState'
import { useOnetimeStore } from './onetime'
import { useProductImageStore } from './productImage'
import { useSubscriptionStore } from './subscription'
import { useTaskStore } from './task'
import { applyDiscount } from '@/services/discount'
import { formatProductTitle } from '@/lib/formatters/product-title'

import { t } from '@/lib/locale'
import Bugsnag from '@bugsnag/js'

export interface LineItem {
  purchase_item_id: string
  title: string
  variant_title?: string
  quantity: number
  total_price: string
  unit_price: string
  external_product_id: {
    ecommerce: string
  }
  external_variant_id: {
    ecommerce: string
  }
  images?: {
    small: string
    medium: string
    large: string
    original: string
  }
  purchase_item_type: 'onetime' | 'subscription'
}

export interface Discount {
  id: string
  code: string
  value: number
  value_type: string
}

export interface ShippingLine {
  code: string
  price: string
  source: string
  tax_lines: []
  taxable: boolean
  title: string
}

export interface Order {
  id: string
  scheduled_at: string
  address_id: string
  line_items: LineItem[]
  discounts: Discount[]
  total_price: string
  total_tax: string
  total_discounts: string
  subtotal_price: string
  discountCode: string
  shipping_address: {
    address1: string
    address2?: string
    city: string
    company?: string
    country_code?: string
    first_name: string
    last_name: string
    phone?: string
    province?: string
    zip: string
  }
  shipping_lines: ShippingLine[]
  status: string
  error?: string
  error_type?: string
  retry_date?: string
}

export const useNextOrderStore = defineStore('nextOrder', {
  state: () => ({
    loadState: AsyncOperationState.IDLE,
    workState: AsyncOperationState.IDLE,
    discountState: AsyncOperationState.IDLE,
    order: null as Order | null,
    stale: true as boolean,
  }),
  getters: {
    getItemById() {
      return (id: string) => {
        return this.order?.line_items.find(
          (item) => `${item.purchase_item_id}` === id
        )
      }
    },
  },
  actions: {
    async init(rechargeId?: string) {
      if (
        (this.stale || this.loadState === AsyncOperationState.IDLE) &&
        rechargeId
      ) {
        await this.load(rechargeId)
      }
    },
    async load(rechargeId: string) {
      const productImageStore = useProductImageStore()
      this.loadState = AsyncOperationState.IN_PROGRESS
      try {
        const order = await getNextOrder(rechargeId)
        if (order) {
          // remove all the 10.00% off auto renew stuff
          order.line_items.forEach((li: LineItem) => {
            li.title = formatProductTitle(li.title)
          })
          productImageStore.loadImagesForProducts(
            order.line_items.map(
              (item: LineItem) =>
                `${item.external_product_id.ecommerce}:${item.external_variant_id.ecommerce}`
            )
          )
        }
        this.order = order
        this.stale = false
        this.loadState = AsyncOperationState.DONE
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.loadState = AsyncOperationState.ERRORED
      }
    },
    async changeDate(rechargeId: string, from: string, to: string) {
      const taskStore = useTaskStore()
      this.workState = AsyncOperationState.IN_PROGRESS
      const taskId = taskStore.setTask(
        t('next_order_date_change_toast_progess_1'),
        this.workState
      )
      let timeout = setTimeout(() => {
        taskStore.updateTask(
          taskId,
          t('next_order_date_change_toast_progess_2'),
          this.workState
        )
        timeout = setTimeout(() => {
          taskStore.updateTask(
            taskId,
            t('next_order_date_change_toast_progess_3'),
            this.workState
          )
        }, 5000)
      }, 3000)
      try {
        await changeDate(rechargeId, from, to)
        clearTimeout(timeout)
        this.workState = AsyncOperationState.DONE
        taskStore.updateTask(
          taskId,
          t('next_order_date_change_toast_success'),
          this.workState
        )

        // invalidate the stores that will need updating after this
        const subscriptionStore = useSubscriptionStore()
        const futureOrderStore = useFutureOrderStore()
        const onetimeStore = useOnetimeStore()

        subscriptionStore.stale = true
        onetimeStore.stale = true
        futureOrderStore.stale = true

        this.load(rechargeId)
      } catch (e) {
        clearTimeout(timeout)
        Bugsnag.notify(e as Error)
        this.workState = AsyncOperationState.ERRORED
        taskStore.updateTask(
          taskId,
          t('next_order_date_change_toast_error'),
          this.workState
        )
      }
    },
    async applyDiscount(rechargeId: string, discountCode: string) {
      if (!this.order) return
      try {
        this.discountState = AsyncOperationState.IN_PROGRESS
        await applyDiscount(rechargeId, this.order.address_id, discountCode)
        setTimeout(() => {
          this.discountState = AsyncOperationState.DONE
          this.load(rechargeId)
        }, 5000)
      } catch (e) {
        Bugsnag.notify(e as Error)
        this.discountState = AsyncOperationState.ERRORED
      }
    },
  },
})
