<script lang="ts" setup>
import Layout from '@/components/layout/Layout.vue'
import NewPaymentMethod from '../components/NewPaymentMethod.vue'
import Button from '../components/Button.vue'
import type { AuthenticatedEntity } from '@/stores/auth'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import { useSubscriptionCustomerStore } from '@/stores/subscriptionCustomer'
import {
  loadStripe,
  type Appearance,
  type Stripe,
  type StripeElements,
} from '@stripe/stripe-js'
import { computed, onMounted, ref } from 'vue'
import Bugsnag from '@bugsnag/js'

import { t } from '../lib/locale'

import { useBackLink } from './lib/backLink'
const { backTo } = useBackLink()

const props = defineProps<{ authedUser: AuthenticatedEntity }>()

const subscriptionCustomerStore = useSubscriptionCustomerStore()
subscriptionCustomerStore.init(props.authedUser.rechargeId)

let stripe: Stripe, elements: StripeElements

const stripePaymentElement = ref<HTMLElement | null>(null)

const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#1F3B35',
    colorBackground: '#fff',
    colorText: '#1F3B35',
    colorSuccess: '#00b448',
    colorSuccessText: '#fff',
    colorWarning: '#ffaa00',
    colorWarningText: '#fff',
    colorDanger: '#bc0000',
    colorDangerText: '#fff',
    fontFamily: 'Rund Text, system-ui, sans-serif',
    borderRadius: '4px',
  },
}

const showStripeForm = async () => {
  const s = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
  if (s) {
    stripe = s
    const { client_secret } =
      await subscriptionCustomerStore.createPaymentMethodSetupIntent(
        props.authedUser.rechargeId
      )
    elements = stripe.elements({
      clientSecret: client_secret,
      appearance,
    })
    const stripePaymentEl = elements.create('payment')
    if (stripePaymentElement.value) {
      stripePaymentEl.mount(stripePaymentElement.value)
    } else {
      Bugsnag.notify('paymentElement ref was null')
    }
  }
}

const paymentError = ref<string>('')

const onPaymentSubmit = async () => {
  const { error } = await stripe.confirmSetup({
    elements,
    confirmParams: {
      return_url: `${window.location.origin}/details`,
    },
  })

  if (error) {
    Bugsnag.notify(new Error('Failed to add payment method'), (event) => {
      event.addMetadata('stripe error', error)
    })
    if (error.message) {
      paymentError.value = error.message
    }
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
  }
}

const onShopifyPaymentUpdate = () => {
  if (subscriptionCustomerStore.customer?.include.payment_methods[0]) {
    subscriptionCustomerStore.requestPaymentMethodEmail(
      props.authedUser.rechargeId,
      subscriptionCustomerStore.customer?.include.payment_methods[0].id
    )
  }
}

const isStripe = computed(
  () =>
    subscriptionCustomerStore.customer?.include.payment_methods[0]
      .processor_name == 'stripe'
)

onMounted(() => {
  if (isStripe.value) {
    showStripeForm()
  }
})
</script>

<template>
  <Layout
    :back-link="{ text: t('your_details_back_cta') }"
    @back="backTo('/details')"
  >
    <template #main>
      <div class="Stack">
        <NewPaymentMethod
          v-if="isStripe"
          :error="
            paymentError ||
            (subscriptionCustomerStore.setupIntentLoadState ===
            AsyncOperationState.ERRORED
              ? t('payment_method_form_load_error')
              : undefined)
          "
          :on-payment-submit="onPaymentSubmit"
          :is-loading="
            subscriptionCustomerStore.setupIntentLoadState ===
            AsyncOperationState.IN_PROGRESS
          "
        >
          <template #stripePaymentElement>
            <div ref="stripePaymentElement"></div>
          </template>
        </NewPaymentMethod>
        <div
          v-else-if="
            subscriptionCustomerStore.customer?.include.payment_methods[0]
          "
        >
          <div class="NewPaymentMethod Card">
            <h3>{{ t('payment_method_add_title') }}</h3>
            <p>
              To update your payment method click the button below. We'll send
              you an email that has a link to provide your new details.
            </p>
            <Button
              @click="onShopifyPaymentUpdate"
              :disabled="
                subscriptionCustomerStore.workState ===
                AsyncOperationState.IN_PROGRESS
              "
            >
              Send me a payment update email
            </Button>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>
