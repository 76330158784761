<script setup lang="ts">
import IconChevron from '@/components/icons/IconChevron.vue'

defineProps<{
  value: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: string): void
}>()
</script>

<template>
  <div class="FormField FormField--dropdown" type="dropdown">
    <icon-chevron />
    <select
      class="FormField__Input FormField__Input--select"
      :value="value"
      :disabled="disabled"
      @change="emit('change', ($event.target as HTMLSelectElement).value as string)"
    >
      <slot />
    </select>
    <div class="FormField__InputOverflowFade" />
  </div>
</template>

<style>
.FormField {
  position: relative;
  color: var(--color-forest-100);
}

.FormField input,
.FormField select {
  transition: box-shadow ease-in-out 0.15s;
}

.FormField input:focus,
.FormField select:focus {
  border-color: var(--color-lichen-150);
}

.FormField input:focus-visible,
.FormField select:focus-visible {
  box-shadow: var(--box-shadow-focus);
}

.FormField .FormField__Input--select {
  appearance: none;
  outline: none;
  font-family: var(--font-family-rund);
  line-height: calc(1rem + 4px);
  color: var(--color-forest-100);
  font-size: var(--font-size--base);
  background: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-lichen-150);
  padding: 8px 10px;
  padding-right: 20px;
  border-radius: 4px;
  position: relative;
  width: 100%;
  text-overflow: ellipsis;
}

.FormField__InputOverflowFade {
  position: absolute;
  top: 5px;
  right: 1px;
  bottom: 5px;
  width: 33px;
  content: '';
  background: linear-gradient(
    to right,
    rgba(var(--rgb-white), 0),
    var(--color-white) 20%
  );
  pointer-events: none;
}

.FormField__Input--select[disabled] + .FormField__InputOverflowFade {
  background: linear-gradient(
    to right,
    rgba(var(--rgb-lichen-100), 0),
    var(--color-lichen-100) 20%
  );
}

.FormField .FormField__Input--select[disabled] {
  background-color: var(--color-lichen-100);
  color: var(--color-forest-80);
}

.FormField--dropdown > .Icon {
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -4px;
  z-index: 1;
  width: 12px;
  height: 8px;
  line-height: 1;
}
</style>
